import { useEffect, useState } from "react";

export type FocusProps = {
  onFocus: () => void;
  onBlur: () => void;
};

export type UseFocusResult = [hasFocus: boolean, focusProps: FocusProps];

const useFocus = (onChange?: (focus: boolean) => void): UseFocusResult => {
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    onChange?.(hasFocus);
  }, [hasFocus]);

  const onFocus = () => {
    setHasFocus(true);
  };
  const onBlur = () => {
    setHasFocus(false);
  };

  return [hasFocus, { onFocus, onBlur }];
};

export default useFocus;
