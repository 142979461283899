import { forwardRef, MouseEventHandler, PropsWithChildren, ReactNode } from "react";
import { motion } from "framer-motion";
import CardFooter from "./CardFooter";
import { renderCardShadow } from "./helpers";
import { CardShadow } from "./types";
import { classNames } from "../../../utils/string";

export type CardProps = {
  level?: CardShadow;
  header?: ReactNode;
  footer?: ReactNode;
  className?: string;
  onClick?: MouseEventHandler;
};

const Card = forwardRef<HTMLDivElement, PropsWithChildren<CardProps>>(
  ({ level = 1, header, footer, children, className, onClick }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames(
          "bg-white text-grey-super-dark border border-grey-super-light shadow rounded",
          renderCardShadow(level),
          onClick && `hover:${renderCardShadow(Math.min(level + 1, 4) as CardShadow)}`,
          className
        )}
        onClick={onClick}
      >
        {header}
        {children && <div className="px-4 py-1">{children}</div>}
        {footer && <CardFooter>{footer}</CardFooter>}
      </div>
    );
  }
);

export default motion(Card, { forwardMotionProps: true });
