import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import { LanguageContext } from "../../Layout/Page";

export type BuyingAgentNeedToKnowProps = {};

const translations = {
  en: [
    "What do you need to know before buying a house in Spain?",
    "The Spanish housing market is an open market, so all estate agents can offer the same houses. We therefore recommend choosing one estate agent and going through the whole process with them. Unfortunately, there are many estate agents who do not keep the prices of new construction projects up to date. Therefore, you may see the same projects at a lower price on another website. We check all prices with proper regularity so there are no surprises. We are also up-to-date on the market when it comes to purchasing existing construction."
  ],
  es: [
    "¿Qué necesitas saber antes de comprar una casa en España?",
    "El mercado inmobiliario español es un mercado abierto, por lo que todos los agentes inmobiliarios pueden ofrecer las mismas casas. Por lo tanto, recomendamos elegir un agente inmobiliario y pasar por todo el proceso con ellos. Desafortunadamente, hay muchos agentes inmobiliarios que no mantienen actualizados los precios de los proyectos de nueva construcción. Por lo tanto, es posible que vea los mismos proyectos a un precio más bajo en otro sitio web. Verificamos todos los precios con la debida regularidad para que no haya sorpresas. También estamos actualizados en el mercado cuando se trata de la compra de construcción existente."
  ],
  nl: [
    "Wat moet je weten voordat je een huis koopt in Spanje?",
    "De Spaanse huizenmarkt is een open markt, dus alle makelaars kunnen dezelfde huizen aanbieden. We raden daarom aan om één makelaar te kiezen en het hele proces met hen door te lopen. Helaas zijn er veel makelaars die de prijzen van nieuwbouwprojecten niet up-to-date houden. Daarom kunt u dezelfde projecten tegen een lagere prijs op een andere website zien. Wij controleren alle prijzen met de juiste regelmaat, zodat er geen verrassingen zijn. We zijn ook op de hoogte van de markt als het gaat om de aankoop van bestaande bouw."
  ],
  de: [
    "Was müssen Sie wissen, bevor Sie ein Haus in Spanien kaufen?",
    "Der spanische Immobilienmarkt ist ein offener Markt, so dass alle Immobilienmakler die gleichen Häuser anbieten können. Wir empfehlen daher, einen Immobilienmakler zu wählen und den gesamten Prozess mit ihm durchzugehen. Leider gibt es viele Immobilienmakler, die die Preise von Neubauprojekten nicht auf dem neuesten Stand halten. Daher können Sie dieselben Projekte zu einem niedrigeren Preis auf einer anderen Website sehen. Wir überprüfen alle Preise mit der richtigen Regelmäßigkeit, damit es keine Überraschungen gibt. Wir sind auch auf dem Markt auf dem neuesten Stand, wenn es um den Kauf von bestehenden Gebäuden geht."
  ]
};

const BuyingAgentNeedToKnow: FC<BuyingAgentNeedToKnowProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  return (
    <Box backgroundColor="primary">
      <Container className="text-white">
        <Text type="h3">{text()}</Text>
        <Text>{text()}</Text>
      </Container>
      <svg
        className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
      >
        <path
          d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
        ></path>
      </svg>
    </Box>
  );
};

export default BuyingAgentNeedToKnow;
