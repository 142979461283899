import { Editor as TinyMCEEditor } from "tinymce";
import { buttonClassName } from "../../UserInput/Button/constants";
import { ButtonType } from "../../UserInput/Button/types";
import { classNames } from "../../../utils/string";

export const getButtonPlugin = (editor: TinyMCEEditor) => ({
  text: "Button",
  onAction: () => {
    editor.windowManager.open({
      title: "Add Button",
      body: {
        type: "panel",
        items: [
          {
            type: "input",
            name: "button_label",
            label: "Button label",
            // @ts-ignore next-line
            flex: true
          },
          {
            type: "input",
            name: "button_href",
            label: "Button href",
            // @ts-ignore next-line
            flex: true
          },
          {
            type: "selectbox",
            name: "button_target",
            label: "Target",
            items: [
              { text: "None", value: "" },
              { text: "New window", value: "_blank" },
              { text: "Self", value: "_self" },
              { text: "Parent", value: "_parent" }
            ],
            // @ts-ignore next-line
            flex: true
          },
          {
            type: "selectbox",
            name: "button_style",
            label: "Style",
            items: [
              { text: "Primary", value: "normal" },
              { text: "Secondary", value: "call-to-action" },
              { text: "Inverse", value: "inverse" },
              { text: "Quiet", value: "quiet" },
              { text: "Outline", value: "outline" },
              { text: "Light", value: "light" }
            ],
            // @ts-ignore next-line
            flex: true
          }
        ]
      },
      onSubmit: (api) => {
        const data = api.getData();
        const resolvedClassName = classNames(
          "button",
          "relative group inline-flex flex-row items-center justify-center text-center gap-2 px-4 py-2 disabled:opacity-50",
          buttonClassName[data.button_style as ButtonType]
        );
        const html = `<a href="${data.button_href}" class="${resolvedClassName}" target="${data.button_target}" rel="${data.button_rel}">${data.button_label}</a>`;
        editor.insertContent(html);
        api.close();
      },
      buttons: [
        {
          text: "Close",
          type: "cancel",
          // @ts-ignore next-line
          onclick: "close"
        },
        {
          text: "Insert",
          type: "submit",
          primary: true,
          enabled: true
        }
      ]
    });
  }
});
