import { FC, PropsWithChildren, useContext } from "react";
import { Helmet } from "react-helmet";
import { Translation } from "../../types";
import { LanguageContext } from "../../Layout/Page";
import { isNonEmptyString } from "../../utils/validation";

export type SEOProps = {
  title: Translation | string;
  description: Translation | string;
};

const locales = {
  en: "en_GB",
  es: "es_ES",
  nl: "nl_NL",
  de: "de_DE"
};

const SEO: FC<PropsWithChildren<SEOProps>> = ({ title, description, children }) => {
  const { language } = useContext(LanguageContext);
  const url = window.location.href;
  return (
    <>
      <Helmet>
        <title>{isNonEmptyString(title) ? title : title[language]}</title>
        <meta name="description" content={isNonEmptyString(description) ? description : description[language]} />
        <meta property="og:title" content={isNonEmptyString(title) ? title : title[language]} />
        <meta property="og:description" content={isNonEmptyString(description) ? description : description[language]} />
        <meta property="og:url" content={url} />
        <meta property="og:locale" content={locales[language]} />
        <meta name="twitter:title" content={isNonEmptyString(title) ? title : title[language]} />
        <meta
          name="twitter:description"
          content={isNonEmptyString(description) ? description : description[language]}
        />
      </Helmet>
      {children}
    </>
  );
};

export default SEO;
