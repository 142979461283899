import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import { PopupButton } from "react-calendly";
import { LanguageContext } from "../../Layout/Page";
import { getClassName } from "../../Widgets/Button/helpers";
import { classNames } from "../../utils/string";

export type BuyingAgentConsultationProps = {};

const translations = {
  en: [
    "Book a free consultation",
    "Let's get started by arranging a video call or a personal meeting so we can discuss your needs and we can give you advice on buying property in Spain. But the most important thing about this first meeting is that we get to know each other. Over the next few months we will spend a lot of time calling, emailing and viewing houses. So you need to be sure that we are the partner you want to work with.",
    "Click here to schedule!"
  ],
  es: [
    "Reserve una consulta gratuita",
    "Comencemos organizando una videollamada o una reunión personal para que podamos discutir sus necesidades y podamos darle consejos sobre la compra de propiedades en España. Pero lo más importante de esta primera reunión es que nos conozcamos. Durante los próximos meses pasaremos mucho tiempo llamando, enviando correos electrónicos y viendo casas. Por lo tanto, debe estar seguro de que somos el socio con el que desea trabajar.",
    "¡Haga clic aquí para programar"
  ],
  nl: [
    "Boek een gratis consult",
    "Laten we beginnen met het regelen van een videogesprek of een persoonlijke ontmoeting, zodat we uw behoeften kunnen bespreken en u advies kunnen geven over het kopen van onroerend goed in Spanje. Maar het belangrijkste van deze eerste ontmoeting is dat we elkaar leren kennen. De komende maanden zullen we veel tijd besteden aan bellen, e-mailen en huizen bekijken. Dus je moet er zeker van zijn dat wij de partner zijn waarmee je wilt samenwerken.",
    "Klik hier om te plannen!"
  ],
  de: [
    "Buchen Sie eine kostenlose Beratung",
    "Lassen Sie uns mit der Organisation eines Videoanrufs oder eines persönlichen Treffens beginnen, damit wir Ihre Bedürfnisse besprechen und Ihnen Ratschläge zum Kauf von Immobilien in Spanien geben können. Aber das Wichtigste an diesem ersten Treffen ist, dass wir uns kennenlernen. In den nächsten Monaten werden wir viel Zeit damit verbringen, anzurufen, E-Mails zu senden und Häuser zu besichtigen. Sie müssen sich also sicher sein, dass wir der Partner sind, mit dem Sie zusammenarbeiten möchten.",
    "Klicken Sie hier, um zu planen!"
  ]
};

const BuyingAgentConsultation: FC<BuyingAgentConsultationProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  return (
    <Box backgroundColor="grey">
      <Container>
        <Text type="h2">{text()}</Text>
        <Text>{text()}</Text>
        <div className={classNames(getClassName("primary"), "mt-6 w-fit mx-auto")}>
          <PopupButton
            url="https://calendly.com/freshpropertymanagement"
            rootElement={document.getElementById("root")!}
            text={text()}
          />
        </div>
      </Container>
    </Box>
  );
};

export default BuyingAgentConsultation;
