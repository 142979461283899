import { FC } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import useGetData from "../../hooks/useGetData";
import { Blog } from "./types";
import { Language } from "../../Layout/Page";
import Loader from "../../Widgets/Feedback/Progress/Loader/Loader";
import TextEditor from "../../Widgets/Text/Editor/TextEditor";
import SEO from "../../Widgets/SEO/SEO";

export type SingleBlogProps = {
  language: Language;
  name: string;
};

const seo = {
  title: {
    en: "Your tour guide in Spain",
    es: "Su guía turístico en España",
    nl: "Jouw gids in Spanje",
    de: "Ihr Reiseführer in Spanien"
  },
  description: {
    en: "FRESH Property Management is your tour guide in Spain. Read our blogs to learn more about the beautiful and exciting surroundings that Málaga has to offer.",
    es: "FRESH Property Management es su guía turístico en España. Lea nuestros blogs para aprender más sobre los hermosos y emocionantes alrededores que Málaga tiene para ofrecer.",
    nl: "FRESH Property Management is jouw gids in Spanje. Lees onze blogs om meer te weten te komen over de prachtige en spannende omgeving die Málaga te bieden heeft.",
    de: "FRESH Property Management ist Ihr Reiseführer in Spanien. Lesen Sie unsere Blogs, um mehr über die schöne und aufregende Umgebung zu erfahren, die Málaga zu bieten hat."
  }
};

const SingleBlog: FC<SingleBlogProps> = ({ language, name }) => {
  const [loading, blog] = useGetData<Blog>(`/blogs?name=${name}`);
  return (
    <SEO title={blog?.name?.[language] ?? seo.title} description={blog?.description?.[language] ?? seo.description}>
      {loading && <Loader type="overlay" />}
      {blog && (
        <>
          <Box
            className="overflow-x-hidden"
            backgroundColor="primary"
            text={{ color: "white" }}
            icon={{ fillColor: "white" }}
          >
            <Container padding={{ top: 14 }} extraTop>
              <div className="grid grid-cols-1 lg:grid-cols-2 space-x-4 items-center">
                <div>
                  <Text type="h1">{blog.name?.[language]}</Text>
                </div>
                <div className="lg:-mt-4 lg:flex flex-col items-center">
                  <img
                    className="hidden lg:block"
                    width="350px"
                    height="350px"
                    src="/logo.svg"
                    alt="FRESH Property Management Logo without text"
                  />
                </div>
              </div>
            </Container>
            <svg
              className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 100"
              preserveAspectRatio="none"
            >
              <path
                d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
              ></path>
            </svg>
          </Box>
          <Box>
            <Container className="w-[800px] max-w-full !text-left [&_a]:text-primary [&_a]:underline [&_p]:mb-8">
              <TextEditor initialValue={blog.content?.[language]} />
            </Container>
          </Box>
        </>
      )}
    </SEO>
  );
};

export default SingleBlog;
