import { useEffect } from "react";

const useKeysUp = (keys: string[], callback: (key: string) => void) => {
  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      if (keys.includes(e.key)) {
        callback(e.key);
      }
    };
    window.addEventListener("keyup", onKeyUp);
    return () => {
      window.removeEventListener("keyup", onKeyUp);
    };
  }, [keys]);
};

export default useKeysUp;
