import { FC } from "react";
import { constrainValue } from "./helpers";
import { informerStyle } from "../../constants";
import { classNames } from "../../../../utils/string";
import Button from "../../../../Widgets/Button/Button";
import { isNonEmptyString } from "../../../../utils/validation";

export type NumberInputProps = {
  placeholder?: string;
  step?: number;
  value?: number;
  onChange?: (value?: number) => void;
  min?: number;
  max?: number;
  className?: string;
};

const NumberInput: FC<NumberInputProps> = ({ placeholder, step, value, onChange, min, max, className }) => {
  const resolvedValue = constrainValue(value, min, max);
  const decrease = () => {
    if (value !== undefined && step !== undefined && value <= step) {
      return onChange?.(undefined);
    }
    onChange?.(constrainValue((value ?? 0) - (step ?? 1), min, max));
  };
  const increase = () => onChange?.(constrainValue((value ?? 0) + (step ?? 1), min, max));

  return (
    <div className={classNames("flex flex-row items-center", className)}>
      <Button className="!h-[2.1rem] !py-0 rounded-r-none mb-1" onClick={decrease}>
        -
      </Button>
      <input
        className={informerStyle.textInput(
          "flex-1 !rounded-none mb-1",
          "[appearance:textfield]",
          "[&::-webkit-outer-spin-button]:appearance-none",
          "[&::-webkit-inner-spin-button]:appearance-none"
        )}
        type="number"
        step={step}
        placeholder={placeholder}
        value={resolvedValue ?? ""}
        onChange={(e) =>
          onChange?.(isNonEmptyString(e.target.value) ? constrainValue(e.target.valueAsNumber, min, max) : undefined)
        }
      />
      <Button className="!h-[2.1rem] !py-0 rounded-l-none mb-1" onClick={increase}>
        +
      </Button>
    </div>
  );
};

export default NumberInput;
