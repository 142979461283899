import { FC, HTMLAttributeAnchorTarget, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { getClassName } from "./helpers";
import { BaseButtonProps } from "./types";
import { classNames } from "../../utils/string";

export type LinkButtonProps = PropsWithChildren<
  BaseButtonProps & {
    to: string;
    target?: HTMLAttributeAnchorTarget;
    className?: string;
  }
>;

const LinkButton: FC<LinkButtonProps> = ({ type = "primary", target, to, className, children }) => {
  return (
    <Link className={classNames(getClassName(type), "block !w-full !text-center", className)} to={to} target={target}>
      {children}
    </Link>
  );
};

export default LinkButton;
