import { Icon } from "@iconify/react";
import rocket from "@iconify-icons/ph/rocket-launch";
import user from "@iconify-icons/ph/user-circle-plus";
import house from "@iconify-icons/ph/house-line";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import { LanguageContext } from "../../Layout/Page";

export type HolidayHassleFreeProps = {};

const translations = {
  en: [
    `Rent out your property hassle-free`,
    `Maximize your rental income with us`,
    `Marketing, Ad Creation & Listing`,
    `We prepare your property for rental by planning the photography, writing the listing ad, and posting it to the major portals like Airbnb, Booking.com, VRBO, and others, to ensure it is visible and ready to be booked.`,
    `Bookings & Communication`,
    `We manage all reservations and communications with tenants. We make sure that the tenants enter your property securely. We act as their primary point of contact throughout their whole stay, answering any questions they might have and helping them however we can.`,
    `Payouts & Property Updates`,
    `As the owner of the property, you get paid for each booking. Our fees are transparent and without any hidden costs. To preserve the look and feel of your property, we provide free, recurring property inspections and recommendations.`
  ],
  es: [
    `Alquile su propiedad sin complicaciones`,
    `Maximice sus ingresos por alquiler con nosotros`,
    `Marketing, creación de anuncios y listado`,
    `Preparamos su propiedad para alquilar planificando la fotografía, escribiendo el anuncio de listado y publicándolo en los principales portales como Airbnb, Booking.com, VRBO y otros, para asegurarnos de que sea visible y esté listo para ser reservado.`,
    `Reservas y comunicación`,
    `Gestionamos todas las reservas y comunicaciones con los inquilinos. Nos aseguramos de que los inquilinos entren en su propiedad de forma segura. Actuamos como su principal punto de contacto durante toda su estancia, respondiendo a cualquier pregunta que puedan tener y ayudándoles en todo lo que podamos.`,
    `Pagos y actualizaciones de la propiedad`,
    `Como propietario de la propiedad, usted recibe un pago por cada reserva. Nuestras tarifas son transparentes y sin costos ocultos. Para preservar el aspecto y la sensación de su propiedad, proporcionamos inspecciones y recomendaciones de propiedades gratuitas y recurrentes.`
  ],
  nl: [
    `Verhuur uw woning zonder gedoe`,
    `Maximaliseer uw huurinkomsten bij ons`,
    `Marketing, Advertentiecreatie & Lijst`,
    `We bereiden uw woning voor op verhuur door de fotografie te plannen, de advertentie te schrijven en deze te plaatsen op de belangrijkste portals zoals Airbnb, Booking.com, VRBO en anderen, om ervoor te zorgen dat deze zichtbaar en klaar is om te worden geboekt.`,
    `Boekingen & Communicatie`,
    `We beheren alle reserveringen en communicatie met huurders. We zorgen ervoor dat de huurders veilig uw woning betreden. We fungeren als hun primaire contactpersoon tijdens hun hele verblijf, beantwoorden alle vragen die ze mogelijk hebben en helpen ze waar we kunnen.`,
    `Uitbetalingen & updates van de accommodatie`,
    `Als eigenaar van het pand krijgt u betaald voor elke boeking. Onze tarieven zijn transparant en zonder verborgen kosten. Om het uiterlijk en de sfeer van uw woning te behouden, bieden wij gratis, terugkerende inspecties en aanbevelingen voor onroerend goed.`
  ],
  de: [
    `Vermieten Sie Ihre Immobilie ohne Stress`,
    `Maximieren Sie Ihre Mieteinnahmen mit uns`,
    `Marketing, Anzeigenerstellung & Auflistung`,
    `Wir bereiten Ihre Immobilie auf die Vermietung vor, indem wir die Fotografie planen, die Anzeigenbeschreibung schreiben und sie auf den wichtigsten Portalen wie Airbnb, Booking.com, VRBO und anderen veröffentlichen, um sicherzustellen, dass sie sichtbar und buchbar ist.`,
    `Buchungen & Kommunikation`,
    `Wir verwalten alle Reservierungen und Kommunikationen mit Mietern. Wir stellen sicher, dass die Mieter sicher in Ihre Immobilie gelangen. Wir fungieren als ihre Hauptkontaktperson während ihres gesamten Aufenthalts, beantworten alle Fragen, die sie möglicherweise haben, und helfen ihnen, wo wir können.`,
    `Auszahlungen & Immobilien-Updates`,
    `Als Eigentümer der Immobilie erhalten Sie für jede Buchung eine Zahlung. Unsere Gebühren sind transparent und ohne versteckte Kosten. Um das Aussehen und das Gefühl Ihrer Immobilie zu erhalten, bieten wir kostenlose, wiederkehrende Immobilieninspektionen und Empfehlungen an.`
  ]
};
const HolidayHassleFree: FC<HolidayHassleFreeProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  return (
    <Box backgroundColor="secondary">
      <Container className="text-white">
        <Text type="h2">{text()}</Text>
        <Text type="h4" className="mb-8">
          {text()}
        </Text>
        <div className="grid grid-cols-1 lg:grid-cols-3 space-y-14 lg:space-y-0 lg:space-x-12">
          <div className="relative flex flex-col items-center">
            <span className="absolute text-lg font-bold flex items-center justify-center text-center bg-white rounded-full left-[calc(50%-4rem)] -top-4 text-secondary w-8 h-8">
              1
            </span>
            <Icon className="w-24 h-24 text-white" icon={rocket} />
            <Text type="h3">{text()}</Text>
            <Text>{text()}</Text>
          </div>
          <div className="relative flex flex-col items-center">
            <span className="absolute text-lg font-bold flex items-center justify-center text-center bg-white rounded-full left-[calc(50%-4rem)] -top-4 text-secondary w-8 h-8">
              2
            </span>
            <Icon className="w-24 h-24 text-white" icon={user} />
            <Text type="h3">{text()}</Text>
            <Text>{text()}</Text>
          </div>
          <div className="relative flex flex-col items-center">
            <span className="absolute text-lg font-bold flex items-center justify-center text-center bg-white rounded-full left-[calc(50%-4rem)] -top-4 text-secondary w-8 h-8">
              3
            </span>
            <Icon className="w-24 h-24 text-white" icon={house} />
            <Text type="h3">{text()}</Text>
            <Text>{text()}</Text>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default HolidayHassleFree;
