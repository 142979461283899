import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import { LanguageContext } from "../../Layout/Page";
import Text from "../../Widgets/Text/Text";
import Button from "../../Widgets/Button/Button";
import WhatsAppLink from "../../WhatsAppLink";
import SEO from "../../Widgets/SEO/SEO";

export type FAQProps = {};

const translations = {
  en: [
    "Let's get your ",
    "questions",
    " answered",
    "Below you will find the answers to the most frequently asked questions.",
    "Do you have a different question? Then feel free to send us message!",
    "I have an unanswered question",
    "Hi team FRESH,\n\nI have a question about your services: \n\n",
    "Contact us",
    "What makes Fresh different?",
    "How do we make this property stand out from the others? - What do we need to get year round bookings, even in low season? - What type of guest would like to stay in this property, and where would they book? We notice that other management companies are not asking these questions. They are running their ground operations effectively, but the occupancy and owner revenue isn't what it could be. While we also focus on cost effectiveness, we go above and beyond to create a 5 star guest experience. Because at the end of the day it is the guest who pays.",
    "What is included in your services?",
    "Everything! We help with design & furniture, take professional photos, 24-7 guest phone support and messaging, dynamic pricing to optimize your revenue, and cross-platform advertise to get your property online. We take care of planning the cleaning, supplies, maintenance, preventive maintenance, and quality control. Our clients also receive a monthly financial report.",
    "How much do you charge?",
    "For our management services we charge 20%. Larger portfolios may be eligible for lowered rates up to 15%. We believe in our services, that's why we don't ask for a specific contract length. You can cancel anytime and you own your listings and accounts.",
    "Are there any start-up costs?",
    "We will have professional photos taken (€150-250), a key lockbox (€35) or smartlock (€250 and €30 install). That's it! We handle the final touch-ups and list your property without any charge. If the property needs a redesign we work with you and local contractors to get the property ready in no time while keeping the costs low.",
    "What regions do you service?",
    "We offer full-service management in the Axarquía area. Contact us for other areas/countries.",
    "Do you manage long-term tenants?",
    "We currently focus solely on short-term rental management. Contact us to enquire about long-term properties."
  ],
  es: [
    "Obtenga sus ",
    "preguntas",
    " respondidas",
    "A continuación encontrará las respuestas a las preguntas más frecuentes.",
    "¿Tienes una pregunta diferente? ¡Entonces no dude en enviarnos un mensaje!",
    "Tengo una pregunta sin respuesta",
    "Hola equipo FRESH,\n\nTengo una pregunta sobre sus servicios: \n\n",
    "Contáctenos",
    "¿Qué hace que Fresh sea diferente?",
    "¿Cómo hacemos que esta propiedad se destaque de las demás? - ¿Qué necesitamos para obtener reservas durante todo el año, incluso en temporada baja? - ¿Qué tipo de huésped le gustaría alojarse en esta propiedad y dónde reservarían? Notamos que otras empresas de administración no hacen estas preguntas. Están ejecutando sus operaciones terrestres de manera efectiva, pero la ocupación y los ingresos del propietario no son lo que podrían ser. Si bien también nos enfocamos en la rentabilidad, hacemos todo lo posible para crear una experiencia de 5 estrellas para los huéspedes. Porque al final del día es el invitado quien paga.",
    "¿Qué se incluye en sus servicios?",
    "¡Todo! Ayudamos con el diseño y los muebles, tomamos fotos profesionales, soporte telefónico y de mensajería para huéspedes las 24 horas, los 7 días de la semana, precios dinámicos para optimizar sus ingresos y publicidad multiplataforma para poner su propiedad en línea. Nos encargamos de planificar la limpieza, los suministros, el mantenimiento, el mantenimiento preventivo y el control de calidad. Nuestros clientes también reciben un informe financiero mensual.",
    "¿Cuánto cobran?",
    "Para nuestros servicios de administración cobramos el 20%. Las carteras más grandes pueden ser elegibles para tarifas reducidas de hasta el 15%. Creemos en nuestros servicios, por eso no pedimos un contrato específico. Puede cancelar en cualquier momento y usted es el propietario de sus listados y cuentas.",
    "¿Hay algún costo de inicio?",
    "Tomaremos fotos profesionales (€150-250), una caja de seguridad para llaves (€35) o una cerradura inteligente (€250 y €30 de instalación). ¡Eso es! Nos encargamos de los últimos retoques y enumeramos su propiedad sin ningún cargo. Si la propiedad necesita un rediseño, trabajamos con usted y los contratistas locales para preparar la propiedad en poco tiempo y manteniendo los costos bajos.",
    "¿Qué regiones atienden?",
    "Ofrecemos servicios de administración integral en el área de la Axarquía. Contáctenos para otras áreas / países.",
    "¿Administra inquilinos a largo plazo?",
    "Actualmente nos enfocamos únicamente en la administración de alquileres a corto plazo. Contáctenos para consultar sobre propiedades a largo plazo."
  ],
  nl: [
    "Laten we je ",
    "vragen",
    " beantwoorden",
    "Hieronder vindt u de antwoorden op de meest gestelde vragen.",
    "Heeft u een andere vraag? Stuur ons dan gerust een bericht!",
    "Ik heb een onbeantwoorde vraag",
    "Hallo team FRESH,\n\nIk heb een vraag over jullie diensten: \n\n",
    "Neem contact met ons op",
    "Wat maakt Fresh anders?",
    "Hoe laten we deze woning opvallen tussen de andere? - Wat hebben we nodig om het hele jaar door boekingen te krijgen, zelfs in het laagseizoen? - Wat voor soort gast zou in deze woning willen verblijven en waar zouden ze boeken? We merken dat andere managementbedrijven deze vragen niet stellen. Ze voeren hun grondoperaties effectief uit, maar de bezettingsgraad en de inkomsten van de eigenaar zijn niet wat ze zouden kunnen zijn. Hoewel we ons ook richten op kostenbesparing, gaan we verder dan dat om een 5-sterren gastervaring te creëren. Want aan het eind van de dag is het de gast die betaalt.",
    "Wat zit er in uw diensten inbegrepen?",
    "Alles! We helpen met ontwerp en meubels, maken professionele foto's, 24-7 gastentelefoonondersteuning en berichten, dynamische prijzen om uw inkomsten te optimaliseren en adverteren op meerdere platforms om uw woning online te krijgen. We zorgen voor de planning van de schoonmaak, benodigdheden, onderhoud, preventief onderhoud en kwaliteitscontrole. Onze klanten ontvangen ook een maandelijks financieel rapport.",
    "Hoeveel rekenen jullie?",
    "Voor onze managementdiensten rekenen wij 20%. Grotere portefeuilles komen mogelijk in aanmerking voor verlaagde tarieven tot 15%. We geloven in onze diensten, daarom vragen we niet om een specifieke contractduur te ondertekenen. U kunt op elk moment annuleren en u bent eigenaar van uw advertenties en accounts.",
    "Zijn er opstartkosten?",
    "We laten professionele foto's maken (€150-250), een sleutelkluis (€35) of een slim slot (€250 en €30 installatie). Dat is alles! We regelen de laatste puntjes op de i en plaatsen uw woning zonder enige kosten. Als de woning een herinrichting nodig heeft, werken we met u en lokale aannemers om de woning in korte tijd klaar te maken, terwijl de kosten laag blijven.",
    "Welke regio's bedienen jullie?",
    "Wij bieden full-service management in de Axarquía regio. Neem contact met ons op voor andere gebieden / landen.",
    "Beheren jullie ook lange termijn verhuur?",
    "We richten ons momenteel uitsluitend op het beheer van korte termijn verhuur. Neem contact met ons op om te informeren naar lange termijn verhuur."
  ],
  de: [
    "Lass uns deine ",
    "Fragen",
    " beantworten",
    "Unten finden Sie die Antworten auf die am häufigsten gestellten Fragen.",
    "Haben Sie eine andere Frage? Dann senden Sie uns gerne eine Nachricht!",
    "Ich habe eine unbeantwortete Frage",
    "Hallo Team FRESH,\n\nIch habe eine Frage zu Ihren Dienstleistungen: \n\n",
    "Kontaktiere uns",
    "Was macht Fresh anders?",
    "Wie heben wir diese Immobilie von den anderen ab? - Was brauchen wir, um das ganze Jahr über Buchungen zu erhalten, auch in der Nebensaison? - Welche Art von Gast möchte in dieser Unterkunft übernachten und wo würden sie buchen? Wir stellen fest, dass andere Managementunternehmen diese Fragen nicht stellen. Sie führen ihre Bodenoperationen effektiv durch, aber die Belegung und die Einnahmen des Eigentümers sind nicht das, was sie sein könnten. Obwohl wir uns auch auf die Kostenoptimierung konzentrieren, gehen wir noch einen Schritt weiter, um ein 5-Sterne-Gasterlebnis zu schaffen. Denn am Ende des Tages ist es der Gast, der bezahlt.",
    "Was ist in Ihren Dienstleistungen enthalten?",
    "Alles! Wir helfen bei Design & Möbeln, machen professionelle Fotos, 24-7-Gasttelefon-Support und Messaging, dynamische Preisgestaltung zur Optimierung Ihrer Einnahmen und plattformübergreifende Werbung, um Ihre Immobilie online zu bringen. Wir kümmern uns um die Planung der Reinigung, der Vorräte, der Wartung, der vorbeugenden Wartung und der Qualitätskontrolle. Unsere Kunden erhalten auch einen monatlichen Finanzbericht.",
    "Wie viel berechnen Sie dafür?",
    "Für unsere Managementdienste berechnen wir 20%. Größere Portfolios können für reduzierte Preise von bis zu 15% in Frage kommen. Wir glauben an unsere Dienstleistungen, deshalb bitten wir nicht um eine bestimmte Vertragslaufzeit. Sie können jederzeit kündigen und Sie besitzen Ihre Angebote und Konten.",
    "Gibt es Startkosten?",
    "Wir lassen professionelle Fotos machen (€150-250), einen Schlüsseltresor (€35) oder ein Smartlock (€250 und €30 installieren). Das ist es! Wir kümmern uns um die letzten Feinheiten und listen Ihre Immobilie ohne Kosten auf. Wenn die Immobilie eine Neugestaltung benötigt, arbeiten wir mit Ihnen und lokalen Auftragnehmern zusammen, um die Immobilie in kürzester Zeit fertig zu stellen, während die Kosten niedrig bleiben.",
    "Welche Regionen bedienen Sie?",
    "Wir bieten Full-Service-Management im Bereich Axarquía an. Kontaktieren Sie uns für andere Gebiete / Länder.",
    "Verwalten Sie auch Langzeitmieter?",
    "Wir konzentrieren uns derzeit ausschließlich auf das Management von Kurzzeitmieten. Kontaktieren Sie uns, um sich nach Langzeitimmobilien zu erkundigen."
  ]
};

const seo = {
  title: {
    en: "Frequently Asked Questions",
    es: "Preguntas frecuentes",
    nl: "Veelgestelde vragen",
    de: "Häufig gestellte Fragen"
  },
  description: {
    en: "How do we make this property stand out from the others? - What do we need to get year round bookings, even in low season? - What type of guest would like to stay in this property, and where would they book? Get all your questions answered right here.",
    es: "¿Cómo hacemos que esta propiedad se destaque de las demás? - ¿Qué necesitamos para obtener reservas durante todo el año, incluso en temporada baja? - ¿Qué tipo de huésped le gustaría alojarse en esta propiedad y dónde reservarían? Obtenga todas sus preguntas respondidas aquí mismo.",
    nl: "Hoe laten we deze woning opvallen tussen de andere? - Wat hebben we nodig om het hele jaar door boekingen te krijgen, zelfs in het laagseizoen? - Wat voor soort gast zou in deze woning willen verblijven en waar zouden ze boeken? Krijg hier al je vragen beantwoord.",
    de: "Wie heben wir diese Immobilie von den anderen ab? - Was brauchen wir, um das ganze Jahr über Buchungen zu erhalten, auch in der Nebensaison? - Welche Art von Gast möchte in dieser Unterkunft übernachten und wo würden sie buchen? Hier finden Sie Antworten auf alle Ihre Fragen."
  }
};

const FAQ: FC<FAQProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  return (
    <SEO title={seo.title} description={seo.description}>
      <Box backgroundColor="primary">
        <Container className="text-white">
          <Text type="h1">
            {text()}
            <span className="text-yellow">{text()}</span>
            {text()}
          </Text>
          <Text>{text()}</Text>
          <Text>{text()}</Text>
          <div className="pt-4 flex flex-row items-center justify-center">
            <WhatsAppLink subject={text()} message={text()}>
              <Button type="inverse">{text()}</Button>
            </WhatsAppLink>
          </div>
        </Container>
        <svg
          className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
      </Box>
      <Box>
        <Container className="space-y-8" text={{ align: "left", color: "black" }}>
          <div className="block lg:flex flex-row items-start justify-start">
            <Text className="w-64 mr-8" type="h4">
              {text()}
            </Text>
            <Text className="flex-1">{text()}</Text>
          </div>
          <div className="block lg:flex flex-row items-start justify-start">
            <Text className="w-64 mr-8" type="h4">
              {text()}
            </Text>
            <Text className="flex-1">{text()}</Text>
          </div>
          <div className="block lg:flex flex-row items-start justify-start">
            <Text className="w-64 mr-8" type="h4">
              {text()}
            </Text>
            <Text className="flex-1">{text()}</Text>
          </div>
          <div className="block lg:flex flex-row items-start justify-start">
            <Text className="w-64 mr-8" type="h4">
              {text()}
            </Text>
            <Text className="flex-1">{text()}</Text>
          </div>
          <div className="block lg:flex flex-row items-start justify-start">
            <Text className="w-64 mr-8" type="h4">
              {text()}
            </Text>
            <Text className="flex-1">{text()}</Text>
          </div>
          <div className="block lg:flex flex-row items-start justify-start">
            <Text className="w-64 mr-8" type="h4">
              {text()}
            </Text>
            <Text className="flex-1">{text()}</Text>
          </div>
        </Container>
      </Box>
    </SEO>
  );
};

export default FAQ;
