import { MouseEvent, RefObject } from "react";

const useOnClickOnTarget = <T extends HTMLElement>(ref: RefObject<T>, onClickCallback: (e: MouseEvent<T>) => void) => {
  const onClick = (e: MouseEvent<T>) => {
    const { current } = ref;
    if (!current || e.currentTarget !== current) {
      return;
    }
    onClickCallback(e);
  };
  return onClick;
};

export default useOnClickOnTarget;
