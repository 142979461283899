import { Icon } from "@iconify/react";
import { FC, useMemo } from "react";
import facebook from "@iconify-icons/ph/facebook-logo-fill";
import insta from "@iconify-icons/ph/instagram-logo-fill";
import whatsapp from "@iconify-icons/ph/whatsapp-logo-fill";
import Text from "../Widgets/Text/Text";
import Container from "./Container";

export type FooterProps = {};

const Footer: FC<FooterProps> = ({}) => {
  const year = useMemo(() => new Date().getFullYear(), []);
  return (
    <>
      <footer role="contentinfo" className="bg-primary border-white border-t-4 text-white">
        <Container className="grid grid-cols-1 lg:grid-cols-4 items-center text-center">
          <Text type="h6">©{year} FRESH - Property Management</Text>
          <a className="text-md my-2 font-unicaone uppercase" href="mailto:info@fresh-propertymanagement.com">
            info@fresh-propertymanagement.com
          </a>
          <div className="justify-self-center">
            <a className="text-md my-2 font-unicaone uppercase block" href="tel:+31623700433">
              tel: +31 6 23700433
            </a>
          </div>
          <div className="flex flex-row items-center justify-center space-x-2">
            <a href="https://www.facebook.com/profile.php?id=100088949635064" target="_blank">
              <Icon className="w-8 h-8" icon={facebook} />
            </a>
            <a href="https://www.instagram.com/fresh_holidayrental/" target="_blank">
              <Icon className="w-8 h-8" icon={insta} />
            </a>
          </div>
        </Container>
        <a
          href="https://wa.me/+31623700433"
          target="_blank"
          className="group bg-white border-4 border-secondary w-24 h-24 fixed rounded-full bottom-5 right-4 flex items-center justify-center z-30"
        >
          <div className="relative">
            <div className="border-4 border-secondary absolute right-11 top-1/2 bg-white px-4 shadow rounded text-black">
              <Text className="w-max !my-1 mr-6">
                <small>Question? Send us a message!</small>
              </Text>
            </div>
            <div className="relative z-10 w-[5.5rem] h-[5.5rem] rounded-full bg-white flex items-center justify-center">
              <Icon className="text-primary w-16 h-16" icon={whatsapp} />
            </div>
          </div>
        </a>
      </footer>
      <div className="w-full p-1 bg-white/95 text-center fixed bottom-0">
        <small>This site uses cookies to track performance.</small>
      </div>
    </>
  );
};

export default Footer;
