export type PropertyLongTerm = {
  _id?: string;
  name: string;
  city: string;
  price?: string;
  sizeM2?: string;
  mapsLocation?: string;
  description?: string;
  images?: string[];
};

export type Area = {
  name: string;
  pueblos: string[];
};

export const extractLongTermImages = (images: string | string[] | undefined | null): string[] => {
  if (images === undefined || images === null) {
    return [];
  }
  if (typeof images === "string") {
    return images.split(",");
  }
  return images;
};
export const AllHouseForSaleTypes = [
  "Apartment",
  "Commercial",
  "House",
  "Plot",
  "Townhouse",
  "Villa",
  "Other"
] as const;
export type HouseForSaleType = (typeof AllHouseForSaleTypes)[number];

export type HouseForSaleSubType =
  | "Ground Floor Apartment"
  | "Middle Floor Apartment"
  | "Top Floor Apartment"
  | "Penthouse"
  | "Penthouse Duplex"
  | "Ground Floor Studio"
  | "Middle Floor Studio"
  | "Top Floor Studio"
  | "Duplex"
  | "Wooden House"
  | "Mobile Home"
  | "Cave House"
  | "Detached Villa"
  | "Semi-Detached House"
  | "Townhouse"
  | "Finca – Cortijo"
  | "Bungalow"
  | "Quad"
  | "Castle"
  | "City Palace"
  | "Wooden Cabin"
  | "Residential Plot"
  | "Commercial Plot"
  | "Land"
  | "Land with Ruin"
  | "Bar"
  | "Restaurant"
  | "Café"
  | "Hotel"
  | "Hostel"
  | "Guest House"
  | "Bed and Breakfast"
  | "Shop"
  | "Office"
  | "Storage Room"
  | "Parking Space"
  | "Farm"
  | "Night Club"
  | "Warehouse"
  | "Garage"
  | "Business"
  | "Mooring"
  | "Stables"
  | "Kiosk"
  | "Chiringuito"
  | "Beach Bar"
  | "Mechanics"
  | "Hairdressers"
  | "Photography Studio"
  | "Laundry"
  | "Aparthotel"
  | "Apartment Complex"
  | "Residential Home"
  | "Vineyard"
  | "Olive Grove"
  | "Car Park"
  | "Commercial Premises"
  | "Campsite"
  | "With Residence"
  | "Other";

export type HouseForSaleCategory = {
  id: string;
  name: string;
  values: string[];
};

export type HouseForSaleImage = {
  src: string;
  alt?: string;
};

export type HouseForSale = {
  externalId: string;
  status: string;
  ref: string;
  price: number;
  currency: string;
  type: HouseForSaleType;
  subtype: HouseForSaleSubType;
  country?: string;
  province?: string;
  town?: string;
  area?: string;
  beds?: number;
  baths?: number;
  levels?: number;
  surface_area: {
    built?: number;
    terrace?: number;
    plot?: number;
  };
  own_property: boolean;
  has_pool: boolean;
  has_garden: boolean;
  has_garage: boolean;
  characteristics: {
    category: HouseForSaleCategory[];
  };
  description: string;
  images: HouseForSaleImage[];
};

export type HouseForSaleResult = {
  numPages: number;
  houses: HouseForSale[];
};
