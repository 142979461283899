import { FC, PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import LayoutHeader from "./LayoutHeader";
import { MenuItemProps } from "./MenuItem";
import { isLanguage } from "./Page";

export type LayoutProps = PropsWithChildren<{}>;

const puebloLabels = {
  en: "Property for sale in ",
  es: "Casa en venta en ",
  nl: "Huis te koop in ",
  de: "Haus zu verkaufen in "
};

const items: MenuItemProps[] = [
  { url: "/", label: { en: "Home", es: "Home", nl: "Home", de: "Home" } },
  {
    url: "https://fresh.holiday/search",
    label: {
      en: "Book your stay",
      es: "Reserve su estancia",
      nl: "Boek je verblijf",
      de: "Aufenthalt Buchen"
    }
  },
  {
    url: "/services",
    label: { en: "Services", es: "Servicios", nl: "Diensten", de: "Dienstleistungen" },
    items: [
      {
        url: "/services/holiday-rental-management",
        label: {
          en: "Holiday Rentals",
          es: "Alquileres de vacaciones",
          nl: "Vakantiewoningen",
          de: "Ferienunterkünfte"
        }
      },
      {
        url: "/services/property-management",
        label: {
          en: "Property Management",
          es: "Gestión de la propiedad",
          nl: "Vastgoedbeheer",
          de: "Verwaltung von Immobilien"
        }
      },
      /*{
        url: "/services/property-investment",
        label: {
          en: "Property Investment",
          es: "Inversión inmobiliaria",
          nl: "Investering in vastgoed",
          de: "Immobilieninvestitionen"
        }
      },*/
      {
        url: "/services/purchase-agent",
        label: {
          en: "Purchase Agent",
          es: "Agente de compras",
          nl: "Aankoopmakelaar",
          de: "Kaufmakler"
        }
      }
    ]
  },
  {
    url: "/for-sale",
    label: {
      en: "For sale",
      es: "En venta",
      nl: "Te koop",
      de: "Zu verkaufen"
    }
    /*items: ["Málaga"].concat(allPueblos).map((pueblo) => ({
      url: pueblo === "Málaga" ? "/for-sale" : `/for-sale?location=${pueblo.toLowerCase().replaceAll(" ", "+")}`,
      label: {
        en: puebloLabels["en"] + pueblo,
        es: puebloLabels["es"] + pueblo,
        nl: puebloLabels["nl"] + pueblo,
        de: puebloLabels["de"] + pueblo
      }
    }))*/
  },
  {
    label: {
      en: "About",
      es: "Sobre nosotros",
      nl: "Over ons",
      de: "Über uns"
    },
    url: "/about",
    items: [
      {
        url: "/axarquia",
        label: {
          en: "Axarquía",
          es: "Axarquía",
          nl: "Axarquía",
          de: "Axarquía"
        }
      },
      {
        url: "/meet-your-team",
        label: {
          en: "Meet your team",
          es: "Conoce a tu equipo",
          nl: "Ontmoet je team",
          de: "Ihre Team"
        }
      },
      {
        url: "/faq",
        label: {
          en: "FAQ",
          es: "FAQ",
          nl: "FAQ",
          de: "FAQ"
        }
      },
      {
        url: "/contact",
        label: {
          en: "Contact",
          es: "Contacto",
          nl: "Contact",
          de: "Kontakt"
        }
      }
    ]
  },
  {
    url: "/blogs",
    label: {
      en: "Blog",
      es: "Blog",
      nl: "Blog",
      de: "Blog"
    }
  }
];

const Layout: FC<LayoutProps> = ({ children }) => {
  const { pathname } = useLocation();
  const possibleLanguage = pathname.split("/").pop();
  const language = isLanguage(possibleLanguage)
    ? possibleLanguage
    : window.location.hostname.endsWith("nl")
    ? "nl"
    : "en";
  return (
    <div className="relative min-h-screen flex flex-col">
      <LayoutHeader
        menuItems={items.map((item) => ({ ...item, active: pathname.replace(`${language}`, "") === item.url }))}
      />
      <main role="main" className="w-full h-full flex-1">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
