import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import HorizontalRuler from "../../Widgets/Separators/HorizontalRuler";
import Text from "../../Widgets/Text/Text";
import { Icon, IconifyIcon } from "@iconify/react";
import marketing from "@iconify-icons/ph/chart-line-up-bold";
import calendar from "@iconify-icons/ph/calendar-check-bold";
import welcome from "@iconify-icons/ph/address-book-bold";
import llave from "@iconify-icons/ph/key-bold";
import wrench from "@iconify-icons/ph/wrench-bold";
import bathtub from "@iconify-icons/ph/bathtub-bold";
import phone from "@iconify-icons/ph/phone-call-bold";
import { LanguageContext } from "../../Layout/Page";

type ItemProps = {
  label: string;
  icon: IconifyIcon;
  isLast?: boolean;
};

const Item: FC<ItemProps> = ({ label, icon, isLast }) => (
  <>
    <div className="p-2 flex flex-row items-center">
      <Icon icon={icon} className="text-primary w-6 h-6 mr-2" />
      <Text>{label}</Text>
    </div>
    {!isLast && <HorizontalRuler />}
  </>
);

const translations = {
  en: [
    `Property Marketing`,
    `Bookings Management`,
    `Guest Welcome Package`,
    `Check-in / Check-out Service`,
    `Coordination of repairs/maintenance`,
    `Coordination of cleaning between guest stays`,
    `Point of contact before and during guest stays`,
    `Our Pricing`,
    `Discover our simple & transparent pricing`,
    `We provide an all-inclusive service, covering all aspects of the rental process. We give homeowners peace of mind and ensure that all the areas are taken care of!`,
    `No financial surprises`,
    `We don't like surprises, at least not in the financial area. This is why we aim to be as transparent as possible in our pricing.`,
    `No hidden print`,
    `We offer our services using a minimal agreement. No strings attached, `,
    `literally`,
    `. Curious?
            Please reach out and have us send you a preview agreement.`,
    `Questions?`,
    `If you have any questions, please feel free to read our `,
    `FAQ`,
    ` or `,
    `Contact`,
    ` us directly. Further more you can fill in our form to get an insight in what your property could earn.`,
    `20% of rental revenue`,
    `An all inclusive solution featuring:`
  ],
  es: [
    `Marketing de Propiedades`,
    `Gestión de Reservas`,
    `Paquete de Bienvenida para Huéspedes`,
    `Servicio de Check-in / Check-out`,
    `Coordinación de reparaciones / mantenimiento`,
    `Coordinación de la limpieza entre las estancias de los huéspedes`,
    `Punto de contacto antes y durante las estancias de los huéspedes`,
    `Nuestra Tarifa`,
    `Descubra nuestra tarifa simple y transparente`,
    `Ofrecemos un servicio todo incluido, que cubre todos los aspectos del proceso de alquiler. ¡Damos a los propietarios la tranquilidad y nos aseguramos de que todas las áreas estén cuidadas!`,
    `Sin sorpresas financieras`,
    `No nos gustan las sorpresas, al menos no en el área financiera. Es por eso que nuestro objetivo es ser lo más transparentes posible en nuestra tarifa.`,
    `Sin letra pequeña`,
    `Ofrecemos nuestros servicios utilizando un acuerdo mínimo. Sin compromisos, `,
    `literalmente`,
    `. ¿Curioso?
            Por favor, póngase en contacto con nosotros y le enviaremos un acuerdo preliminar.`,
    `¿Preguntas?`,
    `Si tiene alguna pregunta, no dude en leer nuestra `,
    `FAQ`,
    ` o `,
    `Contacto`,
    ` nosotros directamente. Además, puede rellenar nuestro formulario para tener una idea de lo que podría ganar su propiedad.`,
    `20% de los ingresos por alquiler`,
    `Una solución todo incluido con:`
  ],
  nl: [
    `Property Marketing`,
    `Boekingenbeheer`,
    `Gast Welkomstpakket`,
    `Check-in / Check-out Service`,
    `Coördinatie van reparaties / onderhoud`,
    `Coördinatie van schoonmaak tussen gastverblijven`,
    `Contactpunt voor en tijdens het verblijf van de gast`,
    `Onze prijsstelling`,
    `Ontdek onze eenvoudige en transparante prijsstelling`,
    `Wij bieden een all-inclusive service, die alle aspecten van het verhuurproces bestrijkt. Wij geven huiseigenaren gemoedsrust en zorgen ervoor dat alle gebieden worden verzorgd!`,
    `Geen financiële verrassingen`,
    `Wij houden niet van verrassingen, althans niet op financieel gebied. Daarom streven wij ernaar zo transparant mogelijk te zijn in onze prijsstelling.`,
    `Geen verborgen print`,
    `Wij bieden onze diensten aan met een minimaal contract. Geen verplichtingen, `,
    `letterlijk`,
    `. Nieuwsgierig?
            Neem dan contact met ons op en wij sturen u een voorbeeldovereenkomst.`,
    `Vragen?`,
    `Als u vragen heeft, lees dan gerust onze `,
    `FAQ`,
    ` of `,
    `Contact`,
    ` ons rechtstreeks. Bovendien kunt u ons formulier invullen om inzicht te krijgen in wat uw woning zou kunnen verdienen.`,
    `20% van de huurinkomsten`,
    `Een all-inclusive oplossing met:`
  ],
  de: [
    `Property Marketing`,
    `Buchungsmanagement`,
    `Gast Willkommenspaket`,
    `Check-in / Check-out Service`,
    `Koordination von Reparaturen / Wartung`,
    `Koordination der Reinigung zwischen den Gästeaufenthalten`,
    `Ansprechpartner vor und während des Gästeaufenthalts`,
    `Unsere Preisgestaltung`,
    `Entdecken Sie unsere einfache und transparente Preisgestaltung`,
    `Wir bieten einen All-Inclusive-Service, der alle Aspekte des Vermietungsprozesses abdeckt. Wir geben den Hausbesitzern die Sicherheit und sorgen dafür, dass alle Bereiche abgedeckt sind!`,
    `Keine finanziellen Überraschungen`,
    `Wir mögen keine Überraschungen, zumindest nicht im finanziellen Bereich. Deshalb streben wir danach, in unserer Preisgestaltung so transparent wie möglich zu sein.`,
    `Kein versteckter Druck`,
    `Wir bieten unsere Dienstleistungen mit einem minimalen Vertrag an. Keine Verpflichtungen, `,
    `wörtlich`,
    `. Neugierig?
            Bitte kontaktieren Sie uns und lassen Sie uns Ihnen einen Vorvertrag zusenden.`,
    `Fragen?`,
    `Wenn Sie Fragen haben, lesen Sie bitte unsere `,
    `FAQ`,
    ` oder `,
    `Kontakt`,
    ` uns direkt. Darüber hinaus können Sie unser Formular ausfüllen, um einen Einblick zu erhalten, was Ihre Immobilie verdienen könnte.`,
    `20% der Mieteinnahmen`,
    `Eine All-Inclusive-Lösung mit:`
  ]
};

const getItems = (text: () => string): ItemProps[] => [
  { label: text(), icon: marketing },
  { label: text(), icon: calendar },
  { label: text(), icon: welcome },
  { label: text(), icon: llave },
  { label: text(), icon: wrench },
  { label: text(), icon: bathtub },
  { label: text(), icon: phone }
];

export type HolidayTransparentPricingProps = {};

const HolidayTransparentPricing: FC<HolidayTransparentPricingProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  const items = getItems(text);
  return (
    <Box backgroundColor="primary">
      <Container className="grid grid-cols-1 lg:grid-cols-2">
        <div className="text-white px-8 py-4 my-4 lg:my-0">
          <Text type="h2">{text()}</Text>
          <Text type="h4">{text()}</Text>
          <Text>{text()}</Text>
          <Text type="h4" className="mt-8">
            {text()}
          </Text>
          <Text>{text()}</Text>
          <Text type="h4" className="mt-8">
            {text()}
          </Text>
          <Text>
            {text()}
            <span>{text()}</span>
            {text()}
          </Text>
          <Text type="h4" className="mt-8">
            {text()}
          </Text>
          <Text>
            {text()}
            <a href="/faq" target="_self" className="text-white hover:text-secondary underline">
              {text()}
            </a>
            {text()}
            <a href="/contact" target="_self" className="text-white hover:text-secondary underline">
              {text()}
            </a>
            {text()}
          </Text>
        </div>
        <div className="bg-white rounded overflow-hidden w-fit mx-auto my-4 lg:my-0">
          <div className="bg-secondary px-8 py-4 text-white">
            <Text type="h2">{text()}</Text>
          </div>
          <div className="px-8 py-4">
            <Text type="h4">{text()}</Text>
            <div className="mt-2 text-left">
              {items.map((item, i) => (
                <Item key={`item-${i}`} {...item} isLast={i === items.length - 1} />
              ))}
            </div>
          </div>
        </div>
      </Container>
      <svg
        className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-secondary"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
      >
        <path
          d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
        ></path>
      </svg>
    </Box>
  );
};

export default HolidayTransparentPricing;
