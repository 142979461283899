import { FC, useRef } from "react";

import { Editor } from "@tinymce/tinymce-react";
import useImagesUpload from "./useImagesUpload";
import { addTextClasses, joinStringGroups, joinStrings, setupCustomTinyMCEPlugins } from "./helpers";
import { TextType } from "../types";
import { generateTextClassName } from "../Text";
import { classNames } from "../../../utils/string";

const h1 = generateTextClassName("h1", "");
const h2 = generateTextClassName("h2", "");
const h3 = generateTextClassName("h3", "");
const h4 = generateTextClassName("h4", "");
const h5 = generateTextClassName("h5", "");
const h6 = generateTextClassName("h6", "");
const p = generateTextClassName("p", "");

export type TextEditorProps = {
  className?: string;
  initialValue?: string;
  textType?: TextType;
  imageUploadUrl?: string;
  onChange?: (content: string) => void;
  onSave?: (content: string) => void;
  allowFontSize?: boolean;
};

const TextEditor: FC<TextEditorProps> = ({
  className,
  textType,
  initialValue = `<${textType ?? "p"}></${textType ?? "p"}>`,
  imageUploadUrl,
  allowFontSize = false,
  onChange,
  onSave
}) => {
  const ref = useRef<Editor>(null);
  const disabled = onChange === undefined && onSave === undefined;
  return (
    <div className={classNames("w-full", className)}>
      {disabled ? (
        <div
          contentEditable="false"
          className="mce-content-body"
          dangerouslySetInnerHTML={{ __html: initialValue }}
        ></div>
      ) : (
        <Editor
          inline
          onKeyDown={(e) => e.stopPropagation()}
          onKeyUp={(e) => e.stopPropagation()}
          disabled={disabled}
          ref={ref}
          value={initialValue}
          tinymceScriptSrc={"/tinymce/tinymce.min.js"}
          init={{
            branding: false,
            autoresize_bottom_margin: 0,
            menubar: false,
            font_size_formats:
              "8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 18pt 20pt 22pt 24pt 30pt 36pt 40pt 44pt 48pt 54pt 60pt 72pt 96pt",
            line_height_formats: "0.5 0.6 0.7 0.8 0.9 1.0 1.1 1.2 1.3 1.4 1.5 1.6 1.7 1.8 1.9 2 2.25 2.5",
            plugins: joinStrings(
              " ",
              "autoresize",
              "autolink",
              "importcss",
              "preview",
              "searchreplace",
              "directionality",
              "visualblocks",
              "visualchars",
              "fullscreen",
              "image",
              "link",
              "media",
              "codesample",
              "table",
              "charmap",
              "pagebreak",
              "nonbreaking",
              "insertdatetime",
              "advlist",
              "lists",
              "wordcount",
              "anchor",
              "save"
            ),
            toolbar: joinStringGroups(
              " ",
              " | ",
              [!textType && "blocks"],
              [
                allowFontSize ? "fontsize" : undefined,
                allowFontSize ? "lineheight" : undefined,
                "alignment",
                "decoration",
                "forecolor",
                "removeformat"
              ].filter((t) => t !== undefined),
              ["lists", "outdent", "indent", "blockquote", "hr"],
              ["table", "link", imageUploadUrl && "image", "media"],
              ["anchor", "button"],
              [onSave && "save"]
            ),
            formats: {
              bold: { inline: "span", classes: "font-bold" },
              italic: { inline: "span", classes: "italic" },
              underline: { inline: "span", classes: "underline" },
              strikethrough: { inline: "span", classes: "line-through" },
              h1: { block: "h1", attributes: { class: h1 } },
              h2: { block: "h2", attributes: { class: h2 } },
              h3: { block: "h3", attributes: { class: h3 } },
              h4: { block: "h4", attributes: { class: h4 } },
              h5: { block: "h5", attributes: { class: h5 } },
              h6: { block: "h6", attributes: { class: h6 } },
              p: { block: "p", attributes: { class: p } }
            },
            setup: setupCustomTinyMCEPlugins,
            content_css: "/index.css",
            paste_data_images: true,
            automatic_uploads: true,
            toolbar_sticky: true,
            images_upload_handler: useImagesUpload(imageUploadUrl),
            image_advtab: true,
            save_onsavecallback: () => onSave?.(addTextClasses(ref.current?.editor?.getContent()))
          }}
          onEditorChange={() => {
            onChange?.(addTextClasses(ref.current?.editor?.getContent()));
          }}
        />
      )}
    </div>
  );
};

export default TextEditor;
