import { RefObject, useEffect, useRef } from "react";

export type SwipeHorizontalActions = {
  onPrevious?: () => void;
  onNext?: () => void;
};

const useSwipeHorizontal = (ref: RefObject<HTMLElement>, actions: SwipeHorizontalActions) => {
  const touchPosition = useRef<Touch | null>(null);
  useEffect(() => {
    const element = ref.current;

    const handleTouchStart = (e: TouchEvent) => {
      touchPosition.current = e.touches[0];
    };

    const handleTouchMove = (e: TouchEvent) => {
      e.preventDefault();
      const touchDown = touchPosition.current;
      if (touchDown === null) {
        return;
      }
      const currentTouch = e.touches[0];
      const diffX = touchDown.clientX - currentTouch.clientX;
      const diffY = Math.abs(touchDown.clientY - currentTouch.clientY);
      if (Math.abs(diffX) > diffY) {
        e.stopPropagation();
        if (diffX < -5) {
          actions.onPrevious?.();
          touchPosition.current = null;
        }
        if (diffX > 5) {
          actions.onNext?.();
          touchPosition.current = null;
        }
        return false;
      }
    };

    element?.addEventListener("touchstart", handleTouchStart);
    element?.addEventListener("touchmove", handleTouchMove, { passive: false });
    return () => {
      element?.removeEventListener("touchstart", handleTouchStart);
      element?.removeEventListener("touchmove", handleTouchMove);
    };
  }, [actions.onPrevious, actions.onNext]);
};

export default useSwipeHorizontal;
