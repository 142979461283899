import { leadingZeroes } from "./number";

export const leadingDate = (date: Date) => leadingZeroes(date.getDate());
export const leadingMonth = (date: Date) => leadingZeroes(date.getMonth() + 1);

export const ymd = (date = new Date(), separator: string = "-") =>
  `${date.getFullYear()}${separator}${leadingMonth(date)}${separator}${leadingDate(date)}`;

export const fromYmd = (dateString: string | undefined | null, separator: string = "-") => {
  if (!dateString) {
    return null;
  }
  const parts = dateString.split(separator).map((v) => parseInt(v));
  if (parts.some((v) => isNaN(v)) || parts.length !== 3) {
    return null;
  }
  return new Date(parts[0], parts[1] - 1, parts[2]);
};

export const dmy = (date = new Date(), separator: string = "-") =>
  `${leadingDate(date)}${separator}${leadingMonth(date)}${separator}${date.getFullYear()}`;

export const unwrapAndCloneDate = (date = new Date()) => new Date(date);

export const startOfYear = (date?: Date) => {
  const d = unwrapAndCloneDate(date);
  d.setMonth(0);
  d.setDate(1);
  return d;
};
export const endOfYear = (date?: Date) => yesterday(startOfYear(nextYear(date)));
export const yearsAgo = (years: number, date?: Date) => {
  const d = unwrapAndCloneDate(date);
  d.setFullYear(d.getFullYear() - years);
  return d;
};
export const yearsAhead = (years: number, date?: Date) => {
  const d = unwrapAndCloneDate(date);
  d.setFullYear(d.getFullYear() + years);
  return d;
};
export const lastYear = (date?: Date) => yearsAgo(1, date);
export const nextYear = (date?: Date) => yearsAhead(1, date);

// MONTHS:
export const startOfMonth = (date?: Date) => {
  const d = unwrapAndCloneDate(date);
  d.setDate(1);
  return d;
};

export const endOfMonth = (date?: Date) => yesterday(startOfMonth(nextMonth(date)));

export const monthsAgo = (months: number, date?: Date) => {
  const d = unwrapAndCloneDate(date);
  d.setMonth(d.getMonth() - months);
  return d;
};

export const monthsAhead = (months: number, date?: Date) => {
  const d = unwrapAndCloneDate(date);
  d.setMonth(d.getMonth() + months);
  return d;
};

export const lastMonth = (date?: Date) => monthsAgo(1, date);
export const nextMonth = (date?: Date) => monthsAhead(1, date);

export const startOfLastMonth = (date?: Date) => startOfMonth(lastMonth(date));
export const endOfLastMonth = (date?: Date) => endOfMonth(lastMonth(date));

export const startOfNextMonth = (date?: Date) => startOfMonth(nextMonth(date));
export const endOfNextMonth = (date?: Date) => endOfMonth(nextMonth(date));

export const startOfDay = (date?: Date) => {
  const d = unwrapAndCloneDate(date);
  d.setHours(0, 0, 0);
  return d;
};
export const endOfDay = (date?: Date) => {
  const d = unwrapAndCloneDate(date);
  d.setHours(23, 59, 59);
  return d;
};
export const daysAgo = (days: number, date?: Date) => {
  const d = unwrapAndCloneDate(date);
  d.setDate(d.getDate() - days);
  return d;
};
export const daysAhead = (days: number, date?: Date) => {
  const d = unwrapAndCloneDate(date);
  d.setDate(d.getDate() + days);
  return d;
};
export const yesterday = (date?: Date) => daysAgo(1, date);
export const tomorrow = (date?: Date) => daysAhead(1, date);

export const isDate = (object: unknown): object is Date => object instanceof Date;
