import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text, { generateTextClassName } from "../../Widgets/Text/Text";
import { LanguageContext } from "../../Layout/Page";
import Button from "../../Widgets/Button/Button";
import { useNavigate } from "react-router-dom";

export type BuyingAgentHowItWorksProps = {};

const translations = {
  en: [
    "How does it work and what are the costs?",
    //"Refundable deposit*: 500 € + VAT",
    //"*When booking our services, the deposit of 500 € + VAT is due. This fee covers our expenses for up to nine months to search for properties, schedule appointments, accompany you to view the properties and negotiate the sales price with the owner or real estate agent. Includes meeting with an attorney to discuss purchase requirements. If you make a purchase, the refundable deposit is paid back by us.",
    //"So in normal circumstances, you end up ",
    //"paying nothing",
    //" for our services.",
    //"How do we get paid for our service?",
    `We are here to guide and support you throughout the entire process of purchasing your new home. Our services are entirely <em class="underline font-black">free of charge to you</em>, and we will work closely with you and all the other parties involved, including the lawyer.`,
    `It is important that you do not register with any other real estate agent or leave your contact information with them. This is because of a principle called the "exclusivity clause," which means that once a potential buyer is known to another agent, that agent has the exclusive right to represent that buyer. This can be frustrating for buyers who would prefer to work with a different agent, but it is a standard practice in the real estate industry.`,
    `The cost of our services is covered by a standard commission of a few percent that is paid on every property that is sold. This commission is split between the selling and buying real estate agents. If one of the parties does not have an agent, the full commission goes to the selling agent. This percentage is typically higher for new construction projects than for existing construction, which is why some agents may try to steer you towards new builds.`,
    `We are committed to working solely for your benefit, and we will put your wishes and needs first. We will always act in your best interest and will not be influenced by any other considerations.`,
    "Interested?",
    "Have a look at our portfolio",
    " or ",
    "Fill out your wishlist",
    " or ",
    "Contact us directly"
  ],
  es: [
    "¿Cómo funciona y cuáles son los costos?",
    //"Depósito reembolsable *: 500 € + IVA",
    //"* Al reservar nuestros servicios, se debe pagar el depósito de 500 € + IVA. Esta tarifa cubre nuestros gastos durante un máximo de nueve meses para buscar propiedades, programar citas, acompañarlo a ver las propiedades y negociar el precio de venta con el propietario o agente inmobiliario. Incluye reunión con un abogado para discutir los requisitos de compra. Si realiza una compra, el depósito reembolsable es pagado por nosotros.",
    //"Por lo tanto, en circunstancias normales, termina sin ",
    //"pagar nada",
    //" por nuestros servicios.",
    //"¿Cómo nos pagan por nuestro servicio?",
    `Estamos aquí para guiarlo y apoyarlo durante todo el proceso de compra de su nuevo hogar. Nuestros servicios son <em class="underline font-black">completamente gratuitos para usted</em>, y trabajaremos estrechamente con usted y todas las demás partes involucradas, incluido el abogado.`,
    `Es importante que no se registre con ningún otro agente de bienes raíces ni deje su información de contacto con ellos. Esto se debe a un principio llamado "cláusula de exclusividad", que significa que una vez que un comprador potencial es conocido por otro agente, ese agente tiene el derecho exclusivo de representar a ese comprador. Esto puede ser frustrante para los compradores que preferirían trabajar con un agente diferente, pero es una práctica estándar en la industria inmobiliaria.`,
    `El costo de nuestros servicios está cubierto por una comisión estándar de unos pocos puntos porcentuales que se paga en cada propiedad que se vende. Esta comisión se divide entre los agentes de bienes raíces que venden y compran. Si una de las partes no tiene un agente, la comisión completa va al agente vendedor. Este porcentaje suele ser más alto para proyectos de nueva construcción que para construcciones existentes, por lo que algunos agentes pueden intentar dirigirlo hacia nuevas construcciones.`,
    `Estamos comprometidos a trabajar únicamente en su beneficio, y pondremos sus deseos y necesidades en primer lugar. Siempre actuaremos en su mejor interés y no seremos influenciados por ninguna otra consideración.`,
    "¿Interesado?",
    "Eche un vistazo a nuestro portafolio",
    " o ",
    "Complete la lista de deseos",
    " o ",
    "Contáctenos directamente"
  ],
  nl: [
    "Hoe werkt het en wat zijn de kosten?",
    //"Terugbetaalbare aanbetaling *: 500 € + BTW",
    //"* Bij het boeken van onze diensten is de aanbetaling van 500 € + BTW verschuldigd. Dit bedrag dekt onze kosten tot negen maanden om naar woningen te zoeken, afspraken in te plannen, u te begeleiden bij het bekijken van de woningen en de verkoopprijs te onderhandelen met de eigenaar of makelaar. Inclusief een ontmoeting met een advocaat om de aankoopvereisten te bespreken. Als u een aankoop doet, wordt de terugbetaalbare aanbetaling door ons terugbetaald.",
    //"Dus onder normale omstandigheden betaalt u ",
    //"niets",
    //" voor onze diensten.",
    //"Hoe worden we betaald voor onze service?",
    `Wij zijn er om u te begeleiden en ondersteunen tijdens het gehele aankoopproces van uw nieuwe woning. Onze dienstverlening is <em class="underline font-black">voor u geheel kosteloos</em> en wij werken nauw samen met u en alle andere betrokken partijen, inclusief de advocaat.`,
    `Het is belangrijk dat u zich niet bij een andere makelaar inschrijft of uw contactgegevens bij hen achterlaat. Dit komt door een principe dat de 'exclusiviteitsclausule' wordt genoemd, wat betekent dat zodra een potentiële koper bekend is bij een andere agent, die agent het exclusieve recht heeft om die koper te vertegenwoordigen. Dit kan frustrerend zijn voor kopers die liever met een andere makelaar werken, maar het is een standaardpraktijk in de vastgoedsector.`,
    `De kosten van onze diensten worden gedekt door een standaardcommissie van een paar procent die wordt betaald over elk verkocht onroerend goed. Deze commissie wordt verdeeld tussen de verkopende en kopende makelaars. Indien een van de partijen geen makelaar heeft, gaat de volledige commissie naar de verkopende makelaar. Dit percentage is doorgaans hoger voor nieuwbouwprojecten dan voor bestaande bouw. Daarom kunnen sommige makelaars proberen u naar nieuwbouw te sturen.`,
    `Wij streven ernaar uitsluitend voor u te werken en stellen uw wensen en behoeften voorop. Wij handelen altijd in uw belang en laten ons niet beïnvloeden door andere overwegingen.`,
    "Geïnteresseerd?",
    "Neem een kijkje in ons portfolio",
    " of ",
    "Vul je wensenlijst in",
    " of ",
    "Neem direct contact op"
  ],
  de: [
    "Wie funktioniert es und was sind die Kosten?",
    //"Rückerstattbare Anzahlung *: 500 € + MwSt.",
    //"* Bei der Buchung unserer Dienstleistungen ist die Anzahlung von 500 € + MwSt. fällig. Diese Gebühr deckt unsere Ausgaben für bis zu neun Monate, um nach Immobilien zu suchen, Termine zu vereinbaren, Sie zu Besichtigungen zu begleiten und den Verkaufspreis mit dem Eigentümer oder Immobilienmakler zu verhandeln. Beinhaltet ein Treffen mit einem Anwalt, um Kaufanforderungen zu besprechen. Wenn Sie einen Kauf tätigen, wird die rückerstattbare Anzahlung von uns zurückgezahlt.",
    //"Also unter normalen Umständen enden Sie damit, ",
    //"nichts",
    //" für unsere Dienstleistungen zu bezahlen.",
    //"Wie werden wir für unseren Service bezahlt?",
    `Wir sind hier, um Sie während des gesamten Prozesses des Kaufs Ihres neuen Hauses zu begleiten und zu unterstützen. Unsere Dienstleistungen sind <em class="underline font-black">für Sie völlig kostenlos</em>, und wir werden eng mit Ihnen und allen anderen beteiligten Parteien, einschließlich des Anwalts, zusammenarbeiten.`,
    `Es ist wichtig, dass Sie sich nicht bei einem anderen Immobilienmakler registrieren oder Ihre Kontaktdaten bei ihnen hinterlassen. Dies liegt an einem Prinzip, das als "Exklusivitätsklausel" bezeichnet wird, was bedeutet, dass, sobald ein potenzieller Käufer einem anderen Makler bekannt ist, dieser Makler das ausschließliche Recht hat, diesen Käufer zu vertreten. Dies kann für Käufer frustrierend sein, die lieber mit einem anderen Makler arbeiten würden, aber es ist eine Standardpraxis in der Immobilienbranche.`,
    `Die Kosten unserer Dienstleistungen werden durch eine Standardprovision von einigen Prozent gedeckt, die bei jedem verkauften Objekt gezahlt wird. Diese Provision wird zwischen den verkaufenden und kaufenden Immobilienmaklern aufgeteilt. Wenn eine der Parteien keinen Makler hat, geht die volle Provision an den verkaufenden Makler. Dieser Prozentsatz ist in der Regel für Neubauprojekte höher als für bestehende Bauwerke, weshalb einige Makler versuchen könnten, Sie zu Neubauten zu lenken.`,
    `Wir sind verpflichtet, ausschließlich zu Ihrem Vorteil zu arbeiten, und wir werden Ihre Wünsche und Bedürfnisse immer an die erste Stelle setzen. Wir werden immer in Ihrem besten Interesse handeln und werden nicht von anderen Überlegungen beeinflusst.`,
    "Interessiert?",
    "Schauen Sie sich unser Portfolio",
    " oder ",
    "Füllen Sie Ihnen Wunschliste aus",
    " oder ",
    "Kontaktieren Sie uns direkt"
  ]
};

const BuyingAgentHowItWorks: FC<BuyingAgentHowItWorksProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  const forSaleUrl = `/for-sale${language === "en" ? "" : `/${language}`}`;
  const wishlistUrl = `https://docs.google.com/forms/d/e/1FAIpQLSddOT-IOUtzDMefwHJjNphk-WkLQI4J7-sL_B4_pmOIueuGUg/viewform`;
  const contactUrl = `https://wa.me/+31623700433?text=Hi team FRESH, I would like to have some more info on your buying agent services. Kind regards,`;
  return (
    <Box>
      <Container>
        <Text type="h3">{text()}</Text>
        {/*<Text type="h4">{text()}</Text>
        <Text>{text()}</Text>
        <Text>
          {text()}
          <span className="underline">{text()}</span>
          {text()}
        </Text>
  <Text type="h4">{text()}</Text>*/}
        <p className={generateTextClassName("p", "")} dangerouslySetInnerHTML={{ __html: text() }} />
        <Text>{text()}</Text>
        <Text>{text()}</Text>
        <Text>{text()}</Text>
        <Text>{text()}</Text>
        <div className="flex flex-wrap flex-col lg:flex-row items-center lg:justify-center gap-4">
          <Button type="primary" onClick={() => navigate(forSaleUrl)}>
            {text()}
          </Button>
          <div className="flex flex-row items-center gap-4">
            <span className="hidden lg:block">{text()}</span>
            <Button type="primary" onClick={() => window.open(wishlistUrl, "_blank", "noreferrer")}>
              {text()}
            </Button>
          </div>
          <div className="flex flex-row items-center gap-4">
            <span className="hidden lg:block">{text()}</span>
            <Button type="primary" onClick={() => window.open(contactUrl, "_blank", "noreferrer")}>
              {text()}
            </Button>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default BuyingAgentHowItWorks;
