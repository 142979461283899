import { FC, useContext, useEffect, useState } from "react";
import Box from "../../Layout/Box";
import Text from "../../Widgets/Text/Text";
import useGetData from "../../hooks/useGetData";
import HomeReviewCard, { GuestReview } from "./HomeReviewCard";
import Container from "../../Layout/Container";
import { LanguageContext } from "../../Layout/Page";

export type HomeReviewProps = {};

const shuffleArray = <T,>(array: Array<T>) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

function* chunks<T>(arr: T[], n: number): Generator<T[], void> {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}
const translations = {
  en: [`Some of our reviews`],
  es: [`Algunas de nuestras reseñas`],
  nl: [`Enkele van onze beoordelingen`],
  de: [`Einige unserer Bewertungen`]
};
const HomeReview: FC<HomeReviewProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  const [loading, reviews] = useGetData<GuestReview[]>("/client/reviews");
  const [sortedReviews, setSortedReviews] = useState<GuestReview[][]>([]);

  useEffect(() => {
    if (reviews) {
      const sorted = [...reviews.filter((review) => review.approved)].sort((a, b) => a.name.localeCompare(b.name));
      shuffleArray(sorted);
      setSortedReviews([...chunks(sorted, 2)]);
    }
  }, [reviews]);

  return (
    <Box backgroundColor="grey">
      <Container>
        <Text className="text-center !my-8" type="h2">
          {text()}
        </Text>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {sortedReviews.slice(0, 2).map((reviews, i) => (
            <div key={`chunk-${i}`} className="grid gap-4">
              {reviews.map((review) => (
                <HomeReviewCard key={review.id} review={review} />
              ))}
            </div>
          ))}
        </div>
      </Container>
    </Box>
  );
};

export default HomeReview;
