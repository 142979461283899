import { FC, useContext, useState, useEffect } from "react";
import axios from "axios";
import Page, { LanguageContext } from "../../Layout/Page";
import SEO from "../../Widgets/SEO/SEO";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import Button from "../../Widgets/Button/Button"; // Assuming you have a Button component


const seo = {
  title: {
    "en": "Costa del Sol New Construction Projects",
    "es": "Nueva Construcción en la Costa del Sol",
    "nl": "Nieuwbouwprojecten aan de Costa del Sol",
    "de": "Neubauprojekte an der Costa del Sol"
  },
  description: {
    "en": "Explore the beautiful coastline of the Costa del Sol in southern Spain, featuring vibrant cities like Málaga, Marbella, Fuengirola, Nerja, Estepona, Torremolinos, Benalmádena, Rincón de la Victoria, and Mijas. The Axarquía region is an emerging area within the Costa del Sol, set to experience significant growth in the coming years due to numerous new construction projects. Known for its natural beauty and authentic Andalusian charm, Axarquía is on the brink of substantial development. With new residential projects and infrastructure improvements, this region offers excellent opportunities for investors and those seeking a second home or a luxury vacation villa. Whether you are looking for a new apartment or a luxury villa, the Costa del Sol and the rapidly growing Axarquía region offer a world of possibilities. Discover the potential of this beautiful region and find your dream home today.",
    
    "es": "Explore la hermosa costa de la Costa del Sol en el sur de España, con ciudades vibrantes como Málaga, Marbella, Fuengirola, Nerja, Estepona, Torremolinos, Benalmádena, Rincón de la Victoria y Mijas. La región de Axarquía es una zona emergente dentro de la Costa del Sol, que experimentará un crecimiento significativo en los próximos años gracias a numerosos proyectos de nueva construcción. Conocida por su belleza natural y su auténtico encanto andaluz, Axarquía está al borde de un desarrollo sustancial. Con nuevos proyectos residenciales y mejoras en la infraestructura, esta región ofrece excelentes oportunidades para inversores y aquellos que buscan una segunda residencia o una villa de lujo para vacaciones. Ya sea que esté buscando un nuevo apartamento o una villa de lujo, la Costa del Sol y la región de Axarquía, que está creciendo rápidamente, ofrecen un mundo de posibilidades. Descubra el potencial de esta hermosa región y encuentre hoy mismo la casa de sus sueños.",
    
    "nl": "Ontdek de prachtige kustlijn van de Costa del Sol in het zuiden van Spanje, met levendige steden zoals Málaga, Marbella, Fuengirola, Nerja, Estepona, Torremolinos, Benalmádena, Rincón de la Victoria en Mijas. De Axarquía-regio is een opkomend gebied binnen de Costa del Sol, dat de komende jaren aanzienlijke groei zal doormaken dankzij tal van nieuwbouwprojecten. Bekend om haar natuurlijke schoonheid en authentieke Andalusische charme, staat Axarquía aan de vooravond van substantiële ontwikkeling. Met nieuwe woonprojecten en infrastructuurverbeteringen biedt deze regio uitstekende kansen voor investeerders en mensen die op zoek zijn naar een tweede huis of een luxe vakantievilla. Of u nu op zoek bent naar een nieuw appartement of een luxe villa, de Costa del Sol en de snelgroeiende Axarquía-regio bieden een wereld aan mogelijkheden. Ontdek het potentieel van deze prachtige regio en vind vandaag nog uw droomwoning.",
    
    "de": "Entdecken Sie die wunderschöne Küste der Costa del Sol im Süden Spaniens, mit lebendigen Städten wie Málaga, Marbella, Fuengirola, Nerja, Estepona, Torremolinos, Benalmádena, Rincón de la Victoria und Mijas. Die Region Axarquía ist ein aufstrebendes Gebiet innerhalb der Costa del Sol, das in den kommenden Jahren dank zahlreicher Neubauprojekte ein erhebliches Wachstum erleben wird. Bekannt für ihre natürliche Schönheit und authentischen andalusischen Charme, steht Axarquía am Rande einer bedeutenden Entwicklung. Mit neuen Wohnprojekten und Infrastrukturverbesserungen bietet diese Region hervorragende Möglichkeiten für Investoren und diejenigen, die ein zweites Zuhause oder eine Luxus-Ferienvilla suchen. Egal, ob Sie nach einer neuen Wohnung oder einer Luxusvilla suchen, die Costa del Sol und die schnell wachsende Region Axarquía bieten eine Welt voller Möglichkeiten. Entdecken Sie das Potenzial dieser wunderschönen Region und finden Sie noch heute Ihr Traumhaus."
  }
  
};


export type NewDevelopmentProps = {};

interface Property {
  _id: string;
  name: string;
  price: string;
  sizeM2: string;
  mapsLocation: string;
  description: string;
  city: string;
  images: string[];
}

const NewDevelopment: FC<NewDevelopmentProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  const [properties, setProperties] = useState<Property[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(
          "https://api.fresh-propertymanagement.com/long-term"
        );
        setProperties(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  const toggleReadMore = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const getShortDescription = (description: string) => {
    const text = description.replace(/(<([^>]+)>)/gi, ""); // Strip HTML tags
    return text.length > 60 ? text.substring(0, 60) + "..." : text;
  };

  return (
    <Page>
      <SEO title={seo.title[language]} description={seo.description[language]}>
        <Box>
          <Container>
            <Text type="h2">{seo.title[language]}</Text>
            <Text>{seo.description[language]}</Text>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "16px",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {loading ? (
                <Text>Loading properties...</Text>
              ) : (
                properties.map((property, index) => (
                  <div
                    className="property-card"
                    key={property._id}
                    style={{
                      flex: "1 1 calc(33.333% - 16px)",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      padding: "16px",
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#fff",
                      maxWidth: "calc(33.333% - 16px)",
                      boxSizing: "border-box",
                    }}
                  >
                    <img
                      src={`https://api.fresh-propertymanagement.com${property.images[0]}`}
                      alt={property.name}
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />

                    <Box padding="16px" className="height275">
                      <Text type="h3">{property.name}</Text>

                      <Text>
                        Price: ${property.price} <br />
                        Size: {property.sizeM2} m² <br />
                        City: {property.city}
                      </Text>

                      <div
                        style={{
                          maxHeight: expandedIndex === index ? "100%" : "60px",
                          overflow: "hidden",
                          transition: "max-height 0.3s ease",
                          scrollbarWidth: "none",
                          msOverflowStyle: "none",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              expandedIndex === index
                                ? property.description
                                : getShortDescription(property.description),
                          }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                        }}
                      >
                        <Button onClick={() => toggleReadMore(index)}>
                          {expandedIndex === index ? "Read Less" : "Read More"}
                        </Button>

                        <Button
                          onClick={() =>
                            window.open(property.mapsLocation, "_blank")
                          }
                        >
                          View location
                        </Button>
                      </div>
                    </Box>
                  </div>
                ))
              )}
            </div>
          </Container>
        </Box>
      </SEO>
    </Page>
  );
};

export default NewDevelopment;
