export const allPueblos = [
  "Alcaucín",
  "Algarrobo",
  "Algarrobo Costa",
  "Almáchar",
  "Almuñecar",
  "Árchez",
  "Arenas",
  "Benagalbón",
  "Benajarafe",
  "Benamargosa",
  "Benamocarra",
  "Cajiz",
  "Canillas de Aceituno",
  "Canillas de Albaida",
  "Colmenar",
  "Comares",
  "Cómpeta",
  "Corumbela",
  "Cútar",
  "El Borge",
  "Frigiliana",
  "Iznate",
  "La Viñuela",
  "Los Romanes",
  "Macharaviaya",
  "Moclinejo",
  "Nerja",
  "Periana",
  "Rincón de la Victoria",
  "Riogordo",
  "Salares",
  "Sayalonga",
  "Torre del Mar",
  "Torrox",
  "Torrox Costa",
  "Vélez-Málaga"
];
