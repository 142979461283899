import { FC } from "react";
import useGetData from "../../hooks/useGetData";
import Loader from "../../Widgets/Feedback/Progress/Loader/Loader";
import { Blog } from "./types";
import { Language, isLanguage } from "../../Layout/Page";
import Container from "../../Layout/Container";
import Box from "../../Layout/Box";
import Text from "../../Widgets/Text/Text";
import Card from "../../Widgets/DisplayContainers/Card/Card";
import CardHeader from "../../Widgets/DisplayContainers/Card/CardHeader";
import { transformCase } from "../../utils/string";
import SEO from "../../Widgets/SEO/SEO";
import { useParams } from "react-router-dom";
import SingleBlog from "./SingleBlog";
import Logo from "../../Widgets/Logo";

export type AllBlogsProps = {};

const translations = {
  en: ["Your ", "tour guide", " in Spain"],
  es: ["Su", "guía turístico", " en España"],
  nl: ["Jouw ", "gids", " in Spanje"],
  de: ["Ihr ", "Reiseführer", " in Spanien"]
};

const seo = {
  title: {
    en: "Your tour guide in Spain",
    es: "Su guía turístico en España",
    nl: "Jouw gids in Spanje",
    de: "Ihr Reiseführer in Spanien"
  },
  description: {
    en: "FRESH Property Management is your tour guide in Spain. Read our blogs to learn more about the beautiful and exciting surroundings that Málaga has to offer.",
    es: "FRESH Property Management es su guía turístico en España. Lea nuestros blogs para aprender más sobre los hermosos y emocionantes alrededores que Málaga tiene para ofrecer.",
    nl: "FRESH Property Management is jouw gids in Spanje. Lees onze blogs om meer te weten te komen over de prachtige en spannende omgeving die Málaga te bieden heeft.",
    de: "FRESH Property Management ist Ihr Reiseführer in Spanien. Lesen Sie unsere Blogs, um mehr über die schöne und aufregende Umgebung zu erfahren, die Málaga zu bieten hat."
  }
};

const AllBlogs: FC<AllBlogsProps> = ({}) => {
  const { language: languageOrName, name } = useParams<{ language: string | undefined; name: string | undefined }>();
  let language: Language = "en";
  if (languageOrName && isLanguage(languageOrName)) {
    language = languageOrName;
  }

  let resolvedName: string | undefined;
  if (name) {
    if (isLanguage(name)) {
      language = name;
    } else {
      resolvedName = name;
    }
  }

  if (resolvedName) {
    return <SingleBlog name={resolvedName} language={language} />;
  }

  const [loading, blogs] = useGetData<Blog[]>("/blogs");

  let next = 0;
  const text = () => translations[language][next++] ?? "";

  return (
    <SEO title={seo.title} description={seo.description}>
      <Box
        className="overflow-x-hidden"
        backgroundColor="primary"
        text={{ color: "white" }}
        icon={{ fillColor: "white" }}
      >
        <Container padding={{ top: 14 }} extraTop>
          <div className="grid grid-cols-1 lg:grid-cols-2 space-x-4 items-center">
            <div>
              <Text type="h1">
                {text()}
                <span className="text-yellow">{text()}</span>
                {text()}
              </Text>
              <Text className="mb-4" type="h4">
                {text()}
              </Text>
            </div>
            <div className="lg:-mt-4 lg:flex flex-col items-center">
              <img
                className="hidden lg:block"
                width="350px"
                height="350px"
                src="/logo.svg"
                alt="FRESH Property Management Logo without text"
              />
            </div>
          </div>
        </Container>
        <svg
          className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
      </Box>
      <Box>
        <Container className="text-left space-y-4">
          {loading && <Loader type="overlay" />}
          {blogs &&
            Array.isArray(blogs) &&
            blogs
              ?.filter((blog) => blog.published)
              ?.map((blog) => (
                <Card
                  key={blog._id}
                  className="cursor-pointer hover:shadow border-2 border-grey-super-light rounded overflow-clip hover:border-primary active:border-secondary"
                  onClick={() =>
                    window.open(
                      `/blogs/${transformCase(blog.name?.[language] ?? "blog-not-found", "kebab")}/${language}`,
                      "_self"
                    )
                  }
                  header={<CardHeader>{blog.name?.[language]}</CardHeader>}
                >
                  <div className="p-2 lg:p-4 lg:gap-x-8 grid grid-cols-1 lg:grid-cols-3 items-center justify-center">
                    <Text className="lg:col-span-2">{blog.description?.[language]}</Text>
                    {blog.img ? (
                      <img
                        alt={blog.name?.["en"] ?? "FRESH Property Management"}
                        src={blog.img}
                        className="w-full h-40 object-cover"
                      />
                    ) : (
                      <Logo className="w-40 h-40 mx-auto" />
                    )}
                  </div>
                </Card>
              ))}
        </Container>
      </Box>
    </SEO>
  );
};

export default AllBlogs;
