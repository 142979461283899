import { FC, useContext } from "react";
import Text from "../../Widgets/Text/Text";
import Container from "../../Layout/Container";
import ImageComponent from "../../Layout/ImageComponent";
import Box from "../../Layout/Box";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../Layout/Page";

export type HolidayListingsProps = {};

const translations = {
  en: [
    `Making sure your property is visible`,
    `We get your property listed on all of the major booking websites, as well as list it on our own website.`,
    `Click `,
    `here`,
    ` to see how your property will be listed as a direct-booking location.`
  ],
  es: [
    `Asegurándose de que su propiedad sea visible`,
    `Hacemos que su propiedad aparezca en todos los principales sitios web de reservas, así como en nuestra propio sitio web.`,
    `Haga clic `,
    `aquí`,
    ` para ver cómo se enumerará su propiedad como un lugar de reserva directa.`
  ],
  nl: [
    `We zorgen dat uw woning zichtbaar is`,
    `We zorgen ervoor dat uw woning wordt vermeld op alle belangrijke boekingssites, evenals op onze eigen website.`,
    `Klik `,
    `hier`,
    ` om te zien hoe uw woning wordt vermeld als een directe boekingslocatie.`
  ],
  de: [
    `Wir stellen sicher, dass Ihre Immobilie sichtbar ist`,
    `Wir listen Ihre Immobilie auf allen wichtigen Buchungsseiten auf und listen sie auch auf unserer eigenen Website auf.`,
    `Klicken Sie `,
    `hier`,
    ` um zu sehen, wie Ihre Immobilie als Direktbuchungsort aufgeführt wird.`
  ]
};
const HolidayListings: FC<HolidayListingsProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  return (
    <Box>
      <Container>
        <Text type="h2">{text()}</Text>
        <Text type="h4">{text()}</Text>
        <img src="/logo_holiday.svg" alt="Fresh Holiday | Book your stay now!" className="mx-auto w-40 h-40 mb-8" />
        <ImageComponent className="w-full max-w-4xl mt-8 mx-auto" src="/booking_sites.png" />
        <Text>
          {text()}
          <Link className="text-primary underline" to="https://fresh.holiday/search">
            {text()}
          </Link>{" "}
          {text()}
        </Text>
      </Container>
    </Box>
  );
};

export default HolidayListings;
