import React, { ErrorInfo } from "react";
import Text from "./Text/Text";

export type ErrorBoundaryProps = React.PropsWithChildren<{}>;
class ErrorBoundary extends React.Component<ErrorBoundaryProps, { error?: Error | null }> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    const { error } = this.state;
    if (error) {
      // You can render any custom fallback UI
      return (
        <div className="flex flex-col space-y-4">
          <Text type="h2" className="text-red">
            {error.name}
          </Text>
          <Text>{error.message}</Text>
          <Text>{error.stack}</Text>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
