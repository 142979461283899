import { FC } from "react";
import { motion } from "framer-motion";
import { classNames } from "../../../../utils/string";
import Overlay from "../../../DisplayContainers/Overlay/Overlay";
import Logo from "../../../Logo";
import Text from "../../../Text/Text";
import { LoaderType } from "./types";

export type LoaderProps = {
  type?: LoaderType;
  text?: string;
  className?: string;
};

const Loader: FC<LoaderProps> = ({ type = "inline", text = "loading", className }) => {
  const renderedChildren = (
    <motion.div
      className={classNames(
        "flex flex-col items-center justify-center",
        "max-w-full max-h-full",
        type === "overlay" && "w-full h-full",
        className
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1.0, scaleY: 1, transition: { duration: 0.3 } }}
      exit={{ scaleY: 0, opacity: 0, transition: { duration: 0.3 } }}
    >
      <motion.div
        key="motion"
        className="w-44 h-44"
        initial={{ rotateY: "0deg" }}
        animate={{
          rotateY: ["0deg", "0deg", "360deg", "360deg", "0deg", "0deg"]
        }}
        transition={{ delay: 4, duration: 4, repeat: Infinity }}
      >
        <Logo className="w-44 h-44" />
      </motion.div>
      <Text type="h4" className="text-center whitespace-nowrap">
        -&nbsp;{text}&nbsp;-
      </Text>
    </motion.div>
  );

  switch (type) {
    case "abs":
      return (
        <div className={classNames("absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2", "text-primary")}>
          {renderedChildren}
        </div>
      );
    case "overlay":
      return <Overlay>{renderedChildren}</Overlay>;
    case "inline":
    default:
      return <div className={classNames("flex flex-col items-center text-primary", "w-24")}>{renderedChildren}</div>;
  }
};

export default Loader;
