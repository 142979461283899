import { FC, useRef } from "react";
import HomeCallToAction from "./HomeCallToAction";
import Page, { Language, isLanguage } from "../../Layout/Page";
import HomeHassleFree from "./HomeHassleFree";
import HomeReview from "./HomeReview";
import HomeBookYourStay from "./HomeBookYourStay";
import HomeBookCar from "./HomeBookCar";
import HomeMeetTheFounders from "./HomeMeetTheFounders";
import HomeFreeRentalAdvice from "./HomeFreeRentalAdvice";
import SEO from "../../Widgets/SEO/SEO";
import Text from "../../Widgets/Text/Text";
import { useParams } from "react-router-dom";

export type HomeProps = {};

const translations = {
  title: {
    en: "FRESH Property Management: your holiday rental & buying agent | Holiday Rental, Property Management and Property Investment at the Costa del Sol, la Axarquía, Málaga, Spain.",
    es: "FRESH Property Management: su agente de alquiler de vacaciones y compra | Alquiler de vacaciones, administración de propiedades e inversión en propiedades en la Costa del Sol, la Axarquía, Málaga, España.",
    nl: "FRESH Property Management: uw vakantieverhuur & aankoopmakelaar | Vakantieverhuur, vastgoedbeheer en vastgoedinvesteringen aan de Costa del Sol, la Axarquía, Málaga, Spanje.",
    de: "FRESH Property Management: Ihr Ferienvermietungs- und Einkaufsagent | Ferienvermietung, Immobilienverwaltung und Immobilieninvestitionen an der Costa del Sol, la Axarquía, Málaga, Spanien."
  },
  description: {
    en: "We are here to make your life easier. We offer a wide range of services to take work off your hands. Whether you want to rent out your property, invest in real estate or just want your property taken care of while you are away: look no further!",
    es: "Estamos aquí para facilitarle la vida. Ofrecemos una amplia gama de servicios para quitarle trabajo de las manos. Ya sea que quiera alquilar su propiedad, invertir en bienes raíces o simplemente quiera que se cuide su propiedad mientras está fuera: ¡no busque más!",
    nl: "Wij zijn er om uw leven gemakkelijker te maken. Wij bieden een breed scala aan diensten om u werk uit handen te nemen. Of je nu je woning wilt verhuren, wilt investeren in onroerend goed of gewoon wilt dat er voor je woning wordt gezorgd terwijl je weg bent: zoek niet verder!",
    de: "Wir sind hier, um Ihnen das Leben zu erleichtern. Wir bieten eine Vielzahl von Dienstleistungen an, um Ihnen die Arbeit abzunehmen. Egal, ob Sie Ihre Immobilie vermieten, in Immobilien investieren oder einfach nur möchten, dass sich jemand um Ihre Immobilie kümmert, während Sie weg sind: Suchen Sie nicht weiter!"
  }
};

const Home: FC<HomeProps> = ({}) => {
  const hassleFreeRef = useRef<HTMLDivElement>(null);
  const { language = "en" } = useParams<{ language: Language }>();
  console.log(language, isLanguage(language));
  if (!isLanguage(language)) {
    return (
      <Text type="h2" className="mt-20">
        Sorry, the page doesn't exist
      </Text>
    );
  }
  return (
    <Page>
      <SEO title={translations.title} description={translations.description}>
        <HomeCallToAction hassleFreeRef={hassleFreeRef} />
        <HomeHassleFree ref={hassleFreeRef} />
        <HomeBookYourStay />
        <HomeMeetTheFounders />
        <HomeFreeRentalAdvice />
        <HomeBookCar />
        <HomeReview />
      </SEO>
    </Page>
  );
};

export default Home;
