import { FC, useRef } from "react";
import Page from "../../Layout/Page";
import HolidayCallToAction from "./HolidayCallToAction";
import HolidayHassleFree from "./HolidayHassleFree";
import HolidayListings from "./HolidayListings";
import HolidayTransparentPricing from "./HolidayTransparentPricing";
import HomeFreeRentalAdvice from "../Home/HomeFreeRentalAdvice";
import HolidayReview from "./HolidayReview";
import SEO from "../../Widgets/SEO/SEO";

export type HolidayRentalManagementProps = {};

const seo = {
  title: {
    en: "Holiday Rental Management",
    es: "Gestión de alquileres de vacaciones",
    nl: "Vakantieverhuurbeheer",
    de: "Ferienvermietungsmanagement"
  },
  description: {
    en: "Manage your holiday rentals with ease with FRESH, the all-in-one holiday rental management agency. We take care of everything from finding and vetting guests to cleaning and maintaining your property. With FRESH, you can relax and enjoy more free time without the stress of managing your rentals.",
    es: "Administre sus alquileres de vacaciones con facilidad con FRESH, la agencia de administración de alquileres de vacaciones todo en uno. Nos encargamos de todo, desde encontrar y evaluar a los huéspedes hasta limpiar y mantener su propiedad. Con FRESH, puede relajarse y disfrutar de más tiempo libre sin el estrés de administrar sus alquileres.",
    nl: "Beheer uw vakantiewoningen eenvoudig met FRESH, het all-in-one vakantieverhuurbeheeragentschap. Wij regelen alles, van het vinden en screenen van gasten tot het schoonmaken en onderhouden van uw woning. Met FRESH kunt u ontspannen en genieten van meer vrije tijd zonder de stress van het beheren van uw verhuur.",
    de: "Verwalten Sie Ihre Ferienwohnungen mit Leichtigkeit mit FRESH, der All-in-One-Ferienwohnungsverwaltungsagentur. Wir kümmern uns um alles, von der Suche und Überprüfung von Gästen bis zur Reinigung und Instandhaltung Ihrer Immobilie. Mit FRESH können Sie sich entspannen und mehr Freizeit genießen, ohne sich um die Verwaltung Ihrer Mietobjekte kümmern zu müssen."
  }
};

const HolidayRentalManagement: FC<HolidayRentalManagementProps> = ({}) => {
  const rentalAdviceRef = useRef<HTMLDivElement>(null);
  return (
    <Page>
      <SEO title={seo.title} description={seo.description}>
        <HolidayCallToAction rentalAdviceRef={rentalAdviceRef} />
        <HolidayHassleFree />
        <HolidayListings />
        <HolidayTransparentPricing />
        <HomeFreeRentalAdvice ref={rentalAdviceRef} color="secondary" />
        <HolidayReview />
      </SEO>
    </Page>
  );
};

export default HolidayRentalManagement;
