import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import { LanguageContext } from "../../Layout/Page";
import Button from "../../Widgets/Button/Button";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import lightBulb from "@iconify-icons/ph/lightbulb";

export type BuyingAgentIntroProps = {};

const translations = {
  en: [
    "FRESH - Nice to meet you",
    "With FRESH, your house hunt will be a success.",
    "The beautiful coast of Nerja",
    "Owning a second home in beautiful Spain, what more could you want? Buying a house in Spain has many advantages. You can go there several times a year to relax and when you are not there you can rent out your house and earn money. We work together with well-known real estate agencies in the Axarquia. In our ",
    "offer",
    " you will find properties and new construction projects in the Axarquia from Nerja to Málaga and inland.",
    "FRESH is active throughout the Axarquia region and we are happy to guide you in your search for a second home in Spain. Trust FRESH as your Purchase Agent with knowledge of the housing and rental market.",
    "Please have a look at our portfolio",
    `Tip`,
    `Want to buy a house in Spain but not sure where to start? Fill out our online wishlist now and we'll contact you for a no-obligation introductory meeting. Step by step, we'll help you find your dream home.`,
    `To the online wishlist`
  ],
  es: [
    "FRESH - Encantado de conocerte",
    "Con FRESH, tu búsqueda de casa será un éxito",
    "La hermosa costa de Nerja",
    "¿Qué más se puede pedir que tener una segunda casa en la hermosa España? Comprar una casa en España tiene muchas ventajas. Puedes ir allí varias veces al año para relajarte y cuando no estés allí puedes alquilar tu casa y ganar dinero. Trabajamos junto con conocidas agencias inmobiliarias en la Axarquía. En nuestra ",
    "oferta",
    " encontrarás propiedades y nuevos proyectos de construcción en la Axarquía desde Nerja hasta Málaga y el interior.",
    "FRESH está activo en toda la región de la Axarquía y estaremos encantados de guiarte en tu búsqueda de una segunda vivienda en España. Confía en FRESH como tu agente de compra con conocimiento del mercado inmobiliario y de alquiler.",
    "Eche un vistazo a nuestro portafolio",
    `Consejo`,
    `¿Quiere comprar una casa en España pero no sabe muy bien por dónde empezar? Rellene ahora nuestro formulario de deseos online y nos pondremos en contacto con usted para una reunión informativa sin compromiso. Paso a paso, le ayudaremos a encontrar la casa de sus sueños.`,
    `Al formulario de deseos online`
  ],
  nl: [
    "FRESH - Leuk je te ontmoeten",
    "Met FRESH wordt uw huizenjacht een succes.",
    "De prachtige kust van Nerja",
    "Een tweede huis bezitten in het prachtige Spanje, wat wil je nog meer? Het kopen van een huis in Spanje heeft vele voordelen. Je kunt er meerdere keren per jaar heen om te ontspannen en als je er niet bent kun je je huis verhuren en geld verdienen. Wij werken samen met bekende makelaars in de Axarquia. In ons ",
    "aanbod",
    " vind je woningen en nieuwbouwprojecten in de Axarquia van Nerja tot Málaga en het binnenland.",
    "FRESH is actief in de hele regio Axarquia en wij begeleiden je graag bij je zoektocht naar een tweede huis in Spanje. Vertrouw op FRESH als jouw Aankoopmakelaar met kennis van de woning- en verhuurmarkt.",
    "Neem een kijkje in ons portfolio",
    `Tip`,
    `Je wilt een huis kopen in Spanje maar je weet nog niet zo goed waar en hoe te beginnen? Vul dan nu onze online wensenlijst in, dan nemen wij contact met je op voor een geheel vrijblijvend kennismakingsgesprek. Stap voor stap helpen wij je jouw droomhuis te vinden.`,
    `Naar de online wensenlijst`
  ],
  de: [
    "FRESH - Schön, Sie kennenzulernen",
    "Mit FRESH wird Ihre Haussuche ein Erfolg.",
    "Die wunderschöne Küste von Nerja",
    "Ein Zweitwohnsitz im schönen Spanien - was will man mehr? Ein Haus in Spanien zu kaufen hat viele Vorteile. Sie können mehrmals im Jahr dorthin fahren, um sich zu entspannen, und wenn Sie nicht dort sind, können Sie Ihr Haus vermieten und Geld verdienen. Wir arbeiten mit bekannten Immobilienmaklern in der Axarquia zusammen. In unserem ",
    "Angebot",
    " finden Sie Immobilien und Neubauprojekte in der Axarquia von Nerja bis Málaga und ins Landesinnere.",
    "FRESH ist in der gesamten Region Axarquia aktiv und wir begleiten Sie gerne bei der Suche nach einem Zweitwohnsitz in Spanien. Vertrauen Sie FRESH als Ihren Kaufagenten mit Kenntnissen des Wohnungs- und Mietmarktes.",
    "Bitte schauen Sie sich unser Portfolio",
    `Tipp`,
    `Sie möchten ein Haus in Spanien kaufen, wissen aber noch nicht so genau, wo und wie Sie anfangen sollen? Füllen Sie jetzt unsere Online-Wunschliste aus, dann nehmen wir Kontakt mit Ihnen auf für ein unverbindliches Kennenlerngespräch. Schritt für Schritt helfen wir Ihnen, Ihr Traumhaus zu finden.`,
    `Zur Online-Wunschliste`
  ]
};

const BuyingAgentIntro: FC<BuyingAgentIntroProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  const wishlistUrl = `https://docs.google.com/forms/d/e/1FAIpQLSddOT-IOUtzDMefwHJjNphk-WkLQI4J7-sL_B4_pmOIueuGUg/viewform`;

  const navigate = useNavigate();
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  const forSaleUrl = `/for-sale${language === "en" ? "" : `/${language}`}`;
  return (
    <Box>
      <Container>
        <Text type="h2">{text()}</Text>
        <Text type="h4">{text()}</Text>
        <img
          className="mx-auto w-full mb-4 object-cover lg:max-h-[512px]"
          srcSet="/visuals/axarquia-320.webp 320w, /visuals/axarquia-640.webp 640w, /visuals/axarquia-960.webp 960w, /visuals/axarquia-1200.webp 1200w, /visuals/axarquia-1600.webp 1600w"
          src="/visuals/axarquia-1600.webp"
          alt="Buy a home in the Axarquia"
        />
        <Text>
          <small>
            <em>{text()}</em>
          </small>
        </Text>
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 space-x-4">
          <div className="w-full lg:col-span-3">
            <Text>
              {text()}
              <a href={forSaleUrl} target="_self" className="text-primary underline">
                {text()}
              </a>
              {text()}
            </Text>
            <Text>{text()}</Text>
            <Button type="primary" className="my-4" onClick={() => navigate(forSaleUrl)}>
              {text()}
            </Button>
          </div>
          <section className="px-8 py-4 lg:col-span-2 flex flex-col items-center border gap-2 border-primary rounded">
            <div className="w-full flex flex-row items-center justify-between self-start">
              <div className="p-1 rounded-full bg-yellow">
                <Icon icon={lightBulb} className="w-10 h-10 text-white" />
              </div>
              <Text type="h3" className="text-center flex-1">
                {text()}
              </Text>
            </div>
            <Text>{text()}</Text>
            <Button className="mt-2" type="primary" onClick={() => window.open(wishlistUrl, "_blank", "noreferrer")}>
              {text()}
            </Button>
          </section>
        </div>
      </Container>
      <svg
        className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-primary"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
      >
        <path
          d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
        ></path>
      </svg>
    </Box>
  );
};
/*
;*/
export default BuyingAgentIntro;
