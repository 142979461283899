import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import { LanguageContext } from "../../Layout/Page";
import Text from "../../Widgets/Text/Text";
import TeamCard, { TeamCardProps } from "./TeamCard";
import { Icon } from "@iconify/react";
import checkMark from "@iconify-icons/ph/check-bold";
import WhatsAppLink from "../../WhatsAppLink";
import SEO from "../../Widgets/SEO/SEO";

export type MeetTheTeamProps = {};
const founders: TeamCardProps[] = [
  { name: "Mike van Houtum", role: "Marketing", isFounder: true },
  { name: "Maayke Cornelissen", role: "Multimedia & content", isFounder: true }
];

const facility: TeamCardProps[] = [
  { name: "Vanesa Morales", role: "Cleaning" },
  { name: "Marnie Thomas", role: "Cleaning" },
  { name: "Lucia Gamez", role: "Cleaning" }
];

const support: TeamCardProps[] = [
  { name: "Tom van Griensven", role: "Allround maintenance" },
  { name: "Alan Carscadden", role: "Professional photography & maintenance" },
  { name: "Enrique", role: "Mural & wood artist" },
  { name: "José Luis", role: "Electrician" },
  { name: "Rúben Segarra", role: "Electrician" }
];

const translations = {
  en: [
    "Meet ",
    "your",
    " team",
    "Property management requires a great team.",
    "Welcome to FRESH Property Management",
    "Your trusted team of experts in holiday rental management",
    "We are a team of passionate and experienced professionals who are dedicated to making your vacation rental experience as smooth and stress-free as possible. We understand that you want to maximize your rental income and provide your guests with an unforgettable experience, and we are here to help you achieve both goals.",
    "Our team of experts has a deep understanding of the holiday rental market, and we are constantly staying up-to-date on the latest trends and regulations. We offer a wide range of services, including:",
    "Property marketing and listing: We will create professional listings for your property on all major rental platforms, and we will use our expertise to target the right audience for your property.",
    "Guest screening and booking: We will carefully screen all potential guests and handle all booking inquiries on your behalf.",
    "Key handover and check-in/check-out: We will meet your guests at the property to hand over the keys and ensure that they have everything they need for a comfortable stay.",
    "Cleaning and maintenance: We will coordinate professional cleaning and maintenance services for your property to ensure that it is always in top condition.",
    "24/7 guest support: We are available 24/7 to provide assistance to your guests in case of any problems.",
    "We are committed to providing our clients with the highest level of service and support. If you are looking for a team of experts to manage your holiday rental property, then FRESH Property Management is the right choice for you.",
    "Request for more information",
    "Hi team FRESH, I would like to request more information about your services.\n\nKind regards,\n\n",
    "Contact us",
    " today and experience the FRESH difference.",
    "Founders",
    "With a combined experience in the holiday rental and investment advisory industry, our founders are experts in their field. They are driven by a shared passion for providing their clients with the highest level of service and support.",
    "Maayke and Mike are committed to building a company that is built on trust, integrity, and a commitment to excellence. They believe that the key to success is to provide their clients with a personalized experience and to go the extra mile to exceed expectations.",
    "Cleaning",
    "At FRESH Property Management, we understand that the condition of your vacation home is critical to your success. That is why we have a dedicated team of experts responsible for ensuring that your property is always in top condition, both inside and out.",
    "We understand that your vacation home is a valuable investment and we are committed to protecting and preserving it. Our clean team is here to give you peace of mind knowing that your property is in good hands.",
    "Support",
    "At FRESH Property Management, we know that a well-maintained vacation rental property is essential to a successful business. That's why we have a dedicated team of upkeep professionals who are committed to keeping your property in top condition, both inside and out.",
    "Our upkeep team is highly skilled and experienced in handling a wide range of repairs, from minor plumbing issues to major appliance replacements. They are also responsive and reliable, and they will work quickly and efficiently to get your property back in tip-top shape.",
    "Choose FRESH Property Management",
    "Minimize your worries; Maximize your income",
    "Whether you're a seasoned property investor or just getting started, FRESH Property Management is here to help you make the most of your vacation rental.",
    "Why you should choose FRESH Property Management:",
    "Expertise: Our team of experts has a deep understanding of the holiday rental market and is constantly staying up-to-date on the latest trends and regulations.",
    "Personalization: We take the time to understand your individual needs and goals, and we work with you to develop a customized management plan that meets your specific requirements.",
    "Dedication: We are dedicated to helping you maximize your rental income and provide your guests with the best possible experience.",
    "Let's get started!",
    "Hi team FRESH",
    "I would like to start with FRESH Property Management.\n\nKind regards,\n\n",
    "Ready to get started?"
  ],
  es: [
    "Conoce ",
    "a tu",
    " equipo",
    "La gestión de propiedades requiere un gran equipo.",
    "Bienvenido a FRESH Property Management",
    "Su equipo de confianza de expertos en gestión de alquileres vacacionales",
    "Somos un equipo de profesionales apasionados y experimentados que se dedican a hacer que su experiencia de alquiler de vacaciones sea lo más fluida y sin estrés posible. Entendemos que desea maximizar sus ingresos por alquiler y brindar a sus huéspedes una experiencia inolvidable, y estamos aquí para ayudarlo a lograr ambos objetivos.",
    "Nuestro equipo de expertos tiene un profundo conocimiento del mercado de alquileres vacacionales y se mantiene constantemente actualizado sobre las últimas tendencias y regulaciones. Ofrecemos una amplia gama de servicios, que incluyen:",
    "Marketing y listado de propiedades: Crearemos listados profesionales para su propiedad en todas las plataformas de alquiler principales y utilizaremos nuestra experiencia para dirigirnos al público adecuado para su propiedad.",
    "Selección y reserva de huéspedes: Examinaremos cuidadosamente a todos los posibles huéspedes y nos encargaremos de todas las consultas de reserva en su nombre.",
    "Entrega de llaves y check-in / check-out: Nos reuniremos con sus huéspedes en la propiedad para entregar las llaves y asegurarnos de que tengan todo lo que necesitan para una estancia cómoda.",
    "Limpieza y mantenimiento: Coordinaremos servicios profesionales de limpieza y mantenimiento para su propiedad para garantizar que siempre esté en perfectas condiciones.",
    "Soporte al cliente 24/7: Estamos disponibles las 24 horas, los 7 días de la semana para brindar asistencia a sus huéspedes en caso de cualquier problema.",
    "Estamos comprometidos a brindar a nuestros clientes el más alto nivel de servicio y soporte. Si está buscando un equipo de expertos para administrar su propiedad de alquiler de vacaciones, entonces FRESH Property Management es la opción correcta para usted.",
    "Más información",
    "Hola equipo FRESH, me gustaría solicitar más información sobre sus servicios.\n\nSaludos cordiales,\n\n",
    "Contáctenos",
    " hoy y experimente la diferencia FRESH.",
    "Fundadores",
    "Con una experiencia combinada en la industria de alquileres vacacionales y asesoramiento de inversiones, nuestros fundadores son expertos en su campo. Los impulsa una pasión compartida por brindar a sus clientes el más alto nivel de servicio y soporte.",
    "Maayke y Mike están comprometidos a construir una empresa basada en la confianza, la integridad y el compromiso con la excelencia. Creen que la clave del éxito es brindar a sus clientes una experiencia personalizada y esforzarse al máximo para superar las expectativas.",
    "Limpieza",
    "En FRESH Property Management, entendemos que el estado de su casa de vacaciones es esencial para su éxito. Por eso contamos con un equipo de expertos que se encarga de garantizar que su propiedad esté siempre en las mejores condiciones, tanto por dentro como por fuera.",
    "Entendemos que su casa de vacaciones es una inversión valiosa y nos comprometemos a protegerla y conservarla. Nuestro equipo de limpieza está aquí para darle la tranquilidad de saber que su propiedad está en buenas manos.",
    "Apoyo",
    "En FRESH Property Management, sabemos que una propiedad de alquiler de vacaciones bien mantenida es esencial para un negocio exitoso. Es por eso que tenemos un equipo dedicado de profesionales de mantenimiento que se comprometen a mantener su propiedad en perfectas condiciones, tanto por dentro como por fuera.",
    "Nuestro equipo de mantenimiento es altamente calificado y experimentado en el manejo de una amplia gama de reparaciones, desde problemas menores de plomería hasta reemplazos importantes de electrodomésticos. También son receptivos y confiables, y trabajarán de manera rápida y eficiente para que su propiedad vuelva a estar en perfectas condiciones.",
    "Elige FRESH Property Management",
    "Minimiza tus preocupaciones; Maximiza tus ingresos",
    "Ya sea que sea un inversor inmobiliario experimentado o recién esté comenzando, FRESH Property Management está aquí para ayudarlo a aprovechar al máximo su alquiler de vacaciones.",
    "Por qué debería elegir FRESH Property Management:",
    "Experiencia: Nuestro equipo de expertos tiene un profundo conocimiento del mercado de alquileres vacacionales y se mantiene constantemente actualizado sobre las últimas tendencias y regulaciones.",
    "Personalización: Nos tomamos el tiempo para comprender sus necesidades y objetivos individuales, y trabajamos con usted para desarrollar un plan de administración personalizado que satisfaga sus requisitos específicos.",
    "Dedicación: Estamos dedicados a ayudarlo a maximizar sus ingresos por alquiler y brindar a sus huéspedes la mejor experiencia posible.",
    "¡Adelante, empezamos!",
    "Hola equipo FRESH,\n\nMe gustaría comenzar con FRESH Property Management.\n\nSaludos cordiales,\n\n",
    "¿Listo para empezar?"
  ],
  nl: [
    "Ontmoet ",
    "jouw",
    " team",
    "Vastgoedbeheer vereist een geweldig team.",
    "Welkom bij FRESH Property Management",
    "Jouw vertrouwde team van experts in vakantieverhuurbeheer",
    "Wij zijn een team van gepassioneerde en ervaren professionals die zich inzetten om je vakantieverhuurervaring zo soepel en stressvrij mogelijk te maken. We begrijpen dat je je huurinkomsten wilt maximaliseren en je gasten een onvergetelijke ervaring wilt bieden, en we zijn hier om je te helpen beide doelen te bereiken.",
    "Ons team van experts heeft een diepgaand inzicht in de vakantieverhuurmarkt en we blijven voortdurend op de hoogte van de nieuwste trends en regelgeving. We bieden een breed scala aan diensten, waaronder:",
    "Property marketing en listing: We maken professionele vermeldingen voor je woning op alle belangrijke verhuurplatforms en gebruiken onze expertise om het juiste publiek voor je woning te targeten.",
    "Screening en boeking van gasten: We screenen alle potentiële gasten zorgvuldig en behandelen alle boekingsaanvragen namens jou.",
    "Sleuteloverdracht en inchecken / uitchecken: We ontmoeten je gasten op het terrein om de sleutels te overhandigen en ervoor te zorgen dat ze alles hebben wat ze nodig hebben voor een comfortabel verblijf.",
    "Schoonmaak en onderhoud: We coördineren professionele schoonmaak- en onderhoudsdiensten voor je woning om ervoor te zorgen dat deze altijd in topconditie is.",
    "24/7 klantenondersteuning: We zijn 24/7 beschikbaar om je gasten te helpen bij eventuele problemen.",
    "We zijn toegewijd om onze klanten het hoogste niveau van service en ondersteuning te bieden. Als je op zoek bent naar een team van experts om je vakantiewoning te beheren, dan is FRESH Property Management de juiste keuze.",
    "Meer informatie",
    "Hallo team FRESH, ik wil graag meer informatie aanvragen over jullie diensten.\n\nMet vriendelijke groet,\n\n",
    "Neem contact met ons op",
    " en ervaar het verschil met FRESH.",
    "Oprichters",
    "Met een gecombineerde ervaring in de vakantieverhuur- en beleggingsadviesbranche zijn onze oprichters experts in hun vakgebied. Ze worden gedreven door een gedeelde passie om hun klanten het hoogste niveau van service en ondersteuning te bieden.",
    "Maayke en Mike zijn toegewijd om een bedrijf op te bouwen dat is gebouwd op vertrouwen, integriteit en een toewijding aan uitmuntendheid. Ze geloven dat de sleutel tot succes is om hun klanten een persoonlijke ervaring te bieden en een stapje extra te doen om de verwachtingen te overtreffen.",
    "Schoonmaak",
    "Bij FRESH Property Management begrijpen we dat de staat van je vakantiewoning essentieel is voor je succes. Daarom hebben we een toegewijd team van experts die verantwoordelijk zijn voor het garanderen dat je woning altijd in topconditie is, zowel van binnen als van buiten.",
    "We begrijpen dat je vakantiewoning een waardevolle investering is en we zijn toegewijd om deze te beschermen en te behouden. Ons clean-team is er om jou gemoedsrust te geven, wetende dat je eigendom in goede handen is.",
    "Ondersteuning",
    "Bij FRESH Property Management weten we dat een goed onderhouden vakantiewoning essentieel is voor een succesvol bedrijf. Daarom hebben we een toegewijd team van onderhoudsprofessionals die zich inzetten om je woning in topconditie te houden, zowel van binnen als van buiten.",
    "Ons onderhoudsteam is zeer bekwaam en ervaren in het omgaan met een breed scala aan reparaties, van kleine loodgietersproblemen tot grote vervangingen van apparaten. Ze zijn ook responsief en betrouwbaar en werken snel en efficiënt om je woning weer in topconditie te krijgen.",
    "Kies FRESH Property Management",
    "Minimaliseer je zorgen; Maximaliseer je inkomen",
    "Of je nu een doorgewinterde vastgoedbelegger bent of net begint, FRESH Property Management staat voor je klaar om het meeste uit je vakantiewoning te halen.",
    "Waarom je FRESH Property Management zou moeten kiezen:",
    "Expertise: ons team van experts heeft een diepgaand inzicht in de vakantieverhuurmarkt en blijft voortdurend op de hoogte van de nieuwste trends en regelgeving.",
    "Personalisatie: we nemen de tijd om je individuele behoeften en doelen te begrijpen en werken met je samen om een op maat gemaakt beheerplan te ontwikkelen dat aan je specifieke eisen voldoet.",
    "Toewijding: we zijn toegewijd om je te helpen je huurinkomsten te maximaliseren en je gasten de best mogelijke ervaring te bieden.",
    "Laten we beginnen!",
    "Hallo team FRESH,\n\nIk wil graag samenwerken met FRESH Property Management.\n\nMet vriendelijke groet,\n\n",
    "Klaar om te beginnen?"
  ],
  de: [
    "Treffen ",
    "Sie Ihr",
    " Team",
    "Immobilienmanagement erfordert ein großartiges Team.",
    "Willkommen bei FRESH Property Management",
    "Ihr vertrauenswürdiges Team von Experten für die Verwaltung von Ferienwohnungen",
    "Wir sind ein Team von leidenschaftlichen und erfahrenen Fachleuten, die sich dafür einsetzen, dass Ihr Erlebnis mit Ferienwohnungen so reibungslos und stressfrei wie möglich verläuft. Wir verstehen, dass Sie Ihre Mieteinnahmen maximieren und Ihren Gästen ein unvergessliches Erlebnis bieten möchten, und wir sind hier, um Ihnen bei der Erreichung beider Ziele zu helfen.",
    "Unser Team von Experten hat ein tiefes Verständnis für den Ferienwohnungsmarkt und wir sind ständig auf dem neuesten Stand der neuesten Trends und Vorschriften. Wir bieten eine breite Palette von Dienstleistungen an, darunter:",
    "Immobilienmarketing und Auflistung: Wir erstellen professionelle Auflistungen für Ihre Immobilie auf allen wichtigen Mietplattformen und nutzen unser Fachwissen, um die richtige Zielgruppe für Ihre Immobilie anzusprechen.",
    "Gastscreening und Buchung: Wir prüfen alle potenziellen Gäste sorgfältig und bearbeiten alle Buchungsanfragen in Ihrem Namen.",
    "Schlüsselübergabe und Check-in / Check-out: Wir treffen uns mit Ihren Gästen auf dem Grundstück, um die Schlüssel zu übergeben und sicherzustellen, dass sie alles haben, was sie für einen angenehmen Aufenthalt benötigen.",
    "Reinigung und Wartung: Wir koordinieren professionelle Reinigungs- und Wartungsdienste für Ihre Immobilie, um sicherzustellen, dass sie sich immer in einem Top-Zustand befindet.",
    "24/7 Kundensupport: Wir sind rund um die Uhr verfügbar, um Ihren Gästen bei Problemen zu helfen.",
    "Wir sind bestrebt, unseren Kunden den bestmöglichen Service und Support zu bieten. Wenn Sie nach einem Team von Experten suchen, das Ihre Ferienimmobilie verwaltet, ist FRESH Property Management die richtige Wahl für Sie.",
    "Weitere Informationen",
    "Hallo Team FRESH, ich möchte weitere Informationen zu Ihren Dienstleistungen anfordern.\n\nFreundliche Grüße,\n\n",
    "Kontaktieren Sie uns",
    " heute und erleben Sie den FRESH-Unterschied.",
    "Gründer",
    "Mit einer kombinierten Erfahrung in der Ferienwohnungs- und Anlageberatungsbranche sind unsere Gründer Experten auf ihrem Gebiet. Sie werden von einer gemeinsamen Leidenschaft angetrieben, ihren Kunden den bestmöglichen Service und Support zu bieten.",
    "Maayke und Mike sind bestrebt, ein Unternehmen aufzubauen, das auf Vertrauen, Integrität und Engagement für Exzellenz aufbaut. Sie sind der Meinung, dass der Schlüssel zum Erfolg darin besteht, ihren Kunden ein personalisiertes Erlebnis zu bieten und die Erwartungen zu übertreffen.",
    "Reinigung",
    "Wir von FRESH Property Management wissen, dass der Zustand Ihrer Ferienimmobilie entscheidend für Ihren Erfolg ist. Deshalb haben wir ein engagiertes Expertenteam, das dafür sorgt, dass Ihre Immobilie innen und außen immer in einem Top-Zustand ist.",
    "Wir wissen, dass Ihr Ferienhaus eine wertvolle Investition ist, und wir sind bestrebt, es zu schützen und zu erhalten. Unser Reinigungs Team soll Ihnen die Gewissheit geben, dass Ihr Eigentum in guten Händen ist.",
    "Unterstützung",
    "Bei FRESH Property Management wissen wir, dass eine gut gepflegte Ferienimmobilie für ein erfolgreiches Geschäft unerlässlich ist. Deshalb haben wir ein engagiertes Team von Instandhaltungsprofis, die sich dafür einsetzen, Ihre Immobilie sowohl innen als auch außen in Top-Zustand zu halten.",
    "Unser Instandhaltungsteam ist hochqualifiziert und erfahren im Umgang mit einer Vielzahl von Reparaturen, von kleinen Klempnerarbeiten bis hin zu großen Geräteersatzteilen. Sie sind auch reaktionsschnell und zuverlässig und arbeiten schnell und effizient, um Ihre Immobilie wieder in Top-Zustand zu bringen.",
    "Wählen Sie FRESH Property Management",
    "Minimieren Sie Ihre Sorgen; Maximieren Sie Ihr Einkommen",
    "Egal, ob Sie ein erfahrener Immobilieninvestor sind oder gerade erst anfangen, FRESH Property Management steht Ihnen zur Seite, um das Beste aus Ihrer Ferienimmobilie herauszuholen.",
    "Warum Sie FRESH Property Management wählen sollten:",
    "Expertise: Unser Team von Experten hat ein tiefes Verständnis für den Ferienwohnungsmarkt und ist ständig auf dem neuesten Stand der neuesten Trends und Vorschriften.",
    "Personalisierung: Wir nehmen uns die Zeit, um Ihre individuellen Bedürfnisse und Ziele zu verstehen, und arbeiten mit Ihnen zusammen, um einen maßgeschneiderten Managementplan zu entwickeln, der Ihren spezifischen Anforderungen entspricht.",
    "Hingabe: Wir sind bestrebt, Ihnen zu helfen, Ihre Mieteinnahmen zu maximieren und Ihren Gästen die bestmögliche Erfahrung zu bieten.",
    "Los geht's!",
    "Hallo Team FRESH,\n\nIch möchte gerne mit FRESH Property Management anfangen.\n\nFreundliche Grüße,\n\n",
    "Bereit zu beginnen?"
  ]
};

const seo = {
  title: {
    en: "Your trusted team of experts in holiday rental management",
    es: "Su equipo de confianza de expertos en gestión de alquileres vacacionales",
    nl: "Uw vertrouwde team van experts in vakantieverhuurbeheer",
    de: "Ihr vertrauenswürdiges Team von Experten für die Verwaltung von Ferienwohnungen"
  },
  description: {
    en: "Our team of experts has a deep understanding of the holiday rental market, and we are constantly staying up-to-date on the latest trends and regulations. We offer a wide range of services that help to maximize your rental income and provide your guests with an unforgettable experience.",
    es: "Nuestro equipo de expertos tiene un profundo conocimiento del mercado de alquileres vacacionales y se mantiene constantemente actualizado sobre las últimas tendencias y regulaciones. Ofrecemos una amplia gama de servicios que ayudan a maximizar sus ingresos por alquiler y brindan a sus huéspedes una experiencia inolvidable.",
    nl: "Ons team van experts heeft een diepgaand inzicht in de vakantieverhuurmarkt en we blijven voortdurend op de hoogte van de nieuwste trends en regelgeving. We bieden een breed scala aan diensten die helpen om uw huurinkomsten te maximaliseren en uw gasten een onvergetelijke ervaring te bieden.",
    de: "Unser Team von Experten hat ein tiefes Verständnis für den Ferienwohnungsmarkt und wir sind ständig auf dem neuesten Stand der neuesten Trends und Vorschriften. Wir bieten eine breite Palette von Dienstleistungen an, die dazu beitragen, Ihre Mieteinnahmen zu maximieren und Ihren Gästen ein unvergessliches Erlebnis zu bieten."
  }
};

const MeetTheTeam: FC<MeetTheTeamProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  return (
    <SEO title={seo.title} description={seo.description}>
      <Box backgroundColor="primary" className="overflow-x-hidden">
        <Container className="text-white">
          <Text type="h1">
            {text()}
            <span className="text-yellow">{text()}</span>
            {text()}
          </Text>
          <Text>{text()}</Text>
        </Container>
        <svg
          className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
      </Box>

      <Box backgroundColor="white">
        <Container>
          <Text type="h2">{text()}</Text>
          <Text type="h4">{text()}</Text>
          <Text>{text()}</Text>
          <Text>{text()}</Text>
          <div className="p-4 lg:max-w-[800px] mx-auto">
            <div className="flex flex-row items-start justify-start text-left w-full">
              <Icon icon={checkMark} className="text-primary mt-4 w-12 h-6 mr-2" />
              <Text className="w-max flex-1">{text()}</Text>
            </div>
            <div className="flex flex-row items-start justify-start text-left w-full">
              <Icon icon={checkMark} className="text-primary mt-4 w-12 h-6 mr-2" />
              <Text className="w-max flex-1">{text()}</Text>
            </div>
            <div className="flex flex-row items-start justify-start text-left w-full">
              <Icon icon={checkMark} className="text-primary mt-4 w-12 h-6 mr-2" />
              <Text className="w-max flex-1">{text()}</Text>
            </div>
            <div className="flex flex-row items-start justify-start text-left w-full">
              <Icon icon={checkMark} className="text-primary mt-4 w-12 h-6 mr-2" />
              <Text className="w-max flex-1">{text()}</Text>
            </div>
            <div className="flex flex-row items-start justify-start text-left w-full">
              <Icon icon={checkMark} className="text-primary mt-4 w-12 h-6 mr-2" />
              <Text className="w-max flex-1">{text()}</Text>
            </div>
          </div>
          <Text>{text()}</Text>
          <Text>
            <WhatsAppLink subject={text()} message={text()}>
              {text()}
            </WhatsAppLink>
            {text()}
          </Text>
        </Container>
        <svg
          className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-primary"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
      </Box>
      <Box backgroundColor="primary">
        <Container>
          <Text type="h1" className="mt-14 text-white">
            {text()}
          </Text>
          <Text className="text-white">{text()}</Text>

          <div className="flex flex-col md:flex-row justify-center items-center mt-4">
            {founders.map((member, i) => (
              <TeamCard key={`founder-${i}`} {...member} />
            ))}
          </div>
          <Text className="text-white">{text()}</Text>
        </Container>
        <svg
          className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
      </Box>
      <Box backgroundColor="white">
        <Container>
          <Text type="h1">{text()}</Text>
          <Text>{text()}</Text>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-4">
            {facility.map((member, i) => (
              <TeamCard key={`team-member-${i}`} {...member} />
            ))}
          </div>
          <Text>{text()}</Text>
        </Container>
        <svg
          className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-primary"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
      </Box>
      <Box backgroundColor="primary">
        <Container>
          <Text type="h1" className="text-white">
            {text()}
          </Text>
          <Text className="text-white">{text()}</Text>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 mt-4">
            {support.map((member, i) => (
              <TeamCard key={`team-member-${i}`} {...member} />
            ))}
          </div>
          <Text className="text-white">{text()}</Text>
        </Container>
        <svg
          className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
      </Box>
      <Box>
        <Container>
          <Text type="h2">{text()}</Text>
          <Text type="h4">{text()}</Text>
          <Text className="text-left">{text()}</Text>
          <Text className="text-left">{text()}</Text>
          <div className="p-4 lg:max-w-[800px] mx-auto">
            <div className="flex flex-row items-start justify-start text-left w-full">
              <Icon icon={checkMark} className="text-primary mt-4 w-12 h-6 mr-2" />
              <Text className="w-max flex-1">{text()}</Text>
            </div>
            <div className="flex flex-row items-start justify-start text-left w-full">
              <Icon icon={checkMark} className="text-primary mt-4 w-12 h-6 mr-2" />
              <Text className="w-max flex-1">{text()}</Text>
            </div>
            <div className="flex flex-row items-start justify-start text-left w-full">
              <Icon icon={checkMark} className="text-primary mt-4 w-12 h-6 mr-2" />
              <Text className="w-max flex-1">{text()}</Text>
            </div>
          </div>
          <Text>
            <WhatsAppLink subject={text()} message={text()}>
              {text()}
              {text()}
            </WhatsAppLink>
          </Text>
        </Container>
      </Box>
    </SEO>
  );
};

export default MeetTheTeam;
