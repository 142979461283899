import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import { Icon } from "@iconify/react";
import checkMark from "@iconify-icons/ph/check-bold";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../Layout/Page";
import WhatsAppLink from "../../WhatsAppLink";

export type InvestmentElaborationProps = {};

const translations = {
  en: [
    "Property Investment",
    "Our team works closely with local real estate agents, where we hand pick only the properties that tick the right boxes. All our selected properties have:",
    "good location",
    "easy access",
    "turnkey or easy to fix",
    "the right paperwork in place",
    "good amenities (pool)",
    "easy maintenance",
    "a good return on investment",
    "Fancy one or several properties? ",
    "I have seen a property I like!",
    "Hi team FRESH, I have seen a property I like!\nCan you send me more information about it?\n\nIt has the following ref: \n",
    "Contact",
    " us and we'll send you the real estate agents' contact information. Once a property has been bought, we help you through the entire process of getting the property on the rental market without you having to be present. A full service solution is what we stand for.",
    "You can find some of our investment opportunities ",
    "here"
  ],
  es: [
    "Inversión inmobiliaria",
    "Nuestro equipo trabaja en estrecha colaboración con agentes inmobiliarios locales, donde seleccionamos a mano solo las propiedades que cumplen con los requisitos adecuados. Todas nuestras propiedades seleccionadas tienen:",
    "buena ubicación",
    "fácil acceso",
    "llave en mano o fácil de arreglar",
    "la documentación correcta en su lugar",
    "buenas comodidades (piscina)",
    "mantenimiento fácil",
    "un buen retorno de la inversión",
    "¿Te gusta una o varias propiedades? ",
    "He visto una propiedad que me gusta",
    "Hola equipo FRESH, he visto una propiedad que me gusta.\n¿Puedes enviarme más información al respecto?\n\nTiene la siguiente referencia: \n",
    "Contáctenos",
    " y le enviaremos la información de contacto de los agentes inmobiliarios. Una vez que se haya comprado una propiedad, lo ayudamos a través de todo el proceso de poner la propiedad en el mercado de alquiler sin que usted tenga que estar presente. Una solución de servicio completo es lo que representamos.",
    "Puede encontrar algunas de nuestras oportunidades de inversión ",
    "aquí"
  ],
  nl: [
    "Investering in vastgoed",
    "Ons team werkt nauw samen met lokale makelaars, waar we alleen de woningen selecteren die aan de juiste voorwaarden voldoen. Al onze geselecteerde woningen hebben:",
    "goede locatie",
    "makkelijke toegang",
    "sleutelklaar of gemakkelijk te repareren",
    "de juiste papieren op orde",
    "goede voorzieningen (zwembad)",
    "gemakkelijk onderhoud",
    "een goed rendement op investeringen",
    "Droomhuis gevonden? ",
    "Ik heb een woning gezien die ik leuk vind!",
    "Hallo team FRESH, ik heb een woning gezien die ik leuk vind!\nKun je me er meer informatie over sturen?\n\nHet heeft de volgende referentie: \n",
    "Neem contact",
    " met ons op en wij sturen u de contactgegevens van de makelaars. Zodra een woning is gekocht, helpen wij u bij het hele proces om de woning op de huurmarkt te krijgen zonder dat u aanwezig hoeft te zijn. Een full-service oplossing is waar wij voor staan.",
    "U kunt enkele van onze investeringsmogelijkheden ",
    "hier"
  ],
  de: [
    "Immobilieninvestitionen",
    "Unser Team arbeitet eng mit lokalen Immobilienmaklern zusammen, bei denen wir nur die Immobilien auswählen, die die richtigen Kriterien erfüllen. Alle unsere ausgewählten Immobilien haben:",
    "gute Lage",
    "einfacher Zugang",
    "schlüsselfertig oder leicht zu reparieren",
    "die richtigen Papiere in Ordnung",
    "gute Annehmlichkeiten (Pool)",
    "einfache Wartung",
    "eine gute Rendite",
    "Lust auf eine oder mehrere Immobilien? ",
    "Ich habe eine Immobilie gesehen, die mir gefällt!",
    "Hallo Team FRESH, ich habe eine Immobilie gesehen, die mir gefällt!\nKönnen Sie mir weitere Informationen dazu senden?\n\nEs hat die folgende Referenz: \n",
    "Kontaktieren Sie",
    " uns und wir senden Ihnen die Kontaktdaten der Immobilienmakler. Sobald eine Immobilie gekauft wurde, helfen wir Ihnen bei der gesamten Abwicklung, um die Immobilie auf den Mietmarkt zu bringen, ohne dass Sie anwesend sein müssen. Eine Full-Service-Lösung ist das, wofür wir stehen.",
    "Einige unserer Investitionsmöglichkeiten finden Sie ",
    "hier"
  ]
};

const InvestmentElaboration: FC<InvestmentElaborationProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  const forSaleUrl = `/for-sale${language === "en" ? "" : `/${language}`}`;
  return (
    <Box>
      <Container>
        <Text type="h2">{text()}</Text>
        <Text className="text-left">{text()}</Text>
        <div className="grid grid-cols-1 lg:grid-cols-2 py-4">
          <div>
            <div className="flex flex-row items-center">
              <Icon className="mr-2" icon={checkMark} />
              <Text className="col-span-3">{text()}</Text>
            </div>
            <div className="flex flex-row items-center">
              <Icon className="mr-2" icon={checkMark} />
              <Text className="col-span-3">{text()}</Text>
            </div>
            <div className="flex flex-row items-center">
              <Icon className="mr-2" icon={checkMark} />
              <Text className="col-span-3">{text()}</Text>
            </div>
            <div className="flex flex-row items-center">
              <Icon className="mr-2" icon={checkMark} />
              <Text className="col-span-3">{text()}</Text>
            </div>
            <div className="flex flex-row items-center">
              <Icon className="mr-2" icon={checkMark} />
              <Text className="col-span-3">{text()}</Text>
            </div>
            <div className="flex flex-row items-center">
              <Icon className="mr-2" icon={checkMark} />
              <Text className="col-span-3">{text()}</Text>
            </div>
            <div className="flex flex-row items-center">
              <Icon className="mr-2" icon={checkMark} />
              <Text className="col-span-3">{text()}</Text>
            </div>
          </div>
          <img src="/visuals/rental_income.webp" alt="Rental Income is maximized by FRESH" />
        </div>
        <Text className="text-left">
          {text()}
          <WhatsAppLink subject={text()} message={text()}>
            {text()}
          </WhatsAppLink>
          {text()}
        </Text>
        <Text>
          {text()}
          <Link to={forSaleUrl} className="text-primary">
            {text()}
          </Link>
          .
        </Text>
      </Container>
    </Box>
  );
};

export default InvestmentElaboration;
