import { FC, useRef } from "react";
import Page from "../../Layout/Page";
import InvestmentCallToAction from "./InvestmentCallToAction";
import InvestmentElaboration from "./InvestmentElaboration";
import SEO from "../../Widgets/SEO/SEO";

export type InvestmentAdviceProps = {};

const seo = {
  title: {
    en: "Invest in the Spanish real estate market",
    es: "Invierta en el mercado inmobiliario español",
    nl: "Investeer in de Spaanse vastgoedmarkt",
    de: "Investieren Sie in den spanischen Immobilienmarkt"
  },
  description: {
    en: "We understand that buying a property can be a big decision. That's why we're committed to providing you with the best possible service; Our team works closely with local real estate agents, where we hand pick only the properties that tick the right boxes.",
    es: "Entendemos que comprar una propiedad puede ser una gran decisión. Es por eso que nos comprometemos a brindarle el mejor servicio posible; Nuestro equipo trabaja en estrecha colaboración con agentes inmobiliarios locales, donde seleccionamos a mano solo las propiedades que marcan las casillas correctas.",
    nl: "We begrijpen dat het kopen van een woning een grote beslissing kan zijn. Daarom zijn we toegewijd om u de best mogelijke service te bieden; Ons team werkt nauw samen met lokale makelaars, waar we alleen de woningen selecteren die de juiste vakjes aanvinken.",
    de: "Wir verstehen, dass der Kauf einer Immobilie eine große Entscheidung sein kann. Deshalb sind wir bestrebt, Ihnen den bestmöglichen Service zu bieten. Unser Team arbeitet eng mit lokalen Immobilienmaklern zusammen, bei denen wir nur die Immobilien auswählen, die die richtigen Kästchen ankreuzen."
  }
};

const InvestmentAdvice: FC<InvestmentAdviceProps> = ({}) => {
  const rentalAdviceRef = useRef<HTMLDivElement>(null);
  return (
    <Page>
      <SEO title={seo.title} description={seo.description}>
        <InvestmentCallToAction rentalAdviceRef={rentalAdviceRef} />
        <InvestmentElaboration />
      </SEO>
    </Page>
  );
};

export default InvestmentAdvice;
