import { FC, Fragment, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { classNames } from "../utils/string";
import Logo from "../Widgets/Logo";
import { MenuItemProps } from "./MenuItem";
import Overlay from "./Overlay";
import { Icon } from "@iconify/react";
import dropdownArrow from "@iconify-icons/ph/caret-down";
import { createPortal } from "react-dom";
import { Language, isLanguage } from "./Page";
import LanguageDropdown from "./LanguageDropdown";

export type LayoutHeaderProps = {
  menuItems: MenuItemProps[];
};

export type RenderedMenuItemProps = {
  item: MenuItemProps;
  i: number;
};

const getUrl = (url: string, language: Language) => {
  const [path, query] = url.split("?");
  return language === "en" || url.includes("https:")
    ? url
    : `${path}/${language}${query ? `?${query}` : ""}`.replaceAll("//", "/");
};

const getNavigateUrl = (pathname: string, language: Language) => {
  const url = pathname.replace(/(\/en|\/es|\/nl|\/de)/gi, "");
  return language === "en" || url.includes("https:") ? url : `${url}/${language}`.replaceAll("//", "/");
};

const RenderedMenuItem: FC<RenderedMenuItemProps> = ({ item, i }) => {
  const { pathname } = useLocation();
  const possibleLanguage = pathname.split("/").pop();
  const language = isLanguage(possibleLanguage)
    ? possibleLanguage
    : window.location.hostname.endsWith("nl")
    ? "nl"
    : "en";

  const liRef = useRef<HTMLLIElement>(null);
  const [open, setOpen] = useState(false);

  const onCloseMenu = () => {
    if (open) {
      setOpen(false);
    }
  };

  if (item.items !== undefined) {
    return (
      <>
        <li
          ref={liRef}
          className={classNames(
            "flex flex-row items-center cursor-pointer p-4 py-2 hover:text-primary",
            (open || pathname.replace(`/${language}`, "").includes(item.url)) && "text-secondary"
          )}
          onClick={() => setOpen(!open)}
        >
          <div className="m-r-4">{item.label[language]}</div>
          <Icon icon={dropdownArrow} className="w-4 h-4" />
        </li>
        {createPortal(
          <div
            className={classNames("fixed top-0 left-0 right-0 bottom-0 z-50", !open && "hidden")}
            onClick={onCloseMenu}
          >
            <nav
              className="fixed bg-white p-4 flex flex-col shadow-xl list-none max-h-[calc(100vh-250px)] overflow-y-auto"
              style={{
                top: liRef.current?.getBoundingClientRect().bottom,
                left: liRef.current?.getBoundingClientRect().left
              }}
            >
              {item.items.map((it, i) => (
                <RenderedMenuItem key={`sub-item-${i}`} item={it} i={i} />
              ))}
            </nav>
          </div>,
          document.body
        )}
      </>
    );
  }
  return (
    <li key={`mobile-menu-item-${i}`}>
      <Link
        className={classNames(
          "p-4 py-2 block hover:text-primary",
          (pathname.replace(`/${language}`, "") === item.url || pathname.replace(`${language}`, "") === item.url) &&
            "text-secondary"
        )}
        to={getUrl(item.url, language)}
      >
        {item.label[language]}
      </Link>
    </li>
  );
};

const LayoutHeader: FC<LayoutHeaderProps> = ({ menuItems }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { pathname, search } = useLocation();
  const location = decodeURI(`${pathname}${search}`);
  const navigate = useNavigate();
  const possibleLanguage = pathname.split("/").pop();
  const language = isLanguage(possibleLanguage)
    ? possibleLanguage
    : window.location.hostname.endsWith("nl")
    ? "nl"
    : "en";
  useEffect(() => {
    ref.current?.classList.add("hidden");
  }, [location]);

  return (
    <header
      role="banner"
      className="sticky z-40 bg-white border-b-4 border-primary h-32 top-0 shadow-lg flex flex-col w-full component-preview items-stretch"
    >
      <nav className="flex flex-row w-full max-w-7xl mx-auto gap-2 p-4">
        <div className="flex">
          <div className="flex items-start justify-start border-black">
            <Logo />
            <LanguageDropdown
              language={language}
              setLanguage={(language) => navigate(getNavigateUrl(pathname, language))}
            />
          </div>
        </div>
        <div className="flex-1 flex flex-wrap items-center justify-end lg:justify-end w-full text-gray-dark text-base">
          <div className="hidden lg:flex items-end w-auto">
            <ul className="flex flex-row items-center justify-between">
              {menuItems.map((item, i) => (
                <RenderedMenuItem key={`menu-item-${i}`} item={item} i={i} />
              ))}
            </ul>
          </div>
          <button aria-label="Menu button to open the menu sider" className="lg:hidden mr-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => ref.current?.classList.toggle("hidden")}
              className="h-6 w-6 cursor-pointer block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
          <Overlay className="hidden" ref={ref}>
            <div
              onClick={() => ref.current?.classList.toggle("hidden")}
              className="fixed top-0 left-0 right-0 bottom-0 bg-grey-super-dark bg-opacity-50"
            ></div>
            <div className="fixed top-28 max-h-[calc(100vh-250px)] overflow-y-auto mt-2 right-8 bg-white shadow-xl p-4 rounded-sm flex flex-col">
              <ul
                className="
              text-base text-gray-700
              md:flex
              md:flex-col
              md:justify-between"
              >
                {menuItems.map((item: MenuItemProps, i) => (
                  <Fragment key={`mobile-menu-item-${i}`}>
                    {item.items ? (
                      <div className={classNames("p-4 py-2 block", i !== 0 && "border-t")}>
                        <div className={classNames("py-2")}>{item.label[language]}</div>
                        <div className="pb-4">
                          {item.items.map((item: MenuItemProps, i) => (
                            <li key={`mobile-sub-menu-item-${i}`}>
                              <Link
                                className={classNames(
                                  "p-4 py-2 block text-black hover:text-primary",
                                  location === getUrl(item.url, language) && "text-secondary"
                                )}
                                to={getUrl(item.url, language)}
                              >
                                {item.label[language]}
                              </Link>
                            </li>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <li>
                        <Link
                          className={classNames(
                            "p-4 py-2 block",
                            i !== 0 && "border-t",
                            item.active && "text-secondary",
                            "hover:text-primary"
                          )}
                          to={getUrl(item.url, language)}
                        >
                          {item.label[language]}
                        </Link>
                      </li>
                    )}
                  </Fragment>
                ))}
              </ul>
            </div>
          </Overlay>
        </div>
      </nav>
    </header>
  );
};

export default LayoutHeader;
