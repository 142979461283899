import { FC } from "react";
import { classNames } from "../../../../utils/string";
import Text from "../../../../Widgets/Text/Text";

type SwitchProps = {
  on: boolean;
  onChange: (on: boolean) => void;
  disabled?: boolean;
};

const Switch: FC<SwitchProps> = ({ on, onChange, disabled = false }) => {
  return (
    <button
      className={classNames(
        "relative w-14 h-8 border rounded-3xl",
        "duration-150 transition-color",
        "outline-primary",
        on ? "bg-primary border-primary" : "bg-grey-ultra-light border-grey-light shadow-inner",
        "disabled:bg-grey-medium"
      )}
      onClick={() => onChange(!on)}
    >
      <div
        className={classNames(
          "absolute top-[1px] bottom-[1px] aspect-square bg-white border-grey-light rounded-full shadow",
          "duration-150 transition-all",
          on ? "left-[26px]" : "left-[1px]"
        )}
      />
    </button>
  );
};

export type BooleanSwitchProps = {
  label?: string;
  value?: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  className?: string;
};

const BooleanSwitch: FC<BooleanSwitchProps> = ({ value = false, onChange, label, disabled = false, className }) => {
  return (
    <div className={classNames("flex flex-row items-center justify-between", className)}>
      <Text className="!my-0 mr-2">{label}</Text>
      <Switch on={value} onChange={onChange} disabled={disabled} />
    </div>
  );
};

export default BooleanSwitch;
