import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import LinkButton from "../../Widgets/Button/LinkButton";
import { LanguageContext } from "../../Layout/Page";

export type HomeBookYourStayProps = {};

const translations = {
  en: [
    `Our Rental Selection`,
    `Book your stay at one of our managed properties`,
    `FRESH Holiday is a web-based booking service that allows to book holidays direct with the owner, ensuring the best possible price. The site has a wide range of properties to choose from, including villas, apartments, and chalets, all located in one of the most beautiful places in the world; The Costa del Sol. To book a holiday, one can simply enter their desired destination, dates, and number of guests, and FRESH Holiday will search its database of properties and present the best results. People can then browse through the property descriptions and photos, and make a booking directly with the owner (you).`,
    `Book Your Stay`
  ],
  es: [
    `Nuestra selección de alquileres`,
    `Reserve su estancia en una de nuestras propiedades gestionadas`,
    `FRESH Holiday es un servicio de reserva basado en la web que permite reservar vacaciones directamente con el propietario, garantizando el mejor precio posible. El sitio tiene una amplia gama de propiedades para elegir, incluidas villas, apartamentos y chalets, todos ubicados en el lugar más hermoso del mundo; La Costa del Sol. Para reservar unas vacaciones, simplemente puede ingresar su destino deseado, fechas y número de huéspedes, y FRESH Holiday buscará en su base de datos de propiedades y presentará los mejores resultados. Luego, las personas pueden navegar por las descripciones y fotos de la propiedad y hacer una reserva directamente con el propietario (usted).`,
    `Reserve su estancia`
  ],
  nl: [
    `Onze huuraanbod`,
    `Boek uw verblijf in een van de door ons beheerde accommodaties`,
    `FRESH Holiday is een webgebaseerde boekingsservice die het mogelijk maakt om vakanties rechtstreeks bij de eigenaar te boeken, zodat je verzekerd bent van de best mogelijke prijs. De site heeft een breed scala aan accommodaties om uit te kiezen, waaronder villa's, appartementen en chalets, allemaal gelegen op één van de mooiste plekken ter wereld; de Costa del Sol. Om een vakantie te boeken, kan men simpelweg de gewenste bestemming, data en het aantal gasten invoeren, waarna FRESH Holiday de database met accommodaties doorzoekt en de beste resultaten presenteert. Mensen kunnen dan door de beschrijvingen en foto's van de accommodaties bladeren en direct bij de eigenaar (jij dus) boeken.`,
    `Boek uw verblijf`
  ],
  de: [
    `Unsere Mietauswahl`,
    `Buchen Sie Ihren Aufenthalt in einer von uns verwalteten Immobilie`,
    `FRESH Holiday ist ein webbasiertes Buchungssystem, mit dem Sie Ihren Urlaub direkt beim Eigentümer buchen können und so den bestmöglichen Preis erhalten. Die Website bietet eine große Auswahl an Immobilien, darunter Villen, Apartments und Chalets, die sich alle an einem der schönsten Orte der Welt befinden; Die Costa del Sol. Um einen Urlaub zu buchen, können Sie einfach Ihr gewünschtes Reiseziel, Ihre Daten und die Anzahl der Gäste eingeben, und FRESH Holiday durchsucht die Datenbank mit Immobilien und präsentiert die besten Ergebnisse. Die Leute können dann die Beschreibungen und Fotos der Immobilien durchsuchen und direkt beim Eigentümer (also bei Ihnen) buchen.`,
    `Buchen Sie Ihren Aufenthalt`
  ]
};

const HomeBookYourStay: FC<HomeBookYourStayProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  return (
    <Box backgroundColor="primary">
      <Container className="grid grid-cols-1 items-center">
        <div className="text-white px-8 py-4 my-4 lg:my-0">
          <Text type="h2">{text()}</Text>
          <Text type="h4" className="mb-8" as="h3">
            {text()}
          </Text>
          <img src="/logo_holiday.svg" alt="Fresh Holiday | Book your stay now!" className="mx-auto w-40 h-40 mb-8" />
          <Text className="mb-8">{text()}</Text>
          <LinkButton type="inverse" to="https://www.fresh.holiday/search">
            {text()}
          </LinkButton>
        </div>
      </Container>
      <svg
        className="w-[calc(100%+1.3px)] h-[76px] -scale-y-100 fill-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
      >
        <path d="M0,192L80,176C160,160,320,128,480,133.3C640,139,800,181,960,181.3C1120,181,1280,139,1360,117.3L1440,96L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
      </svg>
    </Box>
  );
};

export default HomeBookYourStay;
