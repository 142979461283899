import { FC } from "react";
import { classNames } from "../../utils/string";
import Text from "../../Widgets/Text/Text";
import { Icon } from "@iconify/react";
import starFull from "@iconify-icons/ph/star-fill";
import starEmpty from "@iconify-icons/ph/star";

export type GuestReview = {
  id: string;
  name: string;
  message: string;
  stars: number;
  approved?: boolean;
};

export type HomeReviewCardProps = {
  review: GuestReview;
};

const HomeReviewCard: FC<HomeReviewCardProps> = ({ review }) => {
  const translation = null;
  /*const [translation, setTranslation] = useState<string | null>(null);
  useEffect(() => {
    const translate = async () => {
      const res = await fetch("https://translate.fresh-properties.com/translate", {
        method: "POST",
        body: JSON.stringify({
          q: review.message,
          source: "auto",
          target: "en",
          format: "text",
          api_key: ""
        }),
        headers: { "Content-Type": "application/json" }
      });
      const result = await res.json();
      setTranslation(result.translatedText);
    };
    translate();
  }, []);*/

  return (
    <div
      className={classNames(
        "my-2 lg:m-2 p-2 rounded bg-white shadow",
        "relative",
        review.approved ? "border-1 border-primary" : "border border-grey-light"
      )}
    >
      <div className="flex flex-row items-center justify-center space-x-1 my-4">
        {Array.from({ length: 5 }, (_, i) => (
          <Icon
            key={`star-${i}`}
            className={classNames("w-5", "h-5", i < review.stars ? "text-yellow" : "text-grey-regular")}
            icon={i < review.stars ? starFull : starEmpty}
          />
        ))}
      </div>
      <Text type="h3" className="!my-0">
        {review.name}
      </Text>

      <Text type="h4">Message:</Text>
      <Text>
        <em>{translation || review.message}</em>
      </Text>
    </div>
  );
};

export default HomeReviewCard;
