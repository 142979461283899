import { FC, useContext } from "react";
import Page, { LanguageContext } from "../../Layout/Page";
import SEO from "../../Widgets/SEO/SEO";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";

const seo = {
  title: {
    en: "Things to do in the Axarquía region of Spain",
    es: "Actividades de ocio en la Axarquía",
    nl: "Dingen om te doen in de Axarquía-regio van Spanje",
    de: "Freizeitaktivitäten in der Region Axarquía in Spanien"
  },
  description: {
    en: "The Axarquía region is a beautiful part of Spain, with a lot of things to do. From hiking to visiting the beach or enjoying a great diner with lovely views, there is something for everyone. Please find our tips for the area in the map below.",
    es: "La región de la Axarquía es una hermosa parte de España, con muchas cosas que hacer. Desde hacer senderismo hasta visitar la playa o disfrutar de una gran cena con vistas encantadoras, hay algo para todos. Encuentre nuestros consejos para la zona en el mapa a continuación.",
    nl: "De Axarquía-regio is een prachtig deel van Spanje, met veel dingen om te doen. Van wandelen tot een bezoek aan het strand of genieten van een geweldig diner met prachtig uitzicht, er is voor elk wat wils. Hieronder vindt u onze tips voor het gebied op de kaart.",
    de: "Die Region Axarquía ist ein wunderschöner Teil Spaniens mit vielen Freizeitaktivitäten. Vom Wandern über den Besuch des Strandes bis hin zum Genießen eines großartigen Abendessens mit herrlichem Blick ist für jeden etwas dabei. Bitte finden Sie unsere Tipps für die Gegend auf der Karte."
  }
};

export type ThingsToDoPageProps = {};

const ThingsToDoPage: FC<ThingsToDoPageProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  return (
    <Page>
      <SEO title={seo.title} description={seo.description}>
        <Box>
          <Container>
            <Text type="h2">{seo.title[language]}</Text>
            <Text>{seo.description[language]}</Text>
            <iframe
              src="https://www.google.com/maps/d/u/0/embed?mid=1Qw-il5s904OGMo3mKUWhrb_7AbMgujU&ehbc=2E312F"
              width="100%"
              height="600"
            ></iframe>
          </Container>
        </Box>
      </SEO>
    </Page>
  );
};

export default ThingsToDoPage;
