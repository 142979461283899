import { PropsWithChildren, forwardRef, PropsWithoutRef, ForwardRefExoticComponent, RefAttributes } from "react";

export type OverlayProps = PropsWithChildren<{ className?: string; onClick?: () => void }>;

const Overlay: ForwardRefExoticComponent<PropsWithoutRef<OverlayProps> & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  OverlayProps
>(({ children, className, onClick }, ref) => {
  return (
    <div
      ref={ref}
      onScroll={(e) => e.stopPropagation()}
      className={"fixed z-50 top-0 bottom-0 left-0 right-0 ".concat(className ?? "")}
      onClick={onClick}
    >
      {children}
    </div>
  );
});

export default Overlay;
