import { FC, ReactElement, useEffect, useMemo, useState } from "react";
import Tab from "./Tab";
import TabPanel from "./TabPanel";

export type TabSettings = {
  tab: ReactElement;
  content: ReactElement;
};

export type TabPaneProps = {
  tabs: TabSettings[];
  overrideTabIndex?: number;
  onChange?: (tabIndex: number) => void;
};

const TabPane: FC<TabPaneProps> = ({ tabs, overrideTabIndex, onChange }) => {
  const [activeTab, _setActiveTab] = useState(overrideTabIndex ?? 0);

  useEffect(() => {
    if (overrideTabIndex !== undefined) {
      _setActiveTab(overrideTabIndex);
    }
  }, [overrideTabIndex, _setActiveTab]);

  const setActiveTab = (newTab: number) => {
    onChange?.(newTab);
    _setActiveTab(newTab);
  };

  const renderedTabs = useMemo(
    () =>
      tabs.map(({ tab }, i) => (
        <Tab key={`Tab-${i}`} index={i} active={activeTab === i} onClick={() => setActiveTab(i)}>
          {tab}
        </Tab>
      )),
    [tabs, activeTab]
  );

  const renderedTabPanels = useMemo(
    () =>
      tabs.map(({ content }, i) => (
        <TabPanel key={`TabPanel-${i}`} index={i} active={activeTab === i}>
          {content}
        </TabPanel>
      )),
    [tabs, activeTab]
  );

  return (
    <>
      <div className="flex-1 border-blue-gray-100 border border-solid bg-white">
        <div role="tablist" className="flex flex-col md:flex-row flex-1">
          {renderedTabs}
        </div>
        {renderedTabPanels}
      </div>
    </>
  );
};

export default TabPane;
