import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import { Icon } from "@iconify/react";
import checkMark from "@iconify-icons/ph/check-bold";
import { LanguageContext } from "../../Layout/Page";

export type BuyingAgentStepsProps = {};

const translations = {
  en: [
    "What are the steps involved in buying a home?",
    "During the intake interview we will go through the steps required to purchase a property in Spain.",
    'Applying for an NIE number "Número de Identificación de Extranjeros"',
    "Notarized power of attorney",
    "Verification of documents, certificates and licenses related to the property to be purchased",
    "Preparation of the reservation and/or purchase contract",
    "Scheduling and accompaniment for the signing of the purchase contract"
  ],
  es: [
    "¿Cuáles son los pasos involucrados en la compra de una casa?",
    "Durante la entrevista de admisión, repasaremos los pasos necesarios para comprar una propiedad en España.",
    'Solicitud de un número NIE "Número de Identificación de Extranjeros"',
    "Poder notarial",
    "Verificación de documentos, certificados y licencias relacionados con la propiedad a comprar",
    "Preparación del contrato de reserva y/o compra",
    "Programación y acompañamiento para la firma del contrato de compra"
  ],
  nl: [
    "Wat zijn de stappen die nodig zijn om een huis te kopen?",
    "Tijdens het intakegesprek zullen we de stappen doornemen die nodig zijn om een woning in Spanje te kopen.",
    'Aanvragen van een NIE-nummer "Número de Identificación de Extranjeros"',
    "Notarieel volmacht",
    "Verificatie van documenten, certificaten en vergunningen met betrekking tot de aan te kopen woning",
    "Opstellen van het reserverings- en/of koopcontract",
    "Inplannen en begeleiden voor het tekenen van het koopcontract"
  ],
  de: [
    "Welche Schritte sind erforderlich, um ein Haus zu kaufen?",
    "Während des Aufnahmegesprächs werden wir die Schritte durchgehen, die zum Kauf einer Immobilie in Spanien erforderlich sind.",
    'Beantragung einer NIE-Nummer "Número de Identificación de Extranjeros"',
    "Notarielle Vollmacht",
    "Überprüfung von Dokumenten, Zertifikaten und Lizenzen im Zusammenhang mit dem zu kaufenden Objekt",
    "Vorbereitung des Reservierungs- und/oder Kaufvertrags",
    "Planung und Begleitung bei der Unterzeichnung des Kaufvertrags"
  ]
};

const BuyingAgentSteps: FC<BuyingAgentStepsProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  return (
    <Box backgroundColor="primary">
      <Container className="text-white">
        <Text type="h3">{text()}</Text>
        <Text>{text()}</Text>
        <div className="p-4 lg:max-w-[800px] mx-auto">
          <div className="flex flex-row items-start justify-start text-left w-full">
            <Icon icon={checkMark} className="text-white mt-4 w-12 h-6 mr-2" />
            <Text className="w-max flex-1">{text()}</Text>
          </div>
          <div className="flex flex-row items-start justify-start text-left w-full">
            <Icon icon={checkMark} className="text-white mt-4 w-12 h-6 mr-2" />
            <Text className="w-max flex-1">{text()}</Text>
          </div>
          <div className="flex flex-row items-start justify-start text-left w-full">
            <Icon icon={checkMark} className="text-white mt-4 w-12 h-6 mr-2" />
            <Text className="w-max flex-1">{text()}</Text>
          </div>
          <div className="flex flex-row items-start justify-start text-left w-full">
            <Icon icon={checkMark} className="text-white mt-4 w-12 h-6 mr-2" />
            <Text className="w-max flex-1">{text()}</Text>
          </div>
          <div className="flex flex-row items-start justify-start text-left w-full">
            <Icon icon={checkMark} className="text-white mt-4 w-12 h-6 mr-2" />
            <Text className="w-max flex-1">{text()}</Text>
          </div>
        </div>
      </Container>
      <svg
        className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
      >
        <path
          d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
        ></path>
      </svg>
    </Box>
  );
};

export default BuyingAgentSteps;
