import { FC } from "react";
import Page, { isLanguage } from "../../Layout/Page";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Loader from "../../Widgets/Loader";
import { Link, useLocation } from "react-router-dom";
import Text from "../../Widgets/Text/Text";
import useGetData from "../../hooks/useGetData";
import { PropertyLongTerm, extractLongTermImages } from "./types";
import { process } from "../../process";
import SEO from "../../Widgets/SEO/SEO";

const translations = {
  en: {
    title: "Our long term rentals",
    noListings: "There are currently no long term rental opportunities, please contact us to update you when we do.",
    description:
      "We have a range of long term rental properties available in the Costa del Sol. Please contact us for more information."
  },
  es: {
    title: "Nuestros alquileres a largo plazo",
    noListings:
      "Actualmente no hay oportunidades de alquiler a largo plazo, por favor contáctenos para que lo actualicemos cuando lo hagamos.",
    description:
      "Tenemos una variedad de propiedades en alquiler a largo plazo disponibles en la Costa del Sol. Por favor contáctenos para más información."
  },
  nl: {
    title: "Onze lange termijn verhuur",
    noListings:
      "Er zijn momenteel geen mogelijkheden voor lange termijn verhuur, neem contact met ons op om u bij te werken wanneer we dat wel doen.",
    description:
      "We hebben een scala aan lange termijn verhuur beschikbaar in de Costa del Sol. Neem contact met ons op voor meer informatie."
  },
  de: {
    title: "Unsere Langzeitmieten",
    noListings:
      "Derzeit gibt es keine Langzeitmietmöglichkeiten. Bitte kontaktieren Sie uns, damit wir Sie aktualisieren können, wenn wir dies tun.",
    description:
      "Wir haben eine Reihe von Langzeitmietobjekten an der Costa del Sol. Bitte kontaktieren Sie uns für weitere Informationen."
  }
};

export type LongTermProps = {};

const LongTerm: FC<LongTermProps> = ({}) => {
  const [loading, listings] = useGetData<PropertyLongTerm[]>("/long-term");
  const { pathname } = useLocation();
  const possibleLanguage = pathname.split("/").pop();
  const language = isLanguage(possibleLanguage)
    ? possibleLanguage
    : window.location.hostname.endsWith("nl")
    ? "nl"
    : "en";

  return (
    <SEO title={translations[language].title} description={translations[language].description}>
      <Page>
        <Box>
          <Container>
            <Text className="text-center block" type="h2">
              {translations[language].title}
            </Text>
            <Text className="text-center block" type="p">
              {translations[language].description}
            </Text>
          </Container>
          <Loader loading={loading}>
            {listings && listings.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {listings.map((listing, i) => {
                  const images = extractLongTermImages(listing.images);
                  return (
                    <Link
                      key={`listing-${i}`}
                      to={`/long-term/${listing._id}${language && `/${language}`}`}
                      className="bg-white rounded px-6 py-4 border border-grey-super-light hover:border-secondary active:border-primary cursor-pointer shadow m-2"
                    >
                      <Text className="line-clamp-1" type="h4">
                        {listing.name}
                      </Text>
                      <Text className="line-clamp-1" type="h5">
                        {listing.city}
                      </Text>
                      <div className="flex flex-row items-center justify-between">
                        {listing.sizeM2 && <Text>{listing.sizeM2}m2</Text>}
                        {listing.price && <Text type="h3">€{listing.price}</Text>}
                      </div>
                      {images.length > 0 && (
                        <img
                          className="aspect-square object-cover"
                          src={`${process.env.REACT_APP_API}${images[0]}`}
                          alt={listing.name}
                        />
                      )}
                      <div
                        className="text-left line-clamp-5 mt-2"
                        dangerouslySetInnerHTML={{ __html: listing.description ?? "" }}
                      />

                      <Text>
                        <small className="text-primary">Read more &gt;</small>
                      </Text>
                    </Link>
                  );
                })}
              </div>
            ) : (
              <div>
                <Text className="text-center block" type="p">
                  {translations[language].noListings}
                </Text>
              </div>
            )}
          </Loader>
        </Box>
      </Page>
    </SEO>
  );
};

export default LongTerm;
