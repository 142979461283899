import { FC, RefObject, useContext } from "react";
import Text from "../../Widgets/Text/Text";
import Button from "../../Widgets/Button/Button";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import { LanguageContext } from "../../Layout/Page";

export type HomeCallToActionProps = { hassleFreeRef: RefObject<HTMLDivElement> };

const translations = {
  en: [
    `Your`,
    `holiday & rental`,
    `experts`,
    `Discover how we can make your life care free`,
    `Read more about our services`
  ],
  es: [
    `Sus `,
    `vacaciones y alquileres`,
    `expertos`,
    `Descubra cómo podemos hacer que su vida no requiera preocupaciones`,
    `Leer más sobre nuestros servicios`
  ],
  nl: [
    `Uw`,
    `vakantie & verhuur`,
    `experts`,
    `ontdek hoe wij uw leven zorgeloos kunnen maken`,
    `Lees meer over onze diensten`
  ],
  de: [
    `Ihr`,
    `Urlaub & Vermietung`,
    `Experten`,
    `Entdecken Sie, wie wir Ihr Leben sorgenfrei gestalten können`,
    `Mehr lesen über unsere Dienstleistungen`
  ]
};

const HomeCallToAction: FC<HomeCallToActionProps> = ({ hassleFreeRef }) => {
  const { language } = useContext(LanguageContext);
  console.log(language);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  return (
    <Box
      className="overflow-x-hidden"
      backgroundColor="primary"
      text={{ color: "white" }}
      icon={{ fillColor: "white" }}
    >
      <Container className="!pb-0 h-[600px]" extraTop>
        <div className="grid grid-cols-1 lg:grid-cols-2 space-x-4 h-full items-center">
          <div>
            <Text type="h1">
              {text()} <span className="text-yellow">{text()}</span> {text()}
            </Text>
            <Text className="mt-4 mb-6" type="h3" as="h2">
              {text()}
            </Text>
            <Button
              type="inverse"
              onClick={() =>
                window.scrollTo({ top: hassleFreeRef.current?.getBoundingClientRect().top ?? 0, behavior: "smooth" })
              }
            >
              {text()}
            </Button>
          </div>
          <div className="lg:flex flex-col items-center">
            <img
              className="hidden lg:block"
              width="350px"
              height="350px"
              src="/logo.svg"
              alt="FRESH Property Management Logo without text"
            />
          </div>
        </div>
      </Container>
      <svg
        className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
      >
        <path
          d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
        ></path>
      </svg>
    </Box>
  );
};

export default HomeCallToAction;
