import { FC, PropsWithChildren } from "react";
import { classNames } from "../../utils/string";

export type TabPanelProps = {
  index: number;
  active: boolean;
};

const TabPanel: FC<PropsWithChildren<TabPanelProps>> = ({ children, index, active }) => (
  <div
    tabIndex={0}
    className={classNames("p-4 bg-white flex-1 [&>*]:m-0 [&>*]:flex-1", active && "flex items-stretch")}
    role="tabpanel"
    aria-activedescendant={`tab ${index + 1}`}
    aria-labelledby={`tab ${index + 1}`}
    aria-hidden={!active}
    hidden={!active}
  >
    {children}
  </div>
);

export default TabPanel;
