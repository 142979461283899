import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import { LanguageContext } from "../../Layout/Page";

export type BuyingAgentCallToActionProps = {};

const translations = {
  en: ["Your ", "Purchase Agent", " in Spain", "See how we can help you find your dream home!"],
  es: ["Su", "Agente de Compra", " en España", "¡Podemos ayudarlo a encontrar la casa de sus sueños!"],
  nl: ["Jouw ", "Aankoopmakelaar", " in Spanje", "Wij kunnen je helpen jouw droomhuis te vinden!"],
  de: ["Ihr ", "Kaufagent", " in Spanien", "Wir helfen Ihnen, Ihr Traumhaus zu finden!"]
};

const BuyingAgentCallToAction: FC<BuyingAgentCallToActionProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  return (
    <Box
      className="overflow-x-hidden"
      backgroundColor="primary"
      text={{ color: "white" }}
      icon={{ fillColor: "white" }}
    >
      <Container padding={{ top: 14 }} extraTop>
        <div className="grid grid-cols-1 lg:grid-cols-2 space-x-4 items-center">
          <div>
            <Text type="h1">
              {text()}
              <span className="text-yellow">{text()}</span>
              {text()}
            </Text>
            <Text className="mb-4" type="h4">
              {text()}
            </Text>
          </div>
          <div className="lg:-mt-4 lg:flex flex-col items-center">
            <img
              className="hidden lg:block"
              width="350px"
              height="350px"
              src="/logo.svg"
              alt="FRESH Property Management Logo without text"
            />
          </div>
        </div>
      </Container>
      <svg
        className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
      >
        <path
          d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
        ></path>
      </svg>
    </Box>
  );
};

export default BuyingAgentCallToAction;
