import { classNames } from "./utils/string";

// Text
export type TextAlign = "inherit" | "left" | "center" | "right" | "justify";
export type TextColor = "inherit" | "primary" | "secondary" | "white" | "dark" | "black";

export type TextStyle = {
  align?: TextAlign;
  color?: TextColor;
};

export const resolveTextAlign = (align: TextAlign = "inherit") => {
  switch (align) {
    case "left":
      return "text-left";
    case "right":
      return "text-right";
    case "justify":
      return "text-justify";
    case "center":
      return "text-center";
    case "inherit":
    default:
      return false;
  }
};

export const resolveTextColor = (color: TextColor = "inherit") => {
  switch (color) {
    case "primary":
      return "text-primary";
    case "secondary":
      return "text-secondary";
    case "white":
      return "text-white";
    case "black":
      return "text-black";
    case "dark":
      return "text-grey-super-dark";
    case "inherit":
    default:
      return false;
  }
};

export const resolveTextStyle = (style?: TextStyle) =>
  classNames(resolveTextAlign(style?.align), resolveTextColor(style?.color));

// SVG
export type SVGColor = "inherit" | "primary" | "secondary" | "white" | "dark" | "black";
export type SVGStyle = {
  fillColor: SVGColor;
};
export const resolveFillColor = (color: SVGColor = "inherit") => {
  switch (color) {
    case "primary":
      return "fill-primary";
    case "secondary":
      return "fill-secondary";
    case "white":
      return "fill-white";
    case "black":
      return "fill-black";
    case "dark":
      return "fill-grey-super-dark";
    case "inherit":
    default:
      return false;
  }
};

export const resolveSVGStyle = (style?: SVGStyle) => classNames(resolveFillColor(style?.fillColor));

// Background
export type BackgroundColor = "primary" | "secondary" | "white" | "grey" | "inherit";
export const resolveBackgroundColor = (color: BackgroundColor = "inherit") => {
  switch (color) {
    case "primary":
      return "bg-primary";
    case "secondary":
      return "bg-secondary";
    case "white":
      return "bg-white";
    case "grey":
      return "bg-grey-super-light";
    case "inherit":
    default:
      return false;
  }
};

export const AllTailwindSizes = [
  "px",
  0,
  0.5,
  1,
  1.5,
  2,
  2.5,
  3,
  3.5,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  14,
  16,
  20,
  24,
  28,
  32,
  36,
  40,
  44,
  48,
  52,
  56,
  60,
  64,
  72,
  80,
  96
] as const;
export type TailwindSizeNum = (typeof AllTailwindSizes)[number] | `${number}px`;
export const resolveTailwindSizeNum = (prefix: string, size?: TailwindSizeNum) => {
  switch (true) {
    case size === undefined:
      return false;
    case typeof size === "string" && size.includes("px"):
      return `${prefix}-[${size}]`;
    default:
      return `${prefix}-${size}`;
  }
};

// Margin + Padding
export type MarginOrPadding = {
  top?: TailwindSizeNum;
  right?: TailwindSizeNum;
  bottom?: TailwindSizeNum;
  left?: TailwindSizeNum;
  x?: TailwindSizeNum;
  y?: TailwindSizeNum;
  all?: TailwindSizeNum;
};

export const resolveMargin = (margin?: MarginOrPadding) =>
  classNames(
    resolveTailwindSizeNum("!mt", margin?.top),
    resolveTailwindSizeNum("!mr", margin?.right),
    resolveTailwindSizeNum("!mb", margin?.bottom),
    resolveTailwindSizeNum("!ml", margin?.left),
    resolveTailwindSizeNum("!mx", margin?.x),
    resolveTailwindSizeNum("!my", margin?.y),
    resolveTailwindSizeNum("!m", margin?.all)
  );

export const resolvePadding = (padding?: MarginOrPadding) =>
  classNames(
    resolveTailwindSizeNum("!pt", padding?.top),
    resolveTailwindSizeNum("!pr", padding?.right),
    resolveTailwindSizeNum("!pb", padding?.bottom),
    resolveTailwindSizeNum("!pl", padding?.left),
    resolveTailwindSizeNum("!px", padding?.x),
    resolveTailwindSizeNum("!py", padding?.y),
    resolveTailwindSizeNum("!p", padding?.all)
  );

export const resolveStyle = (style?: StyleProps, defaults?: StyleProps): string =>
  classNames(
    "overflow-x-hidden",
    resolveSVGStyle(style?.icon ?? defaults?.icon),
    resolveTextStyle(style?.text ?? defaults?.text),
    resolveBackgroundColor(style?.backgroundColor ?? defaults?.backgroundColor),
    resolveMargin(style?.margin ?? defaults?.margin),
    resolvePadding(style?.padding ?? defaults?.padding),
    style?.className ?? defaults?.className
  );

export type StyleProps = {
  icon?: SVGStyle;
  text?: TextStyle;
  backgroundColor?: BackgroundColor;
  margin?: MarginOrPadding;
  padding?: MarginOrPadding;
  className?: string;
};

export type Translation = {
  en: string;
  es: string;
  nl: string;
  de: string;
};
