import { FC, PropsWithChildren } from "react";
import { classNames } from "../../utils/string";

export type BackgroundWithRingsProps = {
  className?: string;
};

const BackgroundWithRings: FC<PropsWithChildren<BackgroundWithRingsProps>> = ({ className, children }) => {
  return (
    <div
      className={classNames(
        "w-full",
        "relative flex flex-col justify-center",
        "overflow-hidden",
        "bg-primary text-white border-b border-white",
        className
      )}
    >
      <div
        className={classNames(
          "absolute",
          "left-1/2 top-1/4 lg:top-1/2",
          "w-[max(min(100vw,100%),600px)] aspect-square max-w-[96rem]",
          "-translate-x-1/2 -translate-y-[40%]",
          "scale-110",
          "border-[min(12rem,12vw)] border-white/10",
          "rounded-full",
          "z-0"
        )}
      />
      <div
        className={classNames(
          "absolute",
          "left-1/2 top-1/4 lg:top-1/2",
          "w-[max(min(100vw,100%),600px)] aspect-square max-w-[96rem]",
          "-translate-x-1/2 -translate-y-[40%]",
          "scale-110",
          "p-[min(10rem,10vw)]",
          "z-0"
        )}
      >
        <div className={classNames("border-[min(2rem,2vw)] border-white/10", "rounded-full", "w-full h-full")} />
      </div>
      <div
        className={classNames(
          "absolute",
          "left-1/2 top-1/4 lg:top-1/2",
          "w-[max(min(100vw,100%),600px)] aspect-square max-w-[96rem]",
          "-translate-x-1/2 -translate-y-[40%]",
          "scale-110",
          "p-[min(12rem,12vw)]",
          "z-0"
        )}
      >
        <div className={classNames("border-[min(4rem,4vw)] border-white/5", "rounded-full", "w-full h-full")} />
      </div>
      <div className="relative">{children}</div>
    </div>
  );
};

export default BackgroundWithRings;
