import { FC, useContext } from "react";
import Text from "../../Widgets/Text/Text";
import { Icon } from "@iconify/react";
import checkMark from "@iconify-icons/ph/check-bold";
import Container from "../../Layout/Container";
import Box from "../../Layout/Box";
import { LanguageContext } from "../../Layout/Page";
import WhatsAppLink from "../../WhatsAppLink";
import SEO from "../../Widgets/SEO/SEO";

export type PuebloSiteProps = {
  pueblo: string;
};

const translations = {
  en: [
    "Rent out your property in ",
    " hassle free",
    " is located in the Axarquia region of Spain, on the Costa del Sol. It is a popular upcoming tourist destination, thanks to its great location close to Málaga.",
    "The short-term rental market in ",
    " is very active. With an increasing demand for holiday properties on the Costa del Sol this area will continue to grow. The average occupation rate per year in the entire Axarquia area is over 50%. This means that you can expect to rent out your property for a good period throughout the year.",
    " is a great place to invest in a short-term rental property. The village is located in an upcoming tourist destination, and the demand for rental properties is high. Additionally, the short-term rental market in ",
    " is expected to grow in the coming years, making it a wise investment.",
    "Here are some of the benefits of investing in a short-term rental property in ",
    "High demand: The demand for rental properties in ",
    " is high, thanks to the village's popularity as a tourist destination.",
    "High occupancy rates: The average occupancy rate for short-term rental properties in ",
    " is over 50%.",
    "Strong rental income: You can expect to earn a strong rental income from your property in ",
    "Capital appreciation: The value of your property is likely to appreciate over time, making it a wise investment.",
    "If you are considering investing in a short-term rental property, ",
    " is a great option. The village is located in a prime tourist destination, and the demand for rental properties is high. Additionally, the short-term rental market in ",
    " is expected to grow in the coming years, making it a wise investment.",
    "Invest in a short-term rental property in ",
    "Are you considering investing in a property to rent out to tourists in ",
    "Property investment advice",
    "Hi team Fresh,\n\nI would like to have some more info on investing in a property in ",
    ".\n\nKind regards,\n\n",
    "Contact FRESH Property Management",
    "and let us help you make the most of your investment.",
    "We have experience in the ",
    " rental market and know which properties make the highest rental income. We can provide you with a customized Return on Investment (ROI) analysis that will help you identify properties that meet your specific investment goals.",
    "In addition, we can support you by presenting you with interesting properties from the portfolio of our extensive partner network of brokers.",
    "Contact us today to learn more about how we can help you make your dream of investing in a rental property in ",
    " a reality."
  ],
  es: [
    "Alquile su propiedad en ",
    " sin preocupaciones",
    " está ubicado en la región de la Axarquía de España, en la Costa del Sol. Es un popular destino turístico en auge, gracias a su excelente ubicación cerca de Málaga.",
    "El mercado de alquileres a corto plazo en ",
    " es muy activo. Con una creciente demanda de propiedades de vacaciones en la Costa del Sol, esta área continuará creciendo. La tasa de ocupación promedio por año en toda el área de la Axarquía es superior al 50%. Esto significa que puede esperar alquilar su propiedad por un buen período durante todo el año.",
    " es un gran lugar para invertir en una propiedad de alquiler a corto plazo. El pueblo está ubicado en un destino turístico en auge, y la demanda de propiedades de alquiler es alta. Además, se espera que el mercado de alquileres a corto plazo en ",
    " crecerá en los próximos años, por lo que es una inversión inteligente.",
    "Estos son algunos de los beneficios de invertir en una propiedad de alquiler a corto plazo en ",
    "Alta demanda: la demanda de propiedades de alquiler en ",
    " es alta, gracias a la popularidad del pueblo como destino turístico.",
    "Altas tasas de ocupación: la tasa de ocupación promedio de las propiedades de alquiler a corto plazo en ",
    " es superior al 50%.",
    "Ingresos de alquiler sólidos: puede esperar obtener un sólido ingreso por alquiler de su propiedad en ",
    "Apreciación de capital: es probable que el valor de su propiedad aumente con el tiempo, lo que la convierte en una inversión inteligente.",
    "Si está considerando invertir en una propiedad de alquiler a corto plazo, ",
    " es una gran opción. El pueblo está ubicado en un destino turístico de primer nivel, y la demanda de propiedades de alquiler es alta. Además, se espera que el mercado de alquileres a corto plazo en ",
    " crecerá en los próximos años, por lo que es una inversión inteligente.",
    "Invierta en una propiedad de alquiler a corto plazo en ",
    "¿Está considerando invertir en una propiedad para alquilar a turistas en ",
    "Consejo de inversión inmobiliaria",
    "Hola equipo Fresh,\n\nMe gustaría tener más información sobre invertir en una propiedad en ",
    ".\n\nSaludos cordiales,\n\n",
    "Contacte con FRESH Property Management",
    "y permítanos ayudarle a sacar el máximo partido a su inversión.",
    "Tenemos experiencia en el mercado de alquileres de ",
    " y sabemos qué propiedades generan los mayores ingresos por alquiler. Podemos proporcionarle un análisis de retorno de la inversión (ROI) personalizado que le ayudará a identificar las propiedades que cumplen con sus objetivos de inversión específicos.",
    "Además, podemos apoyarlo presentándole propiedades interesantes del portafolio de nuestra extensa red de socios de corredores.",
    "Contáctenos hoy mismo para obtener más información sobre cómo podemos ayudarlo a hacer realidad su sueño de invertir en una propiedad de alquiler en ",
    "."
  ],
  nl: [
    "Verhuur uw woning in ",
    " zonder zorgen",
    " ligt in de regio Axarquia in Spanje, aan de Costa del Sol. Het is een populair opkomende toeristische bestemming, dankzij de geweldige ligging vlakbij Málaga.",
    "De markt voor korte termijn verhuur in ",
    " is erg actief. Met een toenemende vraag naar vakantiewoningen aan de Costa del Sol zal dit gebied blijven groeien. De gemiddelde bezettingsgraad per jaar in het hele gebied van Axarquia is meer dan 50%. Dit betekent dat u uw woning gedurende een groot deel van het jaar kunt verhuren.",
    " is een geweldige plek om te investeren in een vakantiewoning. Het dorp ligt in een opkomende toeristische bestemming, en de vraag naar vakantiewoningen is hoog. Bovendien zal de markt voor korte termijn verhuur in ",
    " naar verwachting de komende jaren groeien, waardoor het een verstandige investering is.",
    "Dit zijn enkele van de voordelen van investeren in een vakantiewoning in ",
    "Hoge vraag: de vraag naar vakantiewoningen in ",
    " is hoog, dankzij de populariteit van het dorp als toeristische bestemming.",
    "Hoge bezettingsgraad: de gemiddelde bezettingsgraad voor vakantiewoningen in ",
    " is meer dan 50%.",
    "Sterke huurinkomsten: u kunt een sterke huurinkomsten verwachten van uw woning in ",
    "Kapitaalwaardering: de waarde van uw woning zal naar verwachting in de loop van de tijd stijgen, waardoor het een verstandige investering is.",
    "Als u overweegt te investeren in een vakantiewoning, is ",
    " een geweldige optie. Het dorp ligt in een toeristische topbestemming, en de vraag naar vakantiewoningen is hoog. Bovendien zal de markt voor",
    " naar verwachting de komende jaren groeien, waardoor het een verstandige investering is.",
    "Investeer in een vakantiewoning in ",
    "Overweegt u te investeren in een woning om te verhuren aan toeristen in ",
    "Advies over vastgoedinvesteringen",
    "Hallo team Fresh,\n\nIk zou graag wat meer informatie willen over het investeren in een woning in ",
    ".\n\nMet vriendelijke groet,\n\n",
    "Neem contact op met FRESH Property Management",
    "en laat ons u helpen het meeste uit uw investering te halen.",
    "We hebben ervaring in de ",
    " verhuurmarkt en weten welke woningen de hoogste huurinkomsten genereren. We kunnen u een op maat gemaakte Return on Investment (ROI) analyse verstrekken die u helpt bij het identificeren van woningen die voldoen aan uw specifieke investeringsdoelen.",
    "Daarnaast kunnen we u ondersteunen door u interessante woningen te presenteren uit het portfolio van ons uitgebreide partnernetwerk van makelaars.",
    "Neem vandaag nog contact met ons op voor meer informatie over hoe wij u kunnen helpen uw droom te verwezenlijken om te investeren in een huurwoning in ",
    "."
  ],
  de: [
    "Vermieten Sie Ihre Immobilie in ",
    " ohne Sorgen",
    " liegt in der Region Axarquia in Spanien, an der Costa del Sol. Es ist ein beliebtes aufstrebendes Touristenziel, dank seiner großartigen Lage in der Nähe von Málaga.",
    "Der Markt für kurzfristige Vermietungen in ",
    " ist sehr aktiv. Mit einer zunehmenden Nachfrage nach Ferienimmobilien an der Costa del Sol wird dieses Gebiet weiter wachsen. Die durchschnittliche Belegungsrate pro Jahr in der gesamten Region Axarquia liegt über 50%. Dies bedeutet, dass Sie Ihre Immobilie für einen großen Teil des Jahres vermieten können.",
    " ist ein großartiger Ort, um in eine Ferienimmobilie zu investieren. Das Dorf liegt in einem aufstrebenden Touristenziel und die Nachfrage nach Ferienimmobilien ist hoch. Darüber hinaus wird der Markt für kurzfristige Vermietungen in ",
    " voraussichtlich in den kommenden Jahren wachsen, was es zu einer klugen Investition macht.",
    "Dies sind einige der Vorteile einer Investition in eine Ferienimmobilie in ",
    "Hohe Nachfrage: Die Nachfrage nach Ferienimmobilien in ",
    " ist hoch, dank der Beliebtheit des Dorfes als Touristenziel.",
    "Hohe Belegungsrate: Die durchschnittliche Belegungsrate für Ferienimmobilien in ",
    " liegt über 50%.",
    "Starke Mieteinnahmen: Sie können starke Mieteinnahmen aus Ihrer Immobilie erwarten in ",
    "Kapitalwertschätzung: Der Wert Ihrer Immobilie wird voraussichtlich im Laufe der Zeit steigen, was es zu einer klugen Investition macht.",
    "Wenn Sie darüber nachdenken, in eine Ferienimmobilie zu investieren, ist ",
    " eine großartige Option. Das Dorf liegt in einem erstklassigen Touristenziel und die Nachfrage nach Ferienimmobilien ist hoch. Darüber hinaus wird der Markt für kurzfristige Vermietungen in ",
    " voraussichtlich in den kommenden Jahren wachsen, was es zu einer klugen Investition macht.",
    "Investieren Sie in eine Ferienimmobilie in ",
    "Überlegen Sie, in eine Immobilie zu investieren, die Sie an Touristen in ",
    "Immobilieninvestmentberatung",
    "Hallo Team Fresh,\n\nIch möchte gerne weitere Informationen über die Investition in eine Immobilie in ",
    ".\n\nMit freundlichen Grüßen,\n\n",
    "Kontaktieren Sie FRESH Property Management",
    "und lassen Sie uns Ihnen helfen, das Beste aus Ihrer Investition herauszuholen.",
    "Wir haben Erfahrung im ",
    " Mietmarkt und wissen, welche Immobilien die höchsten Mieteinnahmen erzielen. Wir können Ihnen eine maßgeschneiderte Return on Investment (ROI) Analyse zur Verfügung stellen, die Ihnen hilft, Immobilien zu identifizieren, die Ihren spezifischen Investitionszielen entsprechen.",
    "Darüber hinaus können wir Sie unterstützen, indem wir Ihnen interessante Immobilien aus dem Portfolio unseres umfangreichen Partnernetzwerks von Maklern präsentieren.",
    "Kontaktieren Sie uns noch heute, um mehr darüber zu erfahren, wie wir Ihnen helfen können, Ihren Traum zu verwirklichen, in eine Ferienimmobilie in ",
    " zu investieren."
  ]
};

const seo = {
  en: {
    title: "Rent out your property in {PUEBLO}",
    description:
      "The short-term rental market in {PUEBLO} is very active. With an increasing demand for holiday rental properties on the Costa del Sol this area will continue to grow. The average occupation rate per year in the entire Axarquia area is over 50%. This means that you can expect to rent out your property in {PUEBLO} for a good period throughout the year. Let us help maximize your rental income and minimize your efforts."
  },
  es: {
    title: "Alquile su propiedad en {PUEBLO}",
    description:
      "El mercado de alquileres a corto plazo en {PUEBLO} es muy activo. Con una creciente demanda de propiedades de vacaciones en la Costa del Sol, esta área continuará creciendo. La tasa de ocupación promedio por año en toda el área de la Axarquía es superior al 50%. Esto significa que puede esperar alquilar su propiedad en {PUEBLO} por un buen período durante todo el año. Permítanos ayudarlo a maximizar sus ingresos por alquiler y minimizar sus esfuerzos."
  },
  nl: {
    title: "Verhuur uw woning in {PUEBLO}",
    description:
      "De markt voor korte termijn verhuur in {PUEBLO} is erg actief. Met een toenemende vraag naar vakantiewoningen aan de Costa del Sol zal dit gebied blijven groeien. De gemiddelde bezettingsgraad per jaar in het hele gebied van Axarquia is meer dan 50%. Dit betekent dat u uw woning in {PUEBLO} gedurende een groot deel van het jaar kunt verhuren. Laat ons u helpen uw huurinkomsten te maximaliseren en uw inspanningen te minimaliseren."
  },
  de: {
    title: "Vermieten Sie Ihre Immobilie in {PUEBLO}",
    description:
      "Der Markt für kurzfristige Vermietungen in {PUEBLO} ist sehr aktiv. Mit einer zunehmenden Nachfrage nach Ferienimmobilien an der Costa del Sol wird dieses Gebiet weiter wachsen. Die durchschnittliche Belegungsrate pro Jahr in der gesamten Region Axarquia liegt über 50%. Dies bedeutet, dass Sie Ihre Immobilie in {PUEBLO} für einen großen Teil des Jahres vermieten können. Lassen Sie uns helfen, Ihre Mieteinnahmen zu maximieren und Ihre Bemühungen zu minimieren."
  }
};

const PuebloSite: FC<PuebloSiteProps> = ({ pueblo }) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  return (
    <SEO
      title={seo[language].title.replaceAll("{PUEBLO}", pueblo)}
      description={seo[language].description.replaceAll("{PUEBLO}", pueblo)}
    >
      <Box
        className="overflow-x-hidden"
        backgroundColor="primary"
        text={{ color: "white" }}
        icon={{ fillColor: "white" }}
      >
        <Container className="!pb-0" extraTop>
          <Text type="h1">
            {text()}
            <span className="text-yellow">{pueblo}</span>
            {text()}
          </Text>
          <Text type="h4">
            {pueblo}
            {text()}
          </Text>
        </Container>
        <svg
          className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
      </Box>
      <Box className="overflow-x-hidden" backgroundColor="white">
        <Container>
          <Text className="mb-8">
            {text()}
            {pueblo}
            {text()}
          </Text>
          <Text>
            {pueblo}
            {text()}
            {pueblo}
            {text()}
          </Text>
        </Container>
        <svg
          className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-primary"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
      </Box>
      <Box
        className="overflow-x-hidden"
        backgroundColor="primary"
        text={{ color: "white" }}
        icon={{ fillColor: "white" }}
      >
        <Container>
          <Text type="h4">
            {text()}
            {pueblo}:
          </Text>
          <ul className="mx-6 mb-8 text-left">
            <li className="flex flex-row items-start space-x-2">
              <Icon icon={checkMark} className="my-5 w-5 h-5" />
              <Text>
                {text()}
                {pueblo}
                {text()}
              </Text>
            </li>
            <li className="flex flex-row items-start space-x-2">
              <Icon icon={checkMark} className="my-5 w-5 h-5" />
              <Text>
                {text()}
                {pueblo}
                {text()}
              </Text>
            </li>
            <li className="flex flex-row items-start space-x-2">
              <Icon icon={checkMark} className="my-5 w-5 h-5" />
              <Text>
                {text()}
                {pueblo}.
              </Text>
            </li>
            <li className="flex flex-row items-start space-x-2">
              <Icon icon={checkMark} className="my-5 w-5 h-5" />
              <Text>{text()}</Text>
            </li>
          </ul>
          <Text>
            {text()}
            {pueblo}
            {text()}
            {pueblo}
            {text()}
          </Text>
        </Container>
        <svg
          className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
      </Box>
      <Box
        className="overflow-x-hidden"
        backgroundColor="white"
        text={{ color: "black" }}
        icon={{ fillColor: "black" }}
      >
        <Container>
          <Text type="h2">
            {text()}
            {pueblo}
          </Text>
          <Text className="mb-8">
            {text()}
            {pueblo}?{" "}
            <WhatsAppLink subject={text()} message={`${text()}${pueblo}${text()}`}>
              {text()}
            </WhatsAppLink>{" "}
            {text()}
          </Text>
          <Text className="mb-8">
            {text()}
            {pueblo}
            {text()}
          </Text>
          <Text className="mb-8">{text()}</Text>
          <Text>
            {text()}
            {pueblo}
            {text()}
          </Text>
        </Container>
      </Box>
    </SEO>
  );
};

export default PuebloSite;
