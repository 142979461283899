import { ButtonType } from "./types";
import { classNames } from "../../../utils/string";

const standardButtonClassname = classNames(
  "flex flex-row items-center",
  "relative group origin-center",
  "gap-2 px-4 py-2",
  "cursor-pointer select-none",
  "disabled:opacity-50",
  "active:animate-[clickScale_250ms_ease-in-out]"
);

export const buttonClassName: Record<ButtonType, string> = {
  normal: classNames(standardButtonClassname, "bg-primary text-on-primary rounded", "outline-secondary"),
  inverse: classNames(standardButtonClassname, "bg-white text-primary rounded", "outline-secondary"),
  quiet: classNames(standardButtonClassname, "text-primary", "outline-secondary"),
  outline: classNames(standardButtonClassname, "text-primary border-primary border rounded", "outline-secondary"),
  "call-to-action": classNames(standardButtonClassname, "bg-secondary text-on-secondary rounded", "outline-primary"),
  light: classNames(standardButtonClassname, "text-white", "outline-secondary")
};

export const hoverEffect = (
  <div
    className={classNames(
      "absolute w-full h-full rounded",
      "duration-100 transition-all",
      "inset-0 scale-0",
      "group-active:bg-white/40 group-hover:bg-white/20 group-hover:scale-100"
    )}
  ></div>
);
