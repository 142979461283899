import { CardShadow } from "./types";

export const renderCardShadow = (level: CardShadow) => {
  switch (level) {
    case 4:
      return "shadow-xl";
    case 3:
      return "shadow-lg";
    case 2:
      return "shadow-md";
    case 1:
      return "shadow";
    case 0:
      return "shadow-none";
    case -1:
      return "shadow-inner";
  }
};
