import { FC } from "react";
import { HouseForSale } from "../types";
import { useNavigate } from "react-router-dom";
import Text from "../../../Widgets/Text/Text";
import SurfaceArea from "../SurfaceArea";
import { Carousel } from "flowbite-react";
export type HouseForSaleCardProps = {
  house: HouseForSale;
  formatPrice: (price: number) => string;
};

const HouseForSaleCard: FC<HouseForSaleCardProps> = ({ house, formatPrice }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/for-sale/${house.ref}`)}
      className="opacity-90 hover:opacity-100 hover:shadow-md m-2 bg-white shadow pt-4 cursor-pointer"
    >
      <div className="w-full aspect-square object-cover border-t-2 border-b-8 border-primary">
        <Carousel
          className="w-full h-full"
          onClick={(e) => {
            if (!(e.target instanceof HTMLImageElement)) {
              e.stopPropagation();
            }
          }}
          slide={false}
          indicators={false}
        >
          {house.images.map((image, i) => (
            <img
              key={`house-img-${i}`}
              className="w-full h-full object-cover"
              alt={image.alt ?? `${house.subtype === "Other" ? house.type : house.subtype} for sale in ${house.town}`}
              src={image.src ?? "/visuals/logo.svg"}
              loading="lazy"
            />
          ))}
        </Carousel>
      </div>
      <div className="px-4 py-2">
        <div className="h-14">
          <Text className="!my-1 line-clamp-2">
            {house.subtype === "Other" ? house.type : house.subtype} in {house.town}
          </Text>
        </div>
        <Text type="h5" className="!my-1 font-bold">
          Asking price: {formatPrice(house.price)}
        </Text>
        <SurfaceArea surfaceArea={house.surface_area} beds={house.beds} baths={house.baths} />
      </div>
    </div>
  );
};

export default HouseForSaleCard;
