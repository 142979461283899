import { useEffect, PropsWithChildren, FC } from "react";
import { useLocation } from "react-router-dom";
import { debounce } from "../utils/debounce";

export type ScrollToTopProps = PropsWithChildren<{}>;

const ScrollToTop: FC<ScrollToTopProps> = ({ children }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    const storedScrollY = parseInt(localStorage.getItem(pathname) ?? "0");
    window.scroll({ top: storedScrollY, behavior: "smooth" });
    const onScroll = debounce(() => {
      localStorage.setItem(pathname, Math.round(window.scrollY).toString());
    }, 100);
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [pathname]);

  return <>{children}</>;
};

export default ScrollToTop;
