import { FC, useCallback, useRef, useState } from "react";
import useKeysUp from "../hooks/useKeysUp";
import useSwipeHorizontal from "../hooks/useSwipeHorizontal";
import { ImageSettings } from "../Layout/ImageComponent";
import { classNames } from "../utils/string";

export type ImageSliderProps = {
  images: ImageSettings[];
  className?: string;
  initialImage?: number;
};

const ImageSlider: FC<ImageSliderProps> = ({ images, className, initialImage = 0 }) => {
  const [index, setIndex] = useState(0);
  const carousel = useRef<HTMLDivElement>(null);

  const nextEnabled = index < images.length - 1;
  const prevEnabled = index > 0;

  const nextImage = useCallback(() => {
    if (nextEnabled) {
      setIndex((index) => Math.min(index + 1, images.length - 1));
    }
  }, [nextEnabled, index, images.length]);
  const prevImage = useCallback(() => {
    if (prevEnabled) {
      setIndex((index) => Math.max(index - 1, 0));
    }
  }, [prevEnabled, index, images.length]);

  useSwipeHorizontal(carousel, { onPrevious: prevImage, onNext: nextImage });
  useKeysUp(["ArrowLeft", "ArrowRight"], (key: string) => {
    if (key === "ArrowLeft") {
      prevImage();
    } else {
      nextImage();
    }
  });

  const showControls = images.length > 1;
  const usePagers = images.length < 7;

  return (
    <div
      ref={carousel}
      className={classNames(
        "relative w-full h-full overflow-y-hidden bg-primary flex items-center justify-center max-h-[65vh]",
        className
      )}
    >
      <div className="relative top-0 left-0 right-0 bottom-0  max-h-[65vh] overflow-hidden">
        {images.map((image, i) => (
          <img
            key={`slider-${i}-${image.src}`}
            src={image.src || ""}
            alt={image.alt}
            className={`relative float-left -mr-[100%] w-full h-full object-cover my-auto !transform-none opacity-0${
              index === i ? " opacity-100" : ""
            } transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none`}
          />
        ))}
      </div>
      {showControls && (
        <>
          {usePagers && (
            <div className="absolute inset-x-0 bottom-0 z-[2] mx-[15%] mb-4 flex list-none justify-center p-0">
              {images.map((_, i) => (
                <button
                  key={`pager-button-${i}`}
                  onClick={() => setIndex(i)}
                  type="button"
                  className={`mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] ${
                    i === index ? "opacity-100" : "opacity-50"
                  } transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none`}
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
              ))}
            </div>
          )}
          <button
            className="absolute top-0 bottom-0 left-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
            type="button"
            data-te-slide="prev"
            onClick={prevImage}
            disabled={!prevEnabled}
          >
            <span className="inline-block h-8 w-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
            </span>
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Previous
            </span>
          </button>
          <button
            className="absolute top-0 bottom-0 right-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
            type="button"
            data-te-target="#carouselExampleCrossfade"
            data-te-slide="next"
            onClick={nextImage}
            disabled={!nextEnabled}
          >
            <span className="inline-block h-8 w-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </span>
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Next
            </span>
          </button>
        </>
      )}
    </div>
  );
};

export default ImageSlider;
